import React from 'react'
import { ReactComponent as ArrowDown } from '../resources/icons/arrow-down.svg'

/*------------------------------------------------------------------------------
Hook useGetSelectionCategories
------------------------------------------------------------------------------*/
function useGetSelectionCategories(categoriesPositions) {
  const [selectedCategory, setSelectedCategory] = React.useState("");
  const [scrollPosition, setScrollPosition] = React.useState(0);
  const [headerSize] = React.useState(parseInt(getComputedStyle(document.documentElement).getPropertyValue('--header-size')));
  React.useLayoutEffect(() => {
    if (categoriesPositions) {
      const categories = Object.keys(categoriesPositions);
      const candidates = Object.values(categoriesPositions).filter(pos => pos<=scrollPosition+headerSize);
      if (candidates.length>0) {
        const max = candidates.reduce((a, b) => a>b?a:b)
        const idx = candidates.findIndex(x => x===max)
        setSelectedCategory(categories[idx]);
      } else {
        setSelectedCategory(categories[0]);
      }
    }
  }, [categoriesPositions, scrollPosition]);
  /* Callback */
  const handleScroll = () => {
    setScrollPosition(window.pageYOffset);
  };
  /* Event listener to get the scroll position */
  React.useLayoutEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [])
  /* Callback */
  const updateSelectedCategory = (value) => {
    setSelectedCategory(value);
    const index = Object.keys(categoriesPositions).findIndex(category => category===value);
    if (index!==-1) {
      window.scrollTo({ top: Object.values(categoriesPositions)[index] }); //, behavior: 'smooth'
    }
  };
  return [selectedCategory, updateSelectedCategory];
}

/*------------------------------------------------------------------------------
Hook useHandleSelectableQuantity
------------------------------------------------------------------------------*/
function useHandleSelectableValue(value, updateSelected) {
  const [selectedValue, setSelectedValue] = React.useState(value);
  const handleSelectedValue = (value) => {
    setSelectedValue(value);
    updateSelected && updateSelected(value);
  }
  React.useLayoutEffect(() => {
    if (value!==null) {
      setSelectedValue(value);
    }
  }, [value]);
  return [selectedValue, handleSelectedValue];
}

/*==============================================================================
Component SelectionBox
==============================================================================*/
function SelectionBox({ value, choices, updateSelected }) {
  const [selectedValue, handleSelectedValue] = useHandleSelectableValue(value, updateSelected);
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleIsOpen = () => setIsOpen(isOpen => !isOpen);

  /* Events for unfocus handling */
  const handleClickEvent = (e) => {
    e.preventDefault();
    setIsOpen(false);
  }
  React.useEffect(() => {
    if (isOpen) {
      window.addEventListener('click', handleClickEvent);
    }
    return () => window.removeEventListener('click', handleClickEvent);
  }, [isOpen])

  return (
    <>
      <div className="categoryNavigationSelectionBox" onClick={toggleIsOpen}>
        <div className="categoryNavigationSelectionBoxCurrent">
          <div>{selectedValue}</div>
          <ArrowDown className="dropdownSelectButton"/>
        </div>
        <div className="categoryNavigationSelectionBoxlist" style={isOpen?{height:"50vh", margin: "-1.6em 0 0"}:{height:"0px", margin: "-1em 0 0"}}>
          {choices.map((item, i) => {
            return <p key={i} onClick={() => handleSelectedValue(item)} role="button"
              style={selectedValue===item?{fontWeight:"700"}:{}}>{item}</p>
          })}
        </div>
      </div>
    </>
  );
}

/*==============================================================================
Default component
==============================================================================*/
export default function ProductCategoryNavigation({ categoriesPositions, ...props }) {
  const [selectedCategory, updateSelectedCategory] = useGetSelectionCategories(categoriesPositions);
  return (
    <SelectionBox value={selectedCategory} choices={categoriesPositions?Object.keys(categoriesPositions):[]} updateSelected={updateSelectedCategory} />
  );
}