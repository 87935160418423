import React from 'react'
import { withRouter } from 'react-router'
import Modal from './Modal.component'

export default withRouter(function ConfirmationModal({ data, ...props }) {
  /* Message box data */
  const [msgBoxData, setMsgBoxData] = React.useState(null);
  /* Handle product deletion logic */
  const onConfirmAction = (e) => {
    e.preventDefault();
    data.next(data, props, setMsgBoxData);
  };
  const onCancelAction = (e) => {
    e.preventDefault();
    props.history.goBack();
  }

  return (
    <Modal className="cancelOrderModalBox" msgBoxData={msgBoxData}>
      <p className="cancelOrderNotice" style={{ textAlign: "center" }}>{data.text}</p>
      <div className="orderActionsLayout" style={{ width: "auto", marginBottom: "15px", justifyContent: "center" }}>
        <div className="orderActionButton buttonConfirm" onClick={onCancelAction} role="button" style={{ margin: "0 5px" }}>
          {(data.buttonCancelText ? data.buttonCancelText : "Annuler")}
        </div>
        <div className="orderActionButton buttonReject" onClick={onConfirmAction} role="button" style={{ margin: "0 5px" }}>
          {(data.buttonConfirmText ? data.buttonConfirmText : "Confirmer")}
        </div>
      </div>
    </Modal>
  );
})
