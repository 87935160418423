import React from 'react'
import { withRouter } from 'react-router'
import SignInModal from '../components/SignInModal.component'
import logo from "../resources/icons/logo.png"

export default withRouter(function InMaintenanceModal({ data, ...props }) {
  const [modalOpen, setModalOpen] = React.useState(false);
  const handleModalOpen = (e) => {
    e.preventDefault();
    setModalOpen(true);
  };
  /* Events for unfocus handling */
  const handleClickEvent = (e) => {
    /* When we have a superModal we check if we click on any element descending from backgroundBlur to unfocus */
    if (e.target.className==='backgroundBlur') {
      setModalOpen(false);
    }
  }
  const handleKeyDownEvent = (e) => (e.key==='Escape') && setModalOpen(false);
  /* Set the event listeners at start for the modal */
  React.useEffect(() => {
    window.addEventListener('click', handleClickEvent);
    window.addEventListener('keydown', handleKeyDownEvent);
    /* Prevent the body scrolling behaviour when modal exists */
    document.body.style.overflow = "hidden";
    document.body.style.height = "100%";
    return () => {
      window.removeEventListener('click', handleClickEvent);
      window.removeEventListener('keydown', handleKeyDownEvent);
      /* Return to normal scrolling behaviour */
      document.body.style.overflow = "auto";
      document.body.style.height = "auto";
    };
  }, [])

  return (
    <div className="maintenancePage">
      <img src={logo} alt="Logo" height="70"/>
      <h1>Maintenance du site</h1>
      <span>
        Le site est actuellement en maintenance technique pour une durée indéterminée, nous sommes désolés pour la gêne occasionnée.<br/>
        Nous faisons de notre mieux pour rétablir le service dès que possible.<br/><br/>
      </span>
      <div className="maintenancePageAdminButton" onClick={handleModalOpen} role="button">Admin</div>
      {modalOpen &&
        <SignInModal inMaintenance={true}/>
      }
    </div>
  );
})
