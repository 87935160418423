import React from 'react';
import { getPublicImageSrc } from "../helperFunctions"
import { ReactComponent as Soundcloud } from "../resources/icons/soundcloud-mask.svg"


/*==============================================================================
Descriptions functions
==============================================================================*/
function ProductDisclaimer() {
  return (
    <p className="productDisclaimer" style={{margin:"0 0 10px"}}>
      Les bracelets étant fabriqués de manière artisanale avec des pierres naturelles il peut y avoir des
      variations de couleur, de textures ou de forme entre la photo et la réalité.
    </p>
  );
}

export function ProductDescriptionBracelet() {
  return (<>
    <p className="categoryDescriptionText">
      <span>
        Les bracelets Évolution sont nés d’une prise de conscience : l’évolution de la pensée collective
        passe par la transformation intérieure de l’individu.<br/>
        De cette réalité, nous avons créé, 20 thèmes de soins conçus avec des méditations différentes pour
        vous aider à trouver un équilibre intérieur et accompagner votre transformation au quotidien.
        Après avoir sélectionné le thème qui vous correspond le mieux, laissez-vous guider par la beauté et
        l’énergie d’un des bracelets de ce thème (entre 4 à 6 bracelets différents par thème).<br/><br/>
      </span>
      <span>
        Les différents bienfaits des bracelets Évolution : développe vos potentiels d’autoguérison, facilite
        les interactions avec le monde et les transformations à venir.<br/>
        Rayonner d’une énergie nouvelle, développer votre être profond et booster votre créativité.<br/><br/>
      </span>
      <span>
        • Ils sont réalisés d’une manière artisanale, éthique et responsable (choix des fournisseurs de pierres,
        d’huiles essentielles, de composé floral).<br/>
        • Chaque pierre est choisie, nettoyée, purifiée.<br/>
        • Chaque bracelet est porteur d’une intention thérapeutique positive pour le soin.<br/>
        • Pour un meilleur résultat, nous vous recommandons d’associer l’huile essentielle et le
        composé floral qui correspondent.<br/>
      </span>
    </p>
    <ProductDisclaimer/>
    <hr className="categoryDescriptionSeparator"/>
  </>);
}

export function ProductDescriptionQuantiques() {
  return (<>
    <p className="categoryDescriptionText">
      <span>
        Les Quantiques Olfactifs, sont nés de l’alliance des laboratoires Herbes &amp; Traditions et Deva.
        Ce sont des synergies d’huiles essentielles fines, de haute qualité vibratoire, 100% naturelles et
        biologiques.<br/>
        Ils agissent par l’odeur sur notre psychisme, notre émotionnel, comme des parfums « intelligents »
        qui nous aident à débloquer, accompagner, faire évoluer nos potentiels individuels et collectifs.
        Les 24 Quantiques Olfactifs, nous ont inspiré pour créer les 24 bracelets avec les pierres
        correspondantes.<br/><br/>
      </span>
      <span>
        Après avoir sélectionné le thème qui vous correspond le mieux, laissez-vous guider par la beauté et
        l’énergie du bracelet.<br/>
        Les différents bienfaits des bracelets Quantiques : développe vos potentiels d’autoguérison, facilite
        les interactions avec le monde et les transformations à venir.<br/>
        Rayonner d’une énergie nouvelle, développer votre être profond et booster votre créativité.<br/><br/>
      </span>
      <span>
        • Ils sont réalisés d’une manière artisanale, éthique et responsable (choix des fournisseurs de pierres,
        d’huiles essentielles, de composé floral).<br/>
        • Chaque pierre est choisie, nettoyée, purifiée.<br/>
        • Chaque bracelet est porteur d’une intention thérapeutique positive pour le soin.<br/>
        • Pour un meilleur résultat, nous vous recommandons d’associer le composé floral qui correspond.<br/><br/>
      </span>
      <span>
        La formule Quantique inclus 1 Bracelet Quantique + 1 Quantique Olfactif du labo DEVA + les méditations correspondantes.<br/>
      </span>
    </p>
    <ProductDisclaimer/>
    <hr className="categoryDescriptionSeparator"/>
  </>);
}

export function ProductDescriptionAboutMobile() {
  return (<>
    <img className="AboutLogoImage" src={getPublicImageSrc("about/Logo + Pictos 5 sens.png")} alt="Logo 5 sens" />
    <p className="categoryDescriptionText">
      <h3 style={{textAlign: "center", marginTop: "0px"}}>L'éveil de la conscience par la méditation associée aux 5 sens</h3>
      <span>
        Nous percevons l’extérieur, grâce à nos 5 sens, lorsque nous les associons entre eux
        (synesthésie) nous pouvons ressentir, comprendre et voir les choses différemment,
        ce qui nous permet d’élever notre conscience et notre taux vibratoire.<br/>
        C’est un moyen pour nous accompagner vers des changements profonds et de rayonner une
        énergie nouvelle. Cela apporte également du soutien et la force d’interagir avec les
        bouleversements de notre monde.<br/><br/>
        C’est avec l’aide des minéraux, des huiles essentielles, des composés de fleurs, du son et de la
        méditation, que nous avons créé : <b><br/>20 thèmes de bracelets Évolution et 24 thèmes de bracelets Quantiques.</b><br/><br/>
      </span>
      <video className="categoryDescriptionVideoPlayer" controls width="250" poster={getPublicImageSrc("about/videoposter.png")}>
          <source src={getPublicImageSrc("about/Synesthesia concept explain Final sans texte H264.mp4")}
            type="video/mp4"/>
      </video>
        <span>
          <b>Vous découvrirez avec chaque bracelet :</b><br/>
          • 1 association de pierres naturelles correspondant au thème de soin choisi<br/>
          • 1 pierre (sur fermoir) absorbante, diffusant l’huile essentielle ou Quantique Olfactif<br/>
          • 1 composé de fleurs<br/>
          • 1 intention de travail intérieur avec plusieurs méditations<br/>
        </span>
    </p>
    <ProductDisclaimer/>
    {/* <p className="categoryDescriptionText">
      Expérimentez nos méditations en accès libre →
    </p>
    <div className="freeMeditationLayout">
      <div className="freeMeditation">
        <a href="https://soundcloud.com/user-557558506/sets/meditations-guidees" target="_blank" rel="noopener noreferrer">
          <img className="freeMeditationImage" src={getPublicImageSrc("about/Visu 1 Meditation 1.jpg")} alt="Lien Méditations Guidées" />
          <Soundcloud className="freeMeditationImageSoundcloud"/>
        </a>
      </div>
    </div> */}

    <p className="categoryDescriptionText" style={{ margin: "10px 0" }}>
    <div className="freeMeditationLayout">
      <div className="freeMeditation">
        <a href="https://soundcloud.com/user-557558506/sets/meditations-guidees" target="_blank" rel="noopener noreferrer">
          <img className="freeMeditationImage" src={getPublicImageSrc("about/Visu 1 Meditation 1.jpg")} alt="Lien Méditations Guidées" />
          <Soundcloud className="freeMeditationImageSoundcloud"/>
        </a>
      </div>
      {"←\u202FExpérimentez\u202Fnos\u202Fméditations en\u202Faccès\u202Flibre"}
    </div>
    </p>

    <p className="categoryDescriptionText">
      <span>
        <b>Mode d’emploi, avant la méditation :</b><br/>
        • Mettre le bracelet sur votre poignet avec l’intention de votre méditation à l’esprit.<br/>
        • Ajoutez une goutte d’huile essentielle et 3 sprays de composé floral (voir détail plus bas).<br/>
        • Faites la méditation de préférence le matin ou le soir (si bracelet sommeil).<br/>
        • « Nettoyez énergiquement » votre bracelet régulièrement (explication plus bas).<br/><br/>
      </span>
      <span>
        <b>Conseil d&#39;application des huiles essentielles :</b><br/>
        • Mettre 1 petite goutte d’huile essentielle ou Quantique Olfactif ou roll-on (dilution d’huile
        essentielle) sur la pierre de lave (pierre du fermoir). À défaut, faites 7 respirations profondes surtout
        si vous faites une petite allergie sur le poignet (si c’est le cas ne mettez pas d’huile essentielle sur la
        pierre).<br/>
        • Pour appliquer l’huile essentielle sur le bracelet, mettre le goulot de la bouteille contre la pierre de
        lave et verser délicatement une petite goutte. Pas besoin d’en mettre beaucoup, la diffusion restera
        la journée.<br/><br/>
      </span>
      <span>
        Si l’huile essentielle coule à côté, essuyez avec un mouchoir et lavez-vous les mains.
        Attention à ne pas vous mettre d’huile essentielle dans les yeux ou sur les muqueuses (si c’est le cas :
        ne pas mettre d’eau, mettre de l’huile végétale qui absorbera l’huile essentielle).
        Ne pas laisser les huiles essentielles à la portée des enfants. Portez une attention particulière au
        niveau de l’odeur d'huile essentielle, elle peut être très forte et incommodante pour votre
        entourage, nous avons sélectionné des huiles essentielles en conséquence.<br/>
        Toutefois, si vous préférez une autre huile essentielle, vous pouvez aussi l’utiliser.<br/><br/>
      </span>
      <span>
        <b>Conseils d’application du composé floral :</b><br/>
        • 1 spray directement sous la langue.<br/>
        • 1 spray au-dessus de votre tête à 30cm environ, pour un travail énergétique sur le corps.<br/>
        • 1 spray sur votre bracelet, à quelques cm de hauteur pour réinformer votre bracelet.<br/><br/>
      </span>
      <span>
        <b>Nettoyage énergétique du bracelet : </b><br/>
        Pour un travail en profondeur, il sera nécessaire de le nettoyer
        régulièrement (les pierres absorbent les énergies), plusieurs possibilités :<br/>
        • Vous avez une pierre de Cristal de roche, un quartz Rose, une améthyste d’une taille plus
        importante que votre bracelet, vous pouvez poser le bracelet dessus pendant au moins 1/2h matin
        ou soir (si bracelet sommeil) avant de le mettre sur votre poignet.<br/>
        • Vous avez une coquille St Jacques vide et propre, posez votre bracelet 1/2h à l’intérieur.<br/>
        • Vous n’avez ni l’un ni l’autre, vous pouvez faire couler un filet d’eau sur les pierres en tenant les fils
        du bracelet dans votre main pour éviter de les mouiller. Vous pouvez également nettoyer les pierres
        avec un petit morceau de tissu en coton mouillé.<br/><br/>
      </span>
      <span>
        <b>Précaution d’emploi : </b><br/>
        Les pierres étant petites, nous avons choisi des fils de bracelets fins, pour une
        longévité optimale, prenez en soin. Il est préférable d’enlever son bracelet pour prendre votre
        douche ou bain.<br/>
        Les pierres sont naturelles, elles peuvent varier en formes, couleur ou textures.<br/><br/>
      </span>
      <span>
        <b>Informations sur votre bracelet :</b><br/>
        • Pour les femmes, il est conseillé de porter le bracelet plutôt à gauche (à droite pour les gauchères).<br/>
        • Pour les hommes, il est conseillé de porter le bracelet plutôt à droite (y compris pour les gauchers).<br/>
        Toutefois, nous vous conseillons d’expérimenter d’un côté puis de l’autre, pour ressentir ce qui est le
        mieux pour vous.<br/><br/>
      </span>
      <span>
        Ne pas oublier de valider la taille de votre tour de poignet sur la fiche du bracelet.<br/><br/>
      </span>
      <span>
        <b>Synergie d’une équipe :</b><br/>
        • Maïa, Morgane, Florian : Fabrication artisanale des bracelets Synesthésia.<br/>
        • Théo : Scénographe, Graphiste, Photographe, Artiste audio-visuel. Création communication :
        réalisation photos, catalogue, site, teasers vidéo. Création méditations : Mixage son et voix. (<a className="categoryDescriptionLink" href="https://theobrice.com/Shop" target="_blank" rel="noopener noreferrer">https://theobrice.com/Shop</a>)<br/>
        • Marielle : Naturopathe, Olfactothérapeute, Conseillère élixirs de fleurs, Thérapeute Psycho-
        corporel Energétique, Thérapie sacrée notamment Son-Cacao, créatrice de méditations
        Thérapeutique, Formatrice et particulièrement des Quantiques Olfactifs.<br/>
        • Morgane, Bruno, Emma : Équipe commerciale communication.<br/>
        • William : Développement du site, design et implémentation.
        <br/><br/>
      </span>
      <span>
        Pour chaque bracelet, une contribution de 1€ sera reversée à l’une des 3 associations bénéficiaires :
        Asso Sésame Autisme - Asso eco-village et santé autonome - Asso protection animaux<br/><br/>
      </span>
      <span>
        <b>Nos partenaires :</b><br/>
        laboratoires Deva - Herbes &amp; Tradition ;<br/>
        boutique : Aromathèque (Lyon 02-06-Caen)
      </span>
    </p>
  </>);
}


export function ProductDescriptionAbout() {
  return (<>
    <div className="productDescriptionAboutHeadLayout">
      <img className="AboutLogoImage" src={getPublicImageSrc("about/Logo + Pictos 5 sens.png")} alt="Logo 5 sens" />
      <h3 style={{textAlign: "center", marginTop: "0px"}}>L'éveil de la conscience par la méditation associée aux 5 sens</h3>
      <video className="categoryDescriptionVideoPlayer" controls width="250" poster={getPublicImageSrc("about/videoposter.png")}>
          <source src={getPublicImageSrc("about/Synesthesia concept explain Final sans texte H264.mp4")}
            type="video/mp4"/>
      </video>
    </div>
    <p className="categoryDescriptionText">
      <span>
        Nous percevons l’extérieur, grâce à nos 5 sens, lorsque nous les associons entre eux
        (synesthésie) nous pouvons ressentir, comprendre et voir les choses différemment,
        ce qui nous permet d’élever notre conscience et notre taux vibratoire.<br/>
        C’est un moyen pour nous accompagner vers des changements profonds et de rayonner une
        énergie nouvelle. Cela apporte également du soutien et la force d’interagir avec les
        bouleversements de notre monde.<br/><br/>
        C’est avec l’aide des minéraux, des huiles essentielles, des composés de fleurs, du son et de la
        méditation, que nous avons créé : <b><br/>20 thèmes de bracelets Évolution et 24 thèmes de bracelets Quantiques.</b><br/><br/>
      </span>
        <span>
          <b>Vous découvrirez avec chaque bracelet :</b><br/>
          • 1 association de pierres naturelles correspondant au thème de soin choisi<br/>
          • 1 pierre (sur fermoir) absorbante, diffusant l’huile essentielle ou Quantique Olfactif<br/>
          • 1 composé de fleurs<br/>
          • 1 intention de travail intérieur avec plusieurs méditations<br/>
        </span>
    </p>
    <ProductDisclaimer/>
    {/* <p className="categoryDescriptionText">
      Expérimentez nos méditations en accès libre →
    </p>
    <div className="freeMeditationLayout">
      <div className="freeMeditation">
        <a href="https://soundcloud.com/user-557558506/sets/meditations-guidees" target="_blank" rel="noopener noreferrer">
          <img className="freeMeditationImage" src={getPublicImageSrc("about/Visu 1 Meditation 1.jpg")} alt="Lien Méditations Guidées" />
          <Soundcloud className="freeMeditationImageSoundcloud"/>
        </a>
      </div>
    </div> */}

    <p className="categoryDescriptionText" style={{ margin: "10px 0" }}>
    <div className="freeMeditationLayout">
      <div className="freeMeditation">
        <a href="https://soundcloud.com/user-557558506/sets/meditations-guidees" target="_blank" rel="noopener noreferrer">
          <img className="freeMeditationImage" src={getPublicImageSrc("about/Visu 1 Meditation 1.jpg")} alt="Lien Méditations Guidées" />
          <Soundcloud className="freeMeditationImageSoundcloud"/>
        </a>
      </div>
      {"←\u202FExpérimentez\u202Fnos\u202Fméditations en\u202Faccès\u202Flibre"}
    </div>
    </p>

    <p className="categoryDescriptionText">
      <span>
        <b>Mode d’emploi, avant la méditation :</b><br/>
        • Mettre le bracelet sur votre poignet avec l’intention de votre méditation à l’esprit.<br/>
        • Ajoutez une goutte d’huile essentielle et 3 sprays de composé floral (voir détail plus bas).<br/>
        • Faites la méditation de préférence le matin ou le soir (si bracelet sommeil).<br/>
        • « Nettoyez énergiquement » votre bracelet régulièrement (explication plus bas).<br/><br/>
      </span>
      <span>
        <b>Conseil d&#39;application des huiles essentielles :</b><br/>
        • Mettre 1 petite goutte d’huile essentielle ou Quantique Olfactif ou roll-on (dilution d’huile
        essentielle) sur la pierre de lave (pierre du fermoir). À défaut, faites 7 respirations profondes surtout
        si vous faites une petite allergie sur le poignet (si c’est le cas ne mettez pas d’huile essentielle sur la
        pierre).<br/>
        • Pour appliquer l’huile essentielle sur le bracelet, mettre le goulot de la bouteille contre la pierre de
        lave et verser délicatement une petite goutte. Pas besoin d’en mettre beaucoup, la diffusion restera
        la journée.<br/><br/>
      </span>
      <span>
        Si l’huile essentielle coule à côté, essuyez avec un mouchoir et lavez-vous les mains.
        Attention à ne pas vous mettre d’huile essentielle dans les yeux ou sur les muqueuses (si c’est le cas :
        ne pas mettre d’eau, mettre de l’huile végétale qui absorbera l’huile essentielle).
        Ne pas laisser les huiles essentielles à la portée des enfants. Portez une attention particulière au
        niveau de l’odeur d'huile essentielle, elle peut être très forte et incommodante pour votre
        entourage, nous avons sélectionné des huiles essentielles en conséquence.<br/>
        Toutefois, si vous préférez une autre huile essentielle, vous pouvez aussi l’utiliser.<br/><br/>
      </span>
      <span>
        <b>Conseils d’application du composé floral :</b><br/>
        • 1 spray directement sous la langue.<br/>
        • 1 spray au-dessus de votre tête à 30cm environ, pour un travail énergétique sur le corps.<br/>
        • 1 spray sur votre bracelet, à quelques cm de hauteur pour réinformer votre bracelet.<br/><br/>
      </span>
      <span>
        <b>Nettoyage énergétique du bracelet : </b><br/>
        Pour un travail en profondeur, il sera nécessaire de le nettoyer
        régulièrement (les pierres absorbent les énergies), plusieurs possibilités :<br/>
        • Vous avez une pierre de Cristal de roche, un quartz Rose, une améthyste d’une taille plus
        importante que votre bracelet, vous pouvez poser le bracelet dessus pendant au moins 1/2h matin
        ou soir (si bracelet sommeil) avant de le mettre sur votre poignet.<br/>
        • Vous avez une coquille St Jacques vide et propre, posez votre bracelet 1/2h à l’intérieur.<br/>
        • Vous n’avez ni l’un ni l’autre, vous pouvez faire couler un filet d’eau sur les pierres en tenant les fils
        du bracelet dans votre main pour éviter de les mouiller. Vous pouvez également nettoyer les pierres
        avec un petit morceau de tissu en coton mouillé.<br/><br/>
      </span>
      <span>
        <b>Précaution d’emploi : </b><br/>
        Les pierres étant petites, nous avons choisi des fils de bracelets fins, pour une
        longévité optimale, prenez en soin. Il est préférable d’enlever son bracelet pour prendre votre
        douche ou bain.<br/>
        Les pierres sont naturelles, elles peuvent varier en formes, couleur ou textures.<br/><br/>
      </span>
      <span>
        <b>Informations sur votre bracelet :</b><br/>
        • Pour les femmes, il est conseillé de porter le bracelet plutôt à gauche (à droite pour les gauchères).<br/>
        • Pour les hommes, il est conseillé de porter le bracelet plutôt à droite (y compris pour les gauchers).<br/>
        Toutefois, nous vous conseillons d’expérimenter d’un côté puis de l’autre, pour ressentir ce qui est le
        mieux pour vous.<br/><br/>
      </span>
      <span>
        Ne pas oublier de valider la taille de votre tour de poignet sur la fiche du bracelet.<br/><br/>
      </span>
      <span>
        <b>Synergie d’une équipe :</b><br/>
        • Maïa, Morgane, Florian : Fabrication artisanale des bracelets Synesthésia.<br/>
        • Théo : Scénographe, Graphiste, Photographe, Artiste audio-visuel. Création communication :
        réalisation photos, catalogue, site, teasers vidéo. Création méditations : Mixage son et voix. (<a className="categoryDescriptionLink" href="https://theobrice.com/Shop" target="_blank" rel="noopener noreferrer">https://theobrice.com/Shop</a>)<br/>
        • Marielle : Naturopathe, Olfactothérapeute, Conseillère élixirs de fleurs, Thérapeute Psycho-
        corporel Energétique, Thérapie sacrée notamment Son-Cacao, créatrice de méditations
        Thérapeutique, Formatrice et particulièrement des Quantiques Olfactifs.<br/>
        • Morgane, Bruno, Emma : Équipe commerciale communication.<br/>
        • William : Développement du site, design et implémentation.
        <br/><br/>
      </span>
      <span>
        Pour chaque bracelet, une contribution de 1€ sera reversée à l’une des 3 associations bénéficiaires :
        Asso Sésame Autisme - Asso eco-village et santé autonome - Asso protection animaux<br/><br/>
      </span>
      <span>
        <b>Nos partenaires :</b><br/>
        laboratoires Deva - Herbes &amp; Tradition ;<br/>
        boutique : Aromathèque (Lyon 02-06-Caen)
      </span>
    </p>
  </>);
}
