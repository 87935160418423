import React from 'react';
import slugify from 'slugify';
import { getMoneticoDate, getMoneticoPhone } from '../helperFunctions';
import { customAlphabet } from 'nanoid';
const nanoid = customAlphabet('0123456789ABCDEFGHJKLMNPQRSTUVWXYZ', 7);

/*------------------------------------------------------------------------------
Hook useHashMAC
------------------------------------------------------------------------------*/
function useHashMAC(orderContext, date, client, total, urls, orderId, updateHashMacData) {
  React.useEffect(() => {
    const data = {
      ...client,
      date: date,
      orderId: orderId,
      sealData: `TPE=${process.env.REACT_APP_MONETICO_TPE}*contexte_commande=${orderContext}*date=${getMoneticoDate(date)}*lgue=${process.env.REACT_APP_MONETICO_LANG}*mail=${client.email}*montant=${`${total}EUR`}*reference=${orderId}*societe=${process.env.REACT_APP_MONETICO_COMPANY}*texte-libre=${''}*url_retour_err=${urls.cancel}*url_retour_ok=${urls.success}*version=${process.env.REACT_APP_MONETICO_VERSION}`
    };
    updateHashMacData(data);
  }, [total, date, urls, orderId, orderContext, client.firstName, client.lastName, client.email, client.mobilePhone, client.address]);
}

/*------------------------------------------------------------------------------
Hook useReturnUrls
------------------------------------------------------------------------------*/
function useReturnUrls(orderId) {
  const [urls] = React.useState({
    cancel: process.env.REACT_APP_MONETICO_CANCEL_URL.replace('id=', `id=${orderId}`),
    success: process.env.REACT_APP_MONETICO_SUCCESS_URL.replace('id=', `id=${orderId}`)
  });
  return urls;
}

/*------------------------------------------------------------------------------
Hook useCreateOrderContext
------------------------------------------------------------------------------*/
function useCreateOrderContext(items, client) {
  const [orderContext, setOrderContext] = React.useState({});
  React.useEffect(() => {
    let clientInfos = {
      firstName: client.firstName,
      lastName: client.lastName,
      addressLine1: client.address?.name,
      city: client.address?.city,
      postalCode: client.address?.postcode,
      country: 'FR',
      email: client.email,
      phone: getMoneticoPhone(client.mobilePhone),
    };
    
    // slugify, if we have accents we won't be able to convert to base64 to send to monetico
    clientInfos = Object.keys(clientInfos).reduce((acc, key) => {
      if (typeof clientInfos[key] === "string")
        acc[key] = slugify(clientInfos[key], { replacement: ' ' });
      else
        acc[key] = clientInfos[key];
      return acc;
    }, {});

    setOrderContext(btoa(JSON.stringify({
      billing: clientInfos,
      shipping: {
        ...clientInfos,
        shipIndicator: "billing_address",
        deliveryTimeframe: "long",
        matchBillingAddress: true
      },
      shoppingCart: {
        shoppingCartItems: items.map(item => {
          return {
            name: item.product.name.normalize("NFD").replace(/\p{Diacritic}/gu, ""),
            productCode: "default",
            unitPrice: item.product.price,
            quantity: item.quantity,
            productSKU: item.product.id,
          };
        })
      }
    })));
  }, [items, client]);
  return orderContext;
}

/*------------------------------------------------------------------------------
Main Component
------------------------------------------------------------------------------*/
export default function MoneticoPayment({ hashMac, updateHashMacData, moneticoSubmitRef, total, client, items }) {
  const [date] = React.useState(new Date());
  const [orderId] = React.useState(nanoid());
  const orderContext = useCreateOrderContext(items, client);
  const urls = useReturnUrls(orderId);
  useHashMAC(orderContext, date, client, total, urls, orderId, updateHashMacData);

  return (
    <form method="post" name="MoneticoTransactionForm" target="_top" action={process.env.REACT_APP_MONETICO_PAYMENT_URL}>
      <input type="hidden" name="TPE" value={process.env.REACT_APP_MONETICO_TPE}></input>
      <input type="hidden" name="version" value={process.env.REACT_APP_MONETICO_VERSION}></input>
      <input type="hidden" name="date" value={getMoneticoDate(date)}></input>
      <input type="hidden" name="montant" value={`${total}EUR`}></input>
      <input type="hidden" name="reference" value={orderId}></input>
      <input type="hidden" name="lgue" value={process.env.REACT_APP_MONETICO_LANG}></input>
      <input type="hidden" name="texte-libre" value=''></input>
      <input type="hidden" name="MAC" value={hashMac}></input>
      <input type="hidden" name="contexte_commande" value={orderContext}></input>
      <input type="hidden" name="societe" value={process.env.REACT_APP_MONETICO_COMPANY}></input>
      <input type="hidden" name="mail" value={client.email}></input>
      <input type="hidden" name="url_retour_ok" value={urls.success}></input>
      <input type="hidden" name="url_retour_err" value={urls.cancel}></input>
      <input type="submit" ref={moneticoSubmitRef} style={{display: "none"}}></input>
    </form>
  );
}
