import { ModalContext } from '../Modal'
import React from 'react'
import { withRouter, Redirect } from 'react-router'
import { isMobile } from 'react-device-detect'
import { ProfileHeader } from '../components/Header.component'
import MessageBox from '../components/MessageBox.component'
import { OrderSubItem } from '../components/OrdersModal.component'
import MoneticoPayment from '../components/MoneticoPayment.component'
import { useEnterText } from '../hooks/asyncHooks'
import { promiseFetch, stringToHtmlText, floatToPrice, convertSearchParamsToObject } from '../helperFunctions'

import "./OrderPage.css"

const url = process.env.REACT_APP_URL;

/*==============================================================================
Component OrdersComponents
==============================================================================*/
function OrdersComponents({ orderData }) {
  return (
    <>
      <div className="orderPageSummary">
        <h2 className="orderPageSummaryTitle">Récapitulatif</h2>
        <hr/>
        {orderData.items.map((item, j) => {
          return <OrderSubItem key={j} orderItem={item} />;
        })}
        <p className="productPackageSummaryTotal" style={{ margin: "0px 0px 10px" }}>
          <input className="orderPageCheckbox" type="checkbox" readOnly={true} checked={true} value="None" name="check" />
          Livraison standard
          <span className="productPackageSummaryTotalAmount">{orderData.deliveryFee===0 ? 'Offerte' : '+'+floatToPrice(orderData.deliveryFee)}</span>
        </p>
        <p className="productPackageSummaryTotal productPackageSummaryTotalAlt">
          Total
          <span className="productPackageSummaryTotalAmount">{floatToPrice(orderData.amount + orderData.deliveryFee)}</span>
        </p>
      </div>
    </>
  );
}

/*------------------------------------------------------------------------------
Hook useHandleTextInput
------------------------------------------------------------------------------*/
function useHandleTextInput(setMsgBoxData) {
  const [text, updateText] = useEnterText();
  const handleUpdateText = (text) => { setMsgBoxData(null); updateText(text); };
  return [text, handleUpdateText];
}

/*------------------------------------------------------------------------------
Hook useHandleEmailInput
------------------------------------------------------------------------------*/
function useHandleEmailInput(setMsgBoxData) {
  const [email, updateEmail] = useEnterText();
  const handleUpdateEmail = (text) => { setMsgBoxData(null); updateEmail(text); };

  /* Force focus on the input text */
  const emailInputRef = React.useRef(null);
  React.useEffect(() => {
    if (emailInputRef.current!==null) {
      /* Get the user email to display as placeholder value */
      promiseFetch(`${url}/api/users/getEmail`)
        .then(res => {
          if (res.data!==null) {
            emailInputRef.current.placeholder = res.data;
            if (!isMobile) {
              emailInputRef.current.focus();
            }
          }
        })
    }
  }, [emailInputRef]);

  return [email, handleUpdateEmail, emailInputRef];
}

/*==============================================================================
Component for address input
==============================================================================*/
function AddressInputBox({ selectedSuggestion, setSelectedSuggestion, setMsgBoxData, msgBoxAnchorRef }) {
  /* Hooks */
  const [address, updateAddress] = useEnterText();
  const [suggestions, setSuggestions] = React.useState(null);
  /* Handlers */
  const handleUpdateAddress = (address) => {
    setMsgBoxData(null);
    setSelectedSuggestion(null);
    updateAddress(address);
  };
  /* Effects */
  React.useEffect(() => {
    if (address!=="") {
      /* https://geo.api.gouv.fr/adresse   50requests/sec limit */
      promiseFetch('https://api-adresse.data.gouv.fr/search?q='+encodeURIComponent(address)+'&limit=10', {
        method: 'GET',
        credentials: 'same-origin' /* needed in prod: set the default value */
      })
        .then(res => {
          if (res.status===200 && res.data) {
            setSuggestions(res.data.features.map(item => item.properties));
          }
        })
    } else {
      setSuggestions(null);
    }
  }, [address]);
  /* */
  const onSuggestionClicked = (item) => {
    updateAddress(item.label);
    setMsgBoxData(null);
    setSelectedSuggestion(item);
  }

  return (
    <>
      <label htmlFor="address">Adresse de livraison</label>
      <input className="orderPageInputBox" type="text" placeholder="" autoComplete="off"
        value={address} onChange={handleUpdateAddress} style={{ color: (selectedSuggestion?"var(--color-text)":"var(--color-text-light)") }} id="address" />
      {suggestions && suggestions.length>0 && !selectedSuggestion &&
        <div className="orderPageAddressSuggestions">
          {suggestions.map((item, i) => {
            return <p className="orderPageAddressSuggestionEntry" key={i} onClick={() => onSuggestionClicked(item)} role="button">{item.label}</p>
          })}
        </div>}
      <div ref={msgBoxAnchorRef}></div>
    </>
  );
}

const CC = "+33"
const CC_LEN = CC.length
/*------------------------------------------------------------------------------
Hook useEnterPhone
------------------------------------------------------------------------------*/
function useEnterPhone(initialValue="", maxLength=0) {
  const [value, setValue] = React.useState(initialValue);
  const handleChange = (e) => {
    e.preventDefault();
    if (maxLength === 0 || e.target.value.length <= maxLength) {
      setValue(e.target.value.slice(CC_LEN));
    }
  };
  return [value, handleChange];
}

/*==============================================================================
Component PhoneInputBox
==============================================================================*/
function PhoneInputBox({ mobilePhone, updateMobilePhone, setMsgBoxData }) {
  const handleUpdateMobilePhone = (e) => { setMsgBoxData(null); updateMobilePhone(e); };
  /* Callbacks */
  const onKeyDown = (e) => {
    /* Global selection */
    if (e.code==="KeyA" && (e.metaKey===true || e.ctrlKey===true)) {
      e.preventDefault();
      e.target.selectionStart = CC_LEN;
      e.target.selectionEnd = e.target.textLength;
    }
    /* Backspace and left arrow logic */
    if ((e.code==="Backspace" || e.code==="ArrowLeft") && e.target.selectionStart<=CC_LEN &&
      !(e.target.selectionStart===CC_LEN && e.target.selectionEnd!==CC_LEN)) {
      e.preventDefault();
    }
    /* Arrow up */
    if (e.code==="ArrowUp") {
      e.preventDefault();
      e.target.selectionStart = CC_LEN;
      e.target.selectionEnd = CC_LEN;
    }
    /* Arrow right */
    if (e.code==="ArrowRight" && e.target.selectionStart<CC_LEN) {
      e.preventDefault();
    }
  }
  const onFocusCapture = (e) => {
    e.preventDefault();
    e.target.selectionStart = CC_LEN;
    e.target.selectionEnd = e.target.textLength;
  }
  const onClicked = (e) => {
    if (e.target.selectionStart<=CC_LEN) {
      e.target.selectionStart = CC_LEN;
    }
  }
  return (
    <>
      <label htmlFor="mobilephone">Numéro de téléphone</label>
      <input className="orderPageInputBox" type="text" placeholder={CC} autoComplete="tel"
        value={CC+mobilePhone} onChange={handleUpdateMobilePhone} onKeyDown={onKeyDown} onClickCapture={onClicked}
        onFocusCapture={onFocusCapture} id="mobilephone" />
    </>
  );
}

/*------------------------------------------------------------------------------
Hook useHandleCgvAcceptation
------------------------------------------------------------------------------*/
function useHandleCgvAcceptation(props) {
  const [cgvAcceptation, setCgvAcceptation] = React.useState(false);
  const handleCgvAcceptation = (e) => {
    setCgvAcceptation(e.target.checked);
  };
  const cgvOnClick = (e) => {
    e.preventDefault();
    props.history.push('/order#cgv', props.location.state);
  };
  return [cgvAcceptation, handleCgvAcceptation, cgvOnClick];
}

/*------------------------------------------------------------------------------
Hook useMoneticoTransaction
------------------------------------------------------------------------------*/
function useMoneticoTransaction() {
  const submitRef = React.useRef(null);
  const triggerSubmit = () => {
    if (submitRef.current)
      submitRef.current.click();
  };
  return [submitRef, triggerSubmit];
}

/*------------------------------------------------------------------------------
Hook useMoneticoHashMac
------------------------------------------------------------------------------*/
function useMoneticoHashMac(moneticoTriggerTransaction, setMsgBoxData) {
  const [data, setData] = React.useState(null);
  const [hashMac, setHashMac] = React.useState(null);

  const updateHashMacData = (data) => {
    setData(data);
  }

  /* → Make a request to backend to get the hashMac */
  const hashMacRequest = () => {
    if (data && data.sealData) {
      promiseFetch(`${url}/api/orders/createMAC`, {
        method: 'POST',
        headers: { "Content-Type": "application/json; charset=utf-8" },
        body: JSON.stringify({ data: data.sealData })
      })
        .then(res => {
          if (res.status===200 && res.data) {
            const newHashMac = res.data.hashMac;
            /* → Add the order */
            promiseFetch(`${url}/api/orders/add`, {
              method: 'POST',
              headers: { "Content-Type": "application/json; charset=utf-8" },
              body: JSON.stringify({
                id: data.orderId,
                date: data.date,
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                address: data.address.name,
                postcode: data.address.postcode,
                city: data.address.city,
                country: 'FR'
              })
            })
              .then(res => {
                if (res.data==="r_exists") {
                  setMsgBoxData({ text: "La commande a déjà été ajoutée", classes: ["colorSuccess"] });
                } else if (res.status===400 || res.data==="r_noitems" || res.data==="r_nodeliveryfee") {
                  setMsgBoxData({ text: "Impossible d'ajouter la commande", classes: ["colorError"] });
                } else if (res.data!==null) {
                  /* → Redirect to monetico to perform the transaction */
                  setHashMac(newHashMac);
                }
              })
          }
        })
    } else {
      setMsgBoxData({ text: "Impossible d'ajouter la commande, données invalides", classes: ["colorError"] });
    }
  }
  /* → Then make the request to monetico */
  React.useEffect(() => {
    if (hashMac && hashMac.length===40) {
      moneticoTriggerTransaction();
    }
  }, [hashMac]);

  return [updateHashMacData, hashMac, hashMacRequest];
}

/*==============================================================================
Component orderInformationForm
==============================================================================*/
const OrderInformationForm = withRouter(({ amount, deliveryFee, items, setMsgBoxData, msgBoxAnchorRef, ...props }) => {
  /* Force focus on the input text */
  const [email, handleUpdateEmail, emailInputRef] = useHandleEmailInput(setMsgBoxData);
  const [lastName, handleUpdateLastName] = useHandleTextInput(setMsgBoxData);
  const [firstName, handleUpdateFirstName] = useHandleTextInput(setMsgBoxData);
  const [mobilePhone, updateMobilePhone] = useEnterPhone();
  const [selectedSuggestion, setSelectedSuggestion] = React.useState(null);
  const [cgvAcceptation, handleCgvAcceptation, cgvOnClick] = useHandleCgvAcceptation(props);
  const [moneticoSubmitRef, moneticoTriggerTransaction] = useMoneticoTransaction();
  const [updateHashMacData, hashMac, hashMacRequest] = useMoneticoHashMac(moneticoTriggerTransaction, setMsgBoxData);

  /* Handling the validation of the form before proceeding to payment */
  const handleFormSubmit = (e) => {
    e.preventDefault();
    const emailRegex = /\S+@\S+\.\S+/;
    const fullMobilePhone = '+33'+mobilePhone;
    const phoneNumberRegRes = /[+]330?\d{9}/.exec(fullMobilePhone);
    if (emailRegex.test(email)===false && !(emailInputRef.current.placeholder!=="" && email==="")) {
      setMsgBoxData({ text: "Veuillez entrer un email valide", classes: ["colorError"] });
    } else if (lastName==="") {
      setMsgBoxData({ text: "Veuillez renseigner votre nom de famille", classes: ["colorError"] });
    } else if (firstName==="") {
      setMsgBoxData({ text: "Veuillez renseigner votre prénom", classes: ["colorError"] });
    } else if (mobilePhone==="") {
      setMsgBoxData({ text: "Veuillez renseigner votre numéro de téléphone", classes: ["colorError"] });
    } else if (!(phoneNumberRegRes ? phoneNumberRegRes[0]===fullMobilePhone : false)) {
      setMsgBoxData({ text: "Veuillez renseigner un numéro de téléphone valide, ex: +33601020304", classes: ["colorError"] });
    } else if (selectedSuggestion===null) {
      setMsgBoxData({ text: "Veuillez renseigner une adresse de livraison et sélectionner une suggestion dans la liste", classes: ["colorError"] });
    } else if (cgvAcceptation===false) {
      setMsgBoxData({ text: "Veuillez prendre connaissance des conditions générales de vente et cocher la case de validation", classes: ["colorError"] });
    } else {
      hashMacRequest();
    }
  };

  return (
    <>
      <h2 className="orderPageSummaryTitle">Informations de livraison</h2>
      <hr/>
      <form className="orderPageForm" onSubmit={handleFormSubmit}>
        <label htmlFor="email">Email</label>
        <input className="orderPageInputBox" type="text" placeholder="" ref={emailInputRef} autoComplete="email"
          value={email} onChange={handleUpdateEmail} id="email" />
        <label htmlFor="lastname">Nom</label>
        <input className="orderPageInputBox" type="text" placeholder="" autoComplete="family-name"
          value={lastName} onChange={handleUpdateLastName} id="lastname" />
        <label htmlFor="firstname">Prénom</label>
        <input className="orderPageInputBox" type="text" placeholder="" autoComplete="given-name"
          value={firstName} onChange={handleUpdateFirstName} id="firstname" />
        <PhoneInputBox mobilePhone={mobilePhone} updateMobilePhone={updateMobilePhone} setMsgBoxData={setMsgBoxData} />
        <AddressInputBox selectedSuggestion={selectedSuggestion} setSelectedSuggestion={setSelectedSuggestion}
          setMsgBoxData={setMsgBoxData} msgBoxAnchorRef={msgBoxAnchorRef} />
        <p className="orderPageNotice">
          {stringToHtmlText(`En confirmant la commande vous allez être redirigé vers notre partenaire pour la gestion du paiement sécurisé.\n
          Une fois vos coordonnées bancaires validées vous recevrez un email de confirmation de commande. Nous reviendrons vers vous dans un délai de 7 jours pour vous confirmer la disponibilité des articles et procéderons à l’envoi de la commande le cas échéant.\n
          Le débit sera effectué une fois la commande envoyée.\n
          Vous pourrez suivre l’état de vos commandes en cliquant sur l’onglet “Commandes” sur la page de votre profil.`)}
        </p>
        <div className="orderPageCgvAcceptation">
          <input name="cgvAcceptation" type="checkbox" onChange={handleCgvAcceptation} checked={cgvAcceptation} />
          <label htmlFor="cgvAcceptation">
            Accepter les <a href="https://www.synesthesia.fr/order/#cgv" onClick={cgvOnClick}>conditions générales de vente</a>
          </label>
        </div>
        <input className="buttonPrimaryAction" type="submit" style={{ width: "inherit" }} value="Procéder au paiement" />
      </form>

      <MoneticoPayment
        updateHashMacData={updateHashMacData}
        hashMac={hashMac}
        moneticoSubmitRef={moneticoSubmitRef}
        total={amount + deliveryFee}
        client={{
          firstName: firstName,
          lastName: lastName,
          address: selectedSuggestion,
          email: (email!=="" ? email : emailInputRef.current?.placeholder),
          mobilePhone: mobilePhone
        }}
        items={items}/>
    </>
  );
})

/*==============================================================================
Component OrderPageSuccess
==============================================================================*/
const OrderPageSuccess = withRouter(function ({ searchParams, setMsgBoxData, msgBoxAnchorRef, handleCartAmount, ...props }) {
  React.useEffect(() => {
    if (searchParams.id) {
      /* → Saves the order to the user, changes the state to "En attente", sends confirmation emails and clear session */
      promiseFetch(`${url}/api/orders/complete`, {
        method: 'POST',
        headers: { "Content-Type": "application/json; charset=utf-8" },
        body: JSON.stringify({
          orderId: searchParams.id
        })
      })
        .then(res => {
          if (res.status===400) {
            setMsgBoxData({ text: "Une erreur est survenue", classes: ["colorError"] });
          } else if (res.status===200 && res.data) {
            /* → Update the cart amount displayed */
            handleCartAmount(null);
            /* → And redirect to profile */
            props.history.replace('/profile');
          }
        })
    } else {
      setMsgBoxData({ text: "Une erreur est survenue", classes: ["colorError"] });
    }
  }, []);
  return (
    <div className="emptyMsgBoxAnchor" ref={msgBoxAnchorRef}></div>
  );
})

/*==============================================================================
Component OrderPageCancel
==============================================================================*/
const OrderPageCancel = withRouter(function ({ searchParams, setMsgBoxData, msgBoxAnchorRef, handleCartAmount, ...props }) {
  React.useEffect(() => {
    if (searchParams.id) {
      /* → Changes the state to "Annulé" and clear session */
      promiseFetch(`${url}/api/orders/reject`, {
        method: 'POST',
        headers: { "Content-Type": "application/json; charset=utf-8" },
        body: JSON.stringify({
          orderId: searchParams.id
        })
      })
        .then(res => {
          if (res.status===400) {
            setMsgBoxData({ text: "Une erreur est survenue", classes: ["colorError"] });
          } else if (res.status===200 && res.data) {
            /* → Update the cart amount displayed */
            handleCartAmount(null);
            /* → And redirect to profile */
            props.history.replace('/profile');
          }
        })
    } else {
      setMsgBoxData({ text: "Une erreur est survenue", classes: ["colorError"] });
    }
  }, []);
  return (
    <div className="emptyMsgBoxAnchor" ref={msgBoxAnchorRef}></div>
  );
})

/*==============================================================================
Main component
==============================================================================*/
export default withRouter(function OrderPage({ ...props }) {
  const { handleCartAmount } = React.useContext(ModalContext);
  /* Message Box data */
  const [msgBoxData, setMsgBoxData] = React.useState(null);
  const msgBoxAnchorRef = React.useRef(null);

  /* Redirection to profile (and clear the order from the session) */
  const onRedirectionToProfile = () => {
    promiseFetch(`${url}/api/orders/clearFromSessionAndCart`, { method: 'POST' })
      .then(res => {
        /* Put in condition to avoid redirection loop if clearFromSession fails */
        if (res.status===200) {
          handleCartAmount(null);
          props.history.replace('/profile');
        }
      })
  };
  /* Redirection to home */
  const onRedirectionToHome = () => {
    promiseFetch(`${url}/api/orders/clearFromSession`, { method: 'POST' })
      .then(res => {
        /* Put in condition to avoid redirection loop if clearFromSession fails */
        if (res.status===200) {
          props.history.push('/');
        }
      })
  };

  return (
    <>
      {props.location.state && props.location.state.orderData ?
        <>
          <ProfileHeader buttons={[
            { name: "Continuer mes achats", func: onRedirectionToHome },
            { name: "Annuler la commande", func: onRedirectionToProfile }
          ]} opened={true} style={{ height: "var(--header-size)" }} />
          <div className="orderPageContent">
            <div className="orderPageLeftLayout">
              <OrdersComponents orderData={props.location.state.orderData} />
            </div>
            <div className="orderPageRightLayout">
              <OrderInformationForm amount={props.location.state.orderData.amount} deliveryFee={props.location.state.orderData.deliveryFee}
                items={props.location.state.orderData.items} setMsgBoxData={setMsgBoxData} msgBoxAnchorRef={msgBoxAnchorRef} />
            </div>
          </div>
          {msgBoxData!==undefined && <MessageBox msgBoxData={msgBoxData} componentRef={msgBoxAnchorRef} mode="absolute2" />}
        </> :
        <>
          {props.location.hash==="#success" ?
            <OrderPageSuccess searchParams={convertSearchParamsToObject(props.location.search)}
              msgBoxAnchorRef={msgBoxAnchorRef} setMsgBoxData={setMsgBoxData} handleCartAmount={handleCartAmount} /> :
            <>
              {props.location.hash==="#cancel" ?
                <OrderPageCancel searchParams={convertSearchParamsToObject(props.location.search)}
                  msgBoxAnchorRef={msgBoxAnchorRef} setMsgBoxData={setMsgBoxData} handleCartAmount={handleCartAmount} /> :
                <Redirect to={{ pathname: "/" }} />
              }
            </>
          }
        </>
      }
    </>
  );
})
