import Auth, { AuthContext } from './Auth'
import Modal, { ModalContextProvider } from './Modal'
import React from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import MainPage from './scenes/MainPage'
import ProfilePage from './scenes/ProfilePage'
import OrderPage from './scenes/OrderPage'
import AdminPage from './scenes/AdminPage'
import MaintenancePage from './scenes/MaintenancePage'

import "./App.css"
import LoadingSpinner from './components/LoadingSpinner.component';

const PrivateRoute = ({ component: Component, ...rest }) => {
  /* Auth context */
  const { isAuthenticated, isLoading } = React.useContext(AuthContext);
  /* We redirect to / when we're done waiting for initial request checkIsAuth and it returns false */
  return (
    <Route {...rest}
      render={props => (
        isAuthenticated ?
          <Component {...props} /> :
          !isLoading && <Redirect to={{ pathname: '/', state: {from: rest.location} }}/>
      )}
    />
  );
}

const AdminRoute = ({ component: Component, ...rest }) => {
  /* Auth context */
  const { isAuthenticated, isAdmin, isLoading } = React.useContext(AuthContext);
  /* We redirect to / when we're done waiting for initial request checkIsAuth and it returns false */
  return (
    <Route {...rest}
      render={props => (
        (isAuthenticated && isAdmin) ?
          <Component {...props} /> :
          !isLoading && <Redirect to={{ pathname: '/', state: {from: rest.location} }}/>
      )}
    />
  );
}

function StatusCheck({ ...props }) {
  const { isInMaintenance, isAdmin, isLoading } = React.useContext(AuthContext);
  return (
    <>
      {!isLoading ?
        <>
          {isAdmin===false && isInMaintenance===true ?
            <MaintenancePage /> :
            <>{props.children}</>
          }
        </> :
        <LoadingSpinner />
      }
    </>
  );
}

export default function App() {
  return (
    <Router>
      <Auth>
        <StatusCheck>
          <ModalContextProvider>
            <Switch>
              <Route exact path='/' component={MainPage} />
              <PrivateRoute path='/profile' component={ProfilePage} />
              <PrivateRoute path='/order' component={OrderPage} />
              <AdminRoute path='/admin' component={AdminPage} />
            </Switch>
            <Modal />
          </ModalContextProvider>
        </StatusCheck>
      </Auth>
    </Router>
  );
}
