import React from 'react'
import { withRouter } from 'react-router'
import CartModal from './components/CartModal.component'
import AddCodeModal from './components/AddCodeModal.component'
import SignInModal from './components/SignInModal.component'
import ForgottenPasswordModal from './components/ForgottenPasswordModal.component'
import PasswordResetModal from './components/PasswordResetModal.component'
import OrdersModal from './components/OrdersModal.component'
import ModifyAccountModal from './components/ModifyAccountModal.component'
import ProductDetailsModal from './components/ProductDetailsModal.component'
import ProductExtrasSelectionModal from './components/ProductExtrasSelectionModal.component'
import CancelOrderModal from './components/CancelOrderModal.component'
import ConfirmationModal from './components/ConfirmationModal.component'
import LegalNoticeModal from './components/LegalNoticeModal.component'
import CgvModal from './components/CgvModal.component'
import SizeGuideModal from './components/SizeGuideModal.component'
import WithdrawalFormModal from './components/WithdrawalFormModal.component'

import { convertSearchParamsToObject } from './helperFunctions'
import { useUpdateCartAmount } from './hooks/cartHooks'

/*==============================================================================
Context
==============================================================================*/
export const ModalContext = React.createContext({});

/*==============================================================================
Component ModalContextProvider
==============================================================================*/
export function ModalContextProvider({ children }) {
  /* The cart amount to display */
  const [cartAmount, handleCartAmount] = useUpdateCartAmount();
  /* The props to pass to the modals */
  const [modalProps, setModalProps] = React.useState(null);
  const [superModalProps, setSuperModalProps] = React.useState(null);

  return (
    <>
      <ModalContext.Provider value={{ modalProps, setModalProps, superModalProps, setSuperModalProps, cartAmount, handleCartAmount }}>
        {children}
      </ModalContext.Provider>
    </>
  );
}

/*==============================================================================
Hooks
==============================================================================*/
function useUpdateCurrentSuperModal(props, superModals, superModalProps) {
  /* The currently selected modals */
  const [currentSuperModal, setCurrentSuperModal] = React.useState(null);

  /* We change the modal depending on the hash location */
  React.useLayoutEffect(() => {
    const fullpath = props.location.pathname+props.location.hash;
    /* → SuperModals */
    if (superModals!==null && Object.keys(superModals).includes(fullpath)) {
      if (superModalProps!==null) {
        if (fullpath==='/#product-extras') {
          setCurrentSuperModal(<ProductExtrasSelectionModal data={superModalProps} />)
        } else {
          setCurrentSuperModal(superModals[fullpath])
        }
      } else {
        /* If we're refreshing while being in a superModal, we want to goBack to modal */
        props.history.goBack();
      }
    } else {
      setCurrentSuperModal(null);
    }
  }, [props.location, superModals, superModalProps]);

  return currentSuperModal;
}
/*==============================================================================
Hooks
==============================================================================*/
function useUpdateCurrentModal(location, modals, superModals, modalProps, handleCartAmount, props) {
  /* The currently selected modals */
  const [currentModal, setCurrentModal] = React.useState(null);
  /* We change the modal depending on the hash location */
  React.useLayoutEffect(() => {
    const CONFIRMATION_MODAL_PATHS = [
      '/admin#deleteproduct',
      '/admin#deletegroup',
      '/admin#deletemeditation',
      '/admin#productactivation',
      '/admin#onlylinkconfirmation',
      '/admin#expiredproceed'
    ];
    const fullpath = location.pathname+location.hash;
    /* → Modals */
    if (modals!==null && Object.keys(modals).includes(fullpath)) {
      /* If we're dealing with product and url search params */
      if (fullpath==='/#product' && location.search!=="") {
        const params = convertSearchParamsToObject(location.search);
        if (Object.keys(params).includes("pid") && params.pid!=="" && modalProps) {
          /* We check if the product is not disabled */
          if (modalProps.productData.disabled===true) {
            props.history.goBack();
            return;
          }
          /* Search params must implement : ?pid=X&selected=X,X  entry 'selected' is not mandatory */
          const selectedExtras = params.selected && params.selected.split(',').map(entry => entry!==''?'#'+entry:'');
          setCurrentModal(<ProductDetailsModal data={modalProps} pid={'#'+params.pid} selectedExtras={selectedExtras} updateAddedToCart={handleCartAmount} />);
        }
      } else if (fullpath==='/admin#cancelorder') {
        if (!modalProps) {
          props.history.goBack();
        } else {
          setCurrentModal(<CancelOrderModal data={modalProps} />);
        }
      } else if (CONFIRMATION_MODAL_PATHS.includes(fullpath)) {
        if (!modalProps) {
          props.history.goBack();
        } else {
          setCurrentModal(<ConfirmationModal data={modalProps} />);
        }
      } else {
        setCurrentModal(modals[fullpath]);
      }
    // If the url is not for superModals and not for modals we remove the modal
    } else {
      if (superModals===null || !Object.keys(superModals).includes(fullpath)) {
        setCurrentModal(null);
      }
    }
  }, [location, modals, modalProps]);

  return currentModal;
}

/*==============================================================================
Component Modal
==============================================================================*/
export default withRouter(function Modal({ children, ...props }) {
  const { modalProps, superModalProps, handleCartAmount } = React.useContext(ModalContext);
  /* Creating the modals */
  const [modals, setModals] = React.useState(null);
  const [superModals, setSuperModals] = React.useState(null);
  /* The currently selected modals */
  const currentModal = useUpdateCurrentModal(props.location, modals, superModals, modalProps, handleCartAmount, props);
  const currentSuperModal = useUpdateCurrentSuperModal(props, superModals, superModalProps);

  /* Callback for unfocusing */
  const unfocusModal = () => {
    if (['/order', '/admin'].includes(props.history.location.pathname)) {
      props.history.goBack();
    } else if (currentSuperModal===null) {
      props.history.push(props.history.location.pathname);
    } else {
      props.history.goBack();
    }
  }
  /* Events for unfocus handling */
  const handleClickEvent = (e) => {
    /* When we have a superModal we check if we click on any element descending from backgroundBlur to unfocus */
    if (currentSuperModal!==null && e.target.className==='backgroundDark') {
      unfocusModal();
    } else if (e.target.className==='backgroundBlur') {
      unfocusModal();
    }
  }
  const handleKeyDownEvent = (e) => (e.key==='Escape') && unfocusModal();

  React.useEffect(() => {
    if (currentModal || currentSuperModal) {
      window.addEventListener('click', handleClickEvent);
      window.addEventListener('keydown', handleKeyDownEvent);
      /* Prevent the body scrolling behaviour when modal exists */
      document.body.style.overflow = "hidden";
      document.body.style.height = "100%";
    }
    return () => {
      window.removeEventListener('click', handleClickEvent);
      window.removeEventListener('keydown', handleKeyDownEvent);
      /* Return to normal scrolling behaviour */
      document.body.style.overflow = "auto";
      document.body.style.height = "auto";
    };
  }, [currentModal, currentSuperModal])

  /* Execute at construction */
  React.useEffect(() => {
    const onForgottenPassword = () => {
      props.history.push('#forgottenpassword');
    }
    const addCodeModal = <AddCodeModal />
    const cartModal = <CartModal updateAddedToCart={handleCartAmount} />
    const signInModal = <SignInModal callback={onForgottenPassword} />
    const forgottenPasswordModal = <ForgottenPasswordModal />
    const passwordResetModal = <PasswordResetModal />
    /* Private route modals */
    const ordersModal = <OrdersModal />
    const modifyAccountModal = <ModifyAccountModal />
    /* Legal documents */
    const legalNoticeModal = <LegalNoticeModal />
    const cgvModal = <CgvModal />
    const withdrawalFormModal = <WithdrawalFormModal />
    /* Other */
    const sizeGuideModal = <SizeGuideModal />

    setModals({
      '/#addcode': addCodeModal,
      '/#cart': cartModal,
      '/#signin': signInModal,
      '/#forgottenpassword': forgottenPasswordModal,
      '/#passwordreset': passwordResetModal,
      '/#product': null,
      '/#legalnotice': legalNoticeModal,
      '/#cgv': cgvModal,
      '/#withdrawalform': withdrawalFormModal,
      '/profile#cart': cartModal,
      '/profile#orders': ordersModal,
      '/profile#modifyaccount': modifyAccountModal,
      '/order#cgv': cgvModal,
      '/admin#cancelorder': null,
      '/admin#deleteproduct': null,
      '/admin#deletegroup': null,
      '/admin#deletemeditation': null,
      '/admin#productactivation': null,
      '/admin#onlylinkconfirmation': null,
      '/admin#expiredproceed': null,
    });

    /* The super modals are above modals (we only allow 2 levels of modals) */
    setSuperModals({
      '/#product-extras': null,
      '/#size-guide': sizeGuideModal,
    });
  }, []);

  return (
    <>
      {currentModal}
      {currentSuperModal}
    </>
  )
})
