import React from 'react'
import { withRouter } from 'react-router'
import Modal from './Modal.component'

export default withRouter(function CgvModal({ ...props }) {
  /* Handle product deletion logic */
  const onConfirmAction = (e) => {
    e.preventDefault();
    props.history.goBack();
  };

  return (
    <Modal className="legalDocumentModalBox">
      <div className="legalDocumentText">
        <h1>Conditions générales de vente</h1>
        <h4>Date de dernière mise à jour : 25/04/2021<br/></h4>
        <h2>Article 1 – MENTION LÉGALES</h2>
        <span>
          Le présent site, accessible à l’URL https://www.synesthesia.fr (le « Site »), est édité par :<br/><br/>

          ESPACE MARIELLE GIRAULT société au capital de 2000,00 euros, inscrite au R.C.S. de RCS Lyon sous le numéro 521 545 798, dont le siège social est situé au 2 rue de thou, 69001 Lyon, France, représenté(e) par Girault Marielle dument habilité(e), (Ci-après désigné l’« Exploitant »).<br/><br/>

          Le numéro individuel TVA de l’Exploitant est : FR52521545798.<br/><br/>

          Le Site est hébergé par la société OVH, situé 2 rue Kellermann, 59100 Roubaix, France, (téléphone : 0899701761).<br/><br/>

          Le Directeur de la publication du Site est Watkins William.<br/><br/>

          L’Exploitant peut être joint au numéro de téléphone suivant 0667075110 et à l’adresse mail suivante synesthesia.fr@gmail.com.
        </span>
        <h2>Article 2 – DISPOSITIONS GÉNÉRALES RELATIVES AUX PRÉSENTES CONDITIONS GÉNÉRALES</h2>
        <span>
          Les conditions générales de Vente (les « Conditions Générales de Vente », ou les « CGV ») sont applicables exclusivement à la vente en ligne des produits proposés par l’Exploitant sur le Site internet.<br/><br/>
          Les CGV sont mises à la disposition des clients sur le Site où elles sont directement consultables et peuvent également lui être communiquées sur simple demande par tout moyen.<br/><br/>
          Les CGV sont opposables au client qui reconnaît, en cochant une case ou en cliquant sur le bouton prévu à cet effet, en avoir eu connaissance et les avoir acceptées avant de passer commande. La validation de la commande par sa confirmation vaut adhésion par l'acheteur aux CGV en vigueur au jour de la commande dont la conservation et la reproduction sont assurées par l’Exploitant.
        </span>
        <h2>Article 3 – DESCRIPTION DES PRODUITS</h2>
        <span>
          Le Site est un site de vente en ligne de Bracelets, Huiles essentielles et Composés de fleurs (ci-après le(s) « Produit(s)») ouvert à toute personne physique ou morale utilisant le Site (le « Client »).<br/><br/>
          Les Produits présentés sur le Site font chacun l'objet d'un descriptif (établi par le fournisseur ou accessible sur le site du fabricant par un lien se trouvant sur le Site) mentionnant leurs caractéristiques essentielles. Les photographies illustrant, le cas échéant, les produits ne constituent pas un document contractuel. La notice d'utilisation du Produit, si c'est un élément essentiel, figure sur le Site ou est au plus tard adressée à la livraison. Les Produits sont conformes aux prescriptions du droit français en vigueur.<br/><br/>
          Le Client demeure responsable des modalités et des conséquences de son accès au Site notamment par l’Internet. Cet accès peut impliquer le paiement de frais à des prestataires techniques tels que notamment des fournisseurs d’accès à l’Internet, lesquels demeurent à sa charge. En outre, le Client devra fournir et être entièrement responsable des équipements nécessaires afin de se connecter au Site.<br/><br/>
          Le Client reconnait avoir vérifié que la configuration informatique qu’il utilise est sécurisée et en état de fonctionnement.
        </span>
        <h2>Article 4 – CREATION DE L’ESPACE CLIENT</h2>
        <span>
          Pour passer une commande sur le Site, le Client doit au préalable créer son espace client personnel. Une fois créé, pour y accéder, le Client doit s’identifier en utilisant son identifiant et son mot de passe secret, personnel et confidentiel. Il appartient au Client de ne pas communiquer son identifiant et son mot de passe conformément aux dispositions de l’article DONNEES PERSONNELLES des présentes Conditions Générales. Chaque Client s'engage à conserver une stricte confidentialité sur les données, en particulier identifiant et mot de passe, lui permettant d'accéder à son espace client, le Client reconnaissant être le seul responsable de l'accès au Service par le biais de son identifiant et de son mot de passe, sauf fraude avérée. Chaque Client s'engage en outre à informer sans délai l’Exploitant dans l'hypothèse d'une perte, d'un détournement ou de l'utilisation frauduleuse de son identifiant et/ou mot de passe.<br/><br/>

          Le Client s'engage lors de son inscription à :
          <ul>
            <li>
              délivrer des informations réelles, exactes, à jour au moment de leur saisie dans le formulaire d'inscription du service, et notamment à ne pas utiliser de faux noms ou adresses, ou encore des noms ou adresses sans y être autorisé. 
            </li>
            <li>
              maintenir à jour les données d'inscriptions en vue de garantir en permanence leur caractère réel, exact et à jour.
            </li>
          </ul>
          Le Client s'engage en outre à ne pas rendre disponible ou distribuer des informations illicites ou répréhensibles (telles que des informations diffamatoires ou constitutive d'usurpation d'identité) ou encore nuisibles (telles que les virus). Dans le cas contraire, l’Exploitant sera en mesure de suspendre ou de résilier l'accès du Client au Site à ses torts exclusifs.
        </span>
        <h2>Article 5 – COMMANDES</h2>
        <span>
          L’Exploitant s’efforce de garantir une disponibilité optimale de ses Produits. Les offres de Produits sont valables dans la limite des stocks disponibles.<br/><br/>

          Si en dépit des meilleurs efforts de l’Exploitant, un Produit s’avérerait indisponible postérieurement à la commande du Client, l’Exploitant en informera le Client par email, dans les meilleurs délais et le Client aura le choix entre : 
          <ul>
            <li>
              la livraison d’un Produit d’une qualité et d’un prix équivalent à celui initialement commandé, ou
            </li>
            <li>
              le remboursement du prix du Produit commandé au plus tard dans les trente (30) jours du paiement des sommes déjà versées. 
            </li>
          </ul>

          Il est convenu qu’en dehors du remboursement du prix du Produit indisponible, si cette option est demandée par le Client, l’Exploitant n'est tenu à aucune indemnité d'annulation, sauf si l'inexécution du contrat lui est personnellement imputable.<br/><br/>

          A l’exception de toute mention contraire figurant dans ces Conditions Générales et sans préjudice du droit de rétractation prévu par la loi applicable, les commandes du Client sont fermes et définitives.<br/><br/><br/>

          Au moment de passer une commande, le Client doit sélectionner les Produits choisis, les ajouter à son panier en indiquant les Produits sélectionnés et les quantités souhaitées. Le Client a la possibilité de vérifier le détail de sa commande et son prix total, et de revenir aux pages précédentes pour éventuellement corriger le contenu de son panier, avant de le valider.<br/><br/>

          Le Client s’engage à lire les Conditions Générales de Vente alors en vigueur avant de les accepter et de confirmer les modalités et les éventuels frais de livraison et de rétractation préalable au paiement de sa commande. La confirmation de la commande entraîne acceptation des CGV et forme le contrat.<br/><br/>

          Les informations contractuelles relatives à la commande (dont notamment le n° de la commande) feront l'objet d'une confirmation par voie d'e-mail en temps utile et au plus tard au moment de la livraison. L’Exploitant conseille vivement au Client d'imprimer et/ou d'archiver sur un support fiable et durable cette confirmation de commande à titre de preuve.<br/><br/>

          Tout email qui sera adressé au Client dans le cadre d’une commande le sera à l’adresse email que le Client a renseignée au moment du passage de la commande s’il diffère de celui utilisé pour s’identifier dans son espace client.<br/><br/>

          L’Exploitant se réserve le droit de ne pas valider la commande du Client pour tout motif légitime, notamment dans l’hypothèse où : 
          <ul>
            <li>
              Le Client ne respecterait pas les Conditions Générales en vigueur lors de sa commande ; 
            </li>
            <li>
              L’historique de commandes du Client montre que des sommes restent dues au titre de précédentes commandes ;
            </li>
            <li>
              L’une des précédentes commandes du Client fait l’objet d’un litige en cours de traitement ; 
            </li>
            <li>
              Le Client n’a pas répondu à une demande de confirmation de sa commande que l’Exploitant lui a fait parvenir.
            </li>
          </ul>

          L’Exploitant archive les contrats de vente de Produits conformément à la législation applicable. En formulant une demande à l’adresse suivante synesthesia.fr@gmail.com, l’Exploitant remettra au Client une copie du contrat objet de la demande.<br/><br/>

          Toute modification de commande par le Client après confirmation de sa commande est soumise à l'accord de l’Exploitant.<br/><br/>

          Les informations communiquées par le Client lors de la passation de la commande (notamment nom et adresse de livraison) engagent celui-ci. Ainsi, la responsabilité de l’Exploitant ne saurait en aucune manière être recherchée dans l'éventualité où une erreur lors de la passation de la commande empêcherait ou retarderait la livraison/la délivrance.<br/><br/>

          Le Client déclare avoir la pleine capacité juridique lui permettant de s'engager au titre des présentes Conditions Générales.<br/><br/>

          L'inscription est ouverte aux majeurs capables et aux mineurs à conditions que ceux-ci interviennent sous la surveillance du parent ou tuteur détenant l'autorité parentale. En aucun cas, l'inscription n'est autorisée pour le compte de tiers à moins d'être valablement habilité à la représenter (personne morale par exemple). L'inscription est strictement personnelle à chaque Client.<br/><br/>

          En cas de manquement par le Client à l'une des dispositions des présentes, l’Exploitant se réserve le droit de résilier sans préavis le compte dudit Client.
        </span>
        <h2>Article 6 – MODALITES DE PAIEMENT ET SÉCURISATION</h2>
        <span>
          Le Client reconnaît expressément que toute commande effectuée sur le Site est une commande avec obligation de paiement, qui nécessite le paiement d’un prix contre la fourniture du Produit commandé.<br/><br/>

          En toute hypothèse, l’Exploitant se réserve le droit de contrôler la validité du règlement, avant l'expédition de la commande, par tous les moyens nécessaires.<br/><br/>

          L’Exploitant utilise la solution de paiement en ligne Monetico.<br/><br/>

          Les commandes peuvent être payées en ayant recours à l’un des modes de paiement suivants :
          <ul>
            <li>
              Paiement par carte bancaire. Le paiement s'effectue directement sur les serveurs bancaires sécurisés de la banque de l’Exploitant, les coordonnées bancaires du Client ne transitent pas sur le Site. Les coordonnées bancaires communiquées lors du paiement sont protégées par un procédé de cryptage SSL (Secure Socket Layer). De cette manière, ces coordonnées ne sont pas accessibles à des tiers.<br/><br/>

              La commande du Client est enregistrée et validée dès acceptation du paiement par la banque.<br/><br/>

              Le compte du Client sera débité du montant correspondant uniquement lorsque (i) les données de la carte bancaire utilisée auront été vérifiées, (ii) le débit aura été accepté par la banque ayant émis la carte bancaire et (iii) quand la commande aura été validée par l’Exploitant, et ce dans un délai maximum de 7 jours qui si dépassé entrainera l’annulation automatique du paiement et de la commande.<br/><br/>

              L’impossibilité de débiter les sommes dues entraînera la nullité immédiate de la vente.<br/><br/>

              La carte bancaire peut notamment être refusée si elle est arrivée à expiration, si elle a atteint le montant maximal de dépense auquel le Client a droit ou si les données saisies sont incorrectes.<br/><br/>
            </li>
          </ul>

          Le cas échéant, la commande validée par le Client ne sera considérée comme effective que lorsque le centre de paiement bancaire sécurisé aura donné son accord sur la transaction.<br/><br/>

          Dans le cadre des procédures de contrôle, l’Exploitant pourra avoir à demander au Client toutes les pièces nécessaires à la finalisation de sa commande. Ces pièces ne seront pas utilisées à d’autres fins que celles-ci.
        </span>
        <h2>Article 7 – PAIEMENT DU PRIX</h2>
        <span>
          Le prix des Produits en vigueur lors de la commande est indiqué en euros toutes taxes comprises (TTC) hors frais de livraison et de transport. En cas de promotion, l’Exploitant s'engage à appliquer le prix promotionnel à toute commande passée durant la période de la publicité faite pour la promotion.<br/><br/>

          Le prix est payable en euros (€) exclusivement. Le prix est exigible en totalité après confirmation de la commande. Les prix proposés comprennent les rabais et ristournes que l’Exploitant serait amené à octroyer.<br/><br/>

          Si des frais de livraison ou de transport s’appliquent, ils seront ajoutés au prix des Produits et indiqués de manière distincte avant la validation de la commande par le Client. Le montant total dû par le Client et son détail sont indiqués sur la page de confirmation de commande.
        </span>
        <h2>Article 8 – FORMATION DU CONTRAT</h2>
        <span>
          Le contrat entre l’Exploitant et le Client est formé au moment de l'envoi par le Client de la confirmation de sa commande.<br/><br/>

          L'attention du Client est particulièrement attirée sur le mode d'acceptation de la commande passée sur le Site. Lorsque le Client passe sa commande il doit confirmer celle-ci par la technique du « double-clic », c'est-à-dire qu'après avoir sélectionné des Produits ajoutés au panier, le Client doit contrôler et éventuellement corriger le contenu de son panier (identification, quantité de produits sélectionnées, prix, modalités et frais de livraison) avant de le valider en cliquant sur « Commander », puis il reconnaît accepter les présentes CGV avant de cliquer sur le bouton « Procéder au paiement », enfin il valide sa commande après avoir rempli ses coordonnées bancaires. Le « double clic » vaut signature électronique et équivaut à une signature manuscrite. Il constitue une acceptation irrévocable et sans réserve de la commande par le Client.<br/><br/>

          L'archivage des communications, des bons de commande et des factures est assuré par l’Exploitant sur un support fiable et durable de manière à constituer une copie fidèle et durable. Ces communications, bons de commande et factures peuvent être produits à titre de preuve du contrat. Sauf preuve contraire, les données enregistrées par l’Exploitant sur Internet ou par téléphone constituent la preuve de l'ensemble des transactions passées entre l’Exploitant et ses Clients.<br/><br/>

          La commande peut être résolue par le Client par lettre recommandée avec demande d'avis de réception ou par un écrit sur un autre support durable en cas :
          <ul>
            <li>
              de livraison d'un Produit non conforme aux caractéristiques déclarées du Produit ;
            </li>
            <li>
              de livraison dépassant la date limite fixée dans le bon de commande ou, à défaut d'une telle date, dans les trente (30) jours suivant la conclusion du contrat, après que l’Exploitant ait été enjoint, selon les mêmes modalités et sans résultat, d'effectuer la livraison dans un délai supplémentaire raisonnable ;
            </li>
            <li>
              de hausse du prix qui n'est pas justifiée par une modification technique du produit imposée par les pouvoirs publics.
            </li>
          </ul>

          Dans tous ces cas, le Client peut exiger le remboursement de l'acompte versé majoré des intérêts calculés au taux légal à partir de la date d'encaissement de l'acompte.<br/><br/>

          La commande peut être résolue par l’Exploitant en cas :
          <ul>
            <li>
              de refus de l'acheteur de prendre livraison ;
            </li>
            <li>
              de non-paiement du prix (ou du solde du prix) au moment de la livraison.
            </li>
          </ul>
        </span>
        <h2>Article 9 – RÉSERVE DE PROPRIÉTÉ</h2>
        <span>
          L’Exploitant reste propriétaire exclusif des Produits commandés sur le Site jusqu’à encaissement de l’intégralité du prix, en ce compris les éventuels frais de port.
        </span>
        <h2>Article 10 – EXPÉDITION ET LIVRAISON</h2>
        <span>
          Les offres de vente en ligne présentées sur le site sont réservées aux consommateurs résidant en France ou, le cas échéant, dans un pays membre de l'Union européenne, et pour des livraisons dans ces mêmes zones géographiques.<br/><br/>

          La livraison s'entend du transfert au Client de la possession physique ou du contrôle du Produit.<br/><br/>

          L’Exploitant vous propose des modes de livraison ou de délivrance différents selon la nature du produit : Livraison en recommandée par les services de La Poste.<br/><br/>

          Les frais d’envoi sont ceux précisés lors de la finalisation de la commande et sont acceptés par la validation de la commande.<br/><br/>

          L’Exploitant s'engage, conformément à la date limite de livraison indiquée sur le Site pour chacun des Produits, à livrer les Produits dans un délai maximum de trente (30) jours après réception de commande.<br/><br/>

          Les délais de livraison sont annoncés en jours ouvrés sur le Site lors de la commande. Ces délais incluent la préparation et l’expédition de la commande ainsi que le délai prévu par le transporteur.<br/><br/>

          L’Exploitant s’engage à expédier les Produits conformément aux délais annoncés sur chaque fiche Produit et au niveau du panier, sous réserve que le paiement de la commande n’ait pas été préalablement refusé.<br/><br/>

          Cependant, si un ou plusieurs Produits ne pouvaient être livrés dans le délai initialement annoncé, l’Exploitant adressera un email indiquant au Client la nouvelle date de livraison.<br/><br/>

          Les Produits seront livrés à l'adresse indiquée par le Client lors de sa commande. Il lui appartient donc de vérifier que cette adresse ne contient pas d’erreur. La responsabilité de l’Exploitant ne pourra être engagée si l’adresse communiquée par le Client est erronée, empêchant ou retardant ainsi la livraison.<br/><br/>

          A la livraison, il pourra être demandé de signer un bon de réception.<br/><br/>

          Aucune livraison ne sera effectuée à une boîte postale.<br/><br/>
          A la livraison, il appartient au Client de vérifier que les Produits livrés sont conformes à sa commande et que le colis est scellé et non endommagé. Si ce n’est pas le cas, le Client doit impérativement l’indiquer sur le bordereau de livraison. Aucune réclamation sur la quantité ou l’état du Produit ne sera admise si la réclamation n’a pas été portée sur le bordereau de livraison.
        </span>
        <h2>Article 11 – DROIT DE RÉTRACTATION</h2>
        <span>
          Si un Produit livré ne donne pas entière satisfaction au Client, ce dernier pourra le retourner à l’Exploitant. Le Client disposera de quatorze (14) jours pour le faire à compter de la date de réception de la commande.<br/><br/>

          Conformément à l’article L.221-21 du Code de la consommation et afin de mettre en œuvre ce droit de rétractation dans les conditions des articles L. 221-18 et suivants du Code de la consommation, le Client est invité à remplir le formulaire type de rétractation en cliquant sur le lien ci-après <a href="https://www.synesthesia.fr/#withdrawalform">https://www.synesthesia.fr/#withdrawalform.</a><br/><br/>

          L’Exploitant adressera un accusé de réception de la demande de rétractation du Client par e-mail.<br/><br/>

          Le cas échéant, le Client peut exercer son droit de rétractation en notifiant les informations suivantes à l’Exploitant : 
          <ul>
            <li>
              nom, adresse géographique, numéro de téléphone et adresse électronique ;
            </li>
            <li>
              décision de rétractation au moyen d'une déclaration dénuée d'ambiguïté (par exemple, lettre envoyée par la poste, télécopie ou courrier électronique dès lors que ces coordonnées sont disponibles et de ce fait apparaissent sur le formulaire type de rétractation). Le Client peut utiliser le modèle de formulaire de rétractation mais ce n'est pas obligatoire.
            </li>
          </ul>

          Les frais de retour sont à la charge du Client, sauf si le bien ne peut être normalement renvoyé par la Poste, auquel cas l’Exploitant récupèrera le Produit à ses frais.<br/><br/>

          Les exceptions de l’article L.221-28 du Code de la Consommation s’appliquent et font obstacle à l’exercice du droit de rétractation, notamment si la commande consiste en un contrat :
          <ul>
            <li>
              de fourniture de services pleinement exécutés avant la fin du délai de rétractation et dont l'exécution a commencé après accord préalable exprès du consommateur et renoncement exprès à son droit de rétractation ;
            </li>
            <li>
              de fourniture de biens ou de services dont le prix dépend de fluctuations sur le marché financier échappant au contrôle du professionnel et susceptibles de se produire pendant le délai de rétractation ;
            </li>
            <li>
              de fourniture de biens confectionnés selon les spécifications du consommateur ou nettement personnalisés ;
            </li>
            <li>
              de fourniture de biens susceptibles de se détériorer ou de se périmer rapidement ;
            </li>
            <li>
              de fourniture de biens qui ont été descellés par le consommateur après la livraison et qui ne peuvent être renvoyés pour des raisons d'hygiène ou de protection de la santé;
            </li>
            <li>
              de fourniture de biens qui, après avoir été livrés et de par leur nature, sont mélangés de manière indissociable avec d'autres articles ;
            </li>
            <li>
              de fourniture de boissons alcoolisées dont la livraison est différée au-delà de trente (30) jours et dont la valeur convenue à la conclusion du contrat dépend de fluctuations sur le marché échappant au contrôle du professionnel ;
            </li>
            <li>
              de travaux d'entretien ou de réparation à réaliser en urgence au domicile du consommateur et expressément sollicités par lui, dans la limite des pièces de rechange et travaux strictement nécessaires pour répondre à l'urgence ;
            </li>
            <li>
              de fourniture d'enregistrements audio ou vidéo ou de logiciels informatiques lorsqu'ils ont été descellés par le consommateur après la livraison ;
            </li>
            <li>
              de fourniture d'un journal, d'un périodique ou d'un magazine, sauf pour les contrats d'abonnement à ces publications ;
            </li>
            <li>
              conclu lors d'une enchère publique ;
            </li>
            <li>
              de prestation de services d'hébergement, autres que d'hébergement résidentiel, de services de transport de biens, de locations de voitures, de restauration ou d'activités de loisirs qui doivent être fournis à une date ou à une période déterminée ;
            </li>
            <li>
              de fourniture d'un contenu numérique non fourni sur un support matériel dont l'exécution a commencé après accord préalable exprès du consommateur et renoncement exprès à son droit de rétractation.
            </li>
          </ul>

          Le Produit retourné doit l’être dans son emballage d'origine, en parfait état, propre à sa revente, non utilisé et avec l’ensemble des accessoires éventuels.<br/><br/>

          Outre le Produit retourné, le colis de retour doit également contenir un courrier précisant les coordonnées exactes (nom, prénom, adresse) et complètes du Client ainsi que le numéro de commande, et la facture originale d’achat.<br/><br/>

          L’Exploitant remboursera au Client le montant du Produit dans un délai de quatorze (14) jours à compter de la réception du Produit et de l’ensemble des éléments permettant de mettre en œuvre le remboursement du Client. Ce remboursement pourra être effectué par le même moyen de paiement que celui employé pour le Client. A ce titre, le Client ayant réglé sa commande sous forme d'avoirs / bons cadeau pourront être remboursés par avoirs / bons cadeau selon la volonté de l’Exploitant.<br/><br/>

          En acceptant les présentes Conditions Générales de Vente, le Client reconnaît expressément avoir été informé des modalités de rétractation.
        </span>
        <h2>Article 12 – SERVICE CLIENTS</h2>
        <span>
          Le Client peut contacter l’Exploitant : 
          <ul>
            <li>
              au numéro suivant 0667075110 au jour et heures d’ouverture suivants : du Lundi au Vendredi de 9h à 18h. 
            </li>
            <li>
              par email en s'adressant à synesthesia.fr@gmail.com en indiquant son nom, numéro de téléphone, l’objet de sa demande et le numéro de la commande concernée.
            </li>
          </ul>
        </span>
        <h2>Article 13 – PROPRIÉTÉ INTELLECTUELLE ET LICENCE D’UTILISATION DU SITE</h2>
        <span>
          L’Exploitant est seul titulaire de tous les éléments présent sur le Site, notamment et sans limitation, tous textes, fichiers, images animées ou non, photographies, vidéos, logos, dessins, modèles, logiciels, marques, identité visuelle, base de données, structure du Site et tous autres éléments de propriété intellectuelle et autres données ou informations (ci-après, les « Éléments ») qui sont protégés par les lois et règlements français et internationaux relatifs notamment à la propriété intellectuelle.<br/><br/>

          En conséquence, aucun des Éléments du Site ne pourra en tout ou partie être modifié, reproduit, copié, dupliqué, vendu, revendu, transmis, publié, communiqué, distribué, diffusé, représenté, stocké, utilisé, loué ou exploité de toute autre manière, à titre gratuit ou onéreux, par un Client ou par un tiers, quel que soient les moyens et/ou les supports utilisés, qu’ils soient connus ou inconnus à ce jour, sans l’autorisation préalable exprès et écrite de l’Exploitant au cas par cas, et le Client est seul responsable de toute utilisation et/ou exploitation non autorisée.<br/><br/>

          L’Exploitant se réserve la possibilité de saisir toutes voies de droit à l’encontre des personnes qui n’auraient pas respecté les interdictions contenues dans le présent article.
        </span>
        <h2>ARTICLE 14 – RESPONSABILITÉ ET GARANTIE</h2>
        <span>
          L’Exploitant ne saurait être tenu pour responsable de l'inexécution du contrat du fait du Client ou en raison d'un événement qualifié de force majeure par les tribunaux compétents ou encore du fait imprévisible et insurmontable de tout tiers aux présentes.<br/><br/>
          Le Client reconnaît que les caractéristiques et les contraintes d'Internet ne permettent pas de garantir la sécurité, la disponibilité et l'intégrité des transmissions de données sur Internet. Ainsi, l’Exploitant ne garantit pas que le Site et ses services fonctionneront sans interruption ni erreur de fonctionnement. En particulier, leur exploitation pourra être momentanément interrompue pour cause de maintenance, de mises à jour ou d'améliorations techniques, ou pour en faire évoluer le contenu et/ou leur présentation.<br/><br/>
          L’Exploitant ne peut être tenu pour responsable de l'utilisation qui serait faite du Site et de ses services par les Clients en violation des présentes Conditions Générales et des dommages directs ou indirects que cette utilisation pourrait causer à un Client ou à un tiers. En particulier, l’Exploitant ne peut être tenu pour responsable des fausses déclarations faites par un Client et de son comportement vis-à-vis des tiers. Dans le cas où la responsabilité de l’Exploitant serait recherchée à raison d'un tel comportement d’un de ses Clients, ce dernier s'engage à garantir l’Exploitant contre toute condamnation prononcée à son encontre ainsi qu’à rembourser l’Exploitant de l’ensemble des frais, notamment les honoraires d’avocats, engagés pour sa défense.<br/><br/>
          Indépendamment de toute garantie contractuelle additionnelle (garantie commerciale) qui pourrait être consentie, les Produits bénéficient de la garantie légale de conformité prévue aux articles L. 217-4 et suivants du Code de la consommation (notamment L. 217-4 à L. 217-14 du Code de la consommation), et de la garantie des vices cachés prévue aux articles 1641 à 1649 du Code civil.<br/><br/>
          Lorsque vous agissez dans le cadre de la garantie légale de conformité :
          <ul>
            <li>
              vous bénéficiez d'un délai de deux (2) ans à compter de la délivrance du bien pour agir :
            </li>
            <li>
              vous pouvez choisir entre la réparation ou le remplacement du bien, sous réserve des conditions de coût prévues par l'article L. 217-9 du Code de la consommation ;
            </li>
            <li>
              vous êtes dispensé de rapporter la preuve de l'existence du défaut de conformité du bien durant les vingt-quatre (24) mois suivant la délivrance du bien (sauf biens d’occasion). 
            </li>
          </ul>

          Vous pouvez décider de mettre en œuvre la garantie contre les défauts cachés de la chose vendue au sens de l'article 1641 du Code civil. Dans cette hypothèse, vous pouvez choisir entre la résolution de la vente ou une réduction du prix de vente conformément à l'article 1644 du Code civil.
          Reproduction des articles L. 217-4, L. 217-5, L. 217-7, L. 217-9 et L. 217-12 du Code de la consommation, de l'article 1641, 1644 et du premier alinéa de l'article 1648 du Code civil, tels qu’en vigueur à la date des présentes Conditions Générales de Vente :<br/><br/>
          Art. L.217-4 du code de la consommation :<br/>
          « Le vendeur livre un bien conforme au contrat et répond des défauts de conformité existant lors de la délivrance. Il répond également des défauts de conformité résultant de l'emballage, des instructions de montage ou de l'installation lorsque celle-ci a été mise à sa charge par le contrat ou a été réalisée sous sa responsabilité. »<br/><br/>
          Art. L.217-5 du code de la consommation :<br/>
          « Le bien est conforme au contrat :<br/>
          1° S'il est propre à l'usage habituellement attendu d'un bien semblable et, le cas échéant :<br/>
          - s'il correspond à la description donnée par le vendeur et possède les qualités que celui-ci a présentées à l'acheteur sous forme d'échantillon ou de modèle ;<br/>
          - s'il présente les qualités qu'un acheteur peut légitimement attendre eu égard aux déclarations publiques faites par le vendeur, par le producteur ou par son représentant, notamment dans la publicité ou l'étiquetage ;<br/>
          2° Ou s'il présente les caractéristiques définies d'un commun accord par les parties ou est propre à tout usage spécial recherché par l'acheteur, porté à la connaissance du vendeur et que ce dernier a accepté. »<br/><br/>
          Art. L.217-7 du code de la consommation :<br/>
          « Les défauts de conformité qui apparaissent dans un délai de vingt-quatre mois à partir de la délivrance du bien sont présumés exister au moment de la délivrance, sauf preuve contraire.<br/>
          Pour les biens vendus d'occasion, ce délai est fixé à six mois.<br/>
          Le vendeur peut combattre cette présomption si celle-ci n'est pas compatible avec la nature du bien ou le défaut de conformité invoqué. »<br/><br/>
          Art. L.217-9 du code de la consommation :<br/>
          « En cas de défaut de conformité, l'acheteur choisit entre la réparation et le remplacement du bien. Toutefois, le vendeur peut ne pas procéder selon le choix de l'acheteur si ce choix entraîne un coût manifestement disproportionné au regard de l'autre modalité, compte tenu de la valeur du bien ou de l'importance du défaut. Il est alors tenu de procéder, sauf impossibilité, selon la modalité non choisie par l'acheteur ».<br/><br/>
          Art. L.217-12 du code de la consommation :<br/>
          « L'action résultant du défaut de conformité se prescrit par deux ans à compter de la délivrance du bien. »<br/><br/>
          Art. 1641 du code civil :<br/>
          « Le vendeur est tenu de la garantie à raison des défauts cachés de la chose vendue qui la rendent impropre à l'usage auquel on la destine, ou qui diminuent tellement cet usage, que l'acheteur ne l'aurait pas acquise, ou n'en aurait donné qu'un moindre prix, s'il les avait connus. »<br/><br/>
          Art. 1644 du code civil :<br/>
          « Dans le cas des articles 1641 et 1643, l'acheteur a le choix de rendre la chose et de se faire restituer le prix, ou de garder la chose et de se faire rendre une partie du prix. »<br/><br/>
          Art. 1648 alinéa 1er du code civil :<br/>
          « L'action résultant des vices rédhibitoires doit être intentée par l'acquéreur dans un délai de deux ans à compter de la découverte du vice. »<br/><br/>

          Il est rappelé que la recherche de solutions amiables préalablement à une éventuelle action en justice n'interrompt pas les délais d'action des garanties légales ni la durée de toute éventuelle garantie contractuelle.
        </span>
        <h2>Article 15 – LIENS HYPERTEXTES</h2>
        <span>
          Les liens hypertextes disponibles sur le Site peuvent renvoyer vers des sites tiers non édités par l’Exploitant. Ils sont fournis uniquement pour la convenance du Client, afin de faciliter l’utilisation des ressources disponibles sur l’Internet. Si le Client utilise ces liens, il quittera le Site et acceptera alors d’utiliser les sites tiers à ses risques et périls ou le cas échéant conformément aux conditions qui les régissent.<br/><br/>

          Le Client reconnait que l’Exploitant ne contrôle ni ne contribue en aucune manière à l’élaboration des conditions d’utilisation et/ou du contenu s’appliquant à ou figurant sur ces sites tiers.<br/><br/>

          En conséquence, l’Exploitant ne saurait être tenu responsable de quelque façon que ce soit du fait de ces liens hypertextes.<br/><br/>

          En outre, le Client reconnait que l’Exploitant ne saurait cautionner, garantir ou reprendre à son compte tout ou partie des conditions d’utilisation et/ou du contenu de ces sites tiers.<br/><br/>

          Le Site peut également contenir des liens hypertextes promotionnels et/ou bandeaux publicitaires renvoyant vers des sites tiers non édités par l’Exploitant.<br/><br/>

          L’Exploitant invite le Client à lui signaler tout lien hypertexte présent sur le Site qui permettrait d’accéder à un site tiers proposant du contenu contraire aux lois et/ou aux bonnes mœurs.<br/><br/>

          Le Client ne pourra pas utiliser et/ou insérer de lien hypertexte pointant vers le site sans l’accord écrit et préalable de l’Exploitant au cas par cas.
        </span>
        <h2>ARTICLE 16 – RÉFÉRENCES</h2>
        <span>
          Le Client autorise l’Exploitant à mentionner le nom du Client, son logo à titre de référence dans ses supports de communication (plaquette, site internet, proposition commerciale, relation avec la presse, communiqué de presse, dossier de presse, communication interne, etc.).
        </span>
        <h2>ARTICLE 17 – DISPOSITION GÉNÉRALES</h2>
        <span>
          <b>INTEGRALITE DE L’ACCORD DES PARTIES</b><br/><br/>

          Les présentes Conditions Générales constituent un contrat régissant les relations entre le Client et l’Exploitant. Elles constituent l'intégralité des droits et obligations de la Société et de l’Exploitant relatifs à leur objet. Si une ou plusieurs stipulations des présentes Conditions Générales étaient déclarées nulles en application d'une loi, d'un règlement ou à la suite d'une décision définitive d'une juridiction compétente, les autres stipulations garderont toute leur force et leur portée. En outre, le fait pour une des parties aux présentes Conditions Générales de ne pas se prévaloir d'un manquement de l'autre partie à l'une quelconque des dispositions des présentes Conditions Générales ne saurait s'interpréter comme une renonciation de sa part à se prévaloir dans l'avenir d'un tel manquement.<br/><br/>

          <b>MODIFICATIONS DES CONDITIONS</b><br/><br/>

          L’Exploitant se réserve le droit de modifier à tout moment et sans préavis le contenu du Site ou des services qui y sont disponibles, et/ou de cesser de manière temporaire ou définitive d’exploiter tout ou partie du Site.<br/><br/>

          En outre, l’Exploitant se réserve le droit de modifier à tout moment et sans préavis la localisation du Site sur l’Internet, ainsi que les présentes Conditions Générales. Le Client est donc tenu par conséquent de se reporter aux présentes Conditions Générales avant toute utilisation du Site.<br/><br/>

          Le Client reconnait que l’Exploitant ne saurait être tenu responsable de quelque manière que ce soit envers lui ou tout tiers du fait de ces modifications, suspensions ou cessations.<br/><br/>

          L’Exploitant conseille au Client de sauvegarder et/ou imprimer les présentes Conditions Générales pour une conservation sûre et durable, et pouvoir ainsi les invoquer à tout moment pendant l'exécution du contrat si besoin.<br/><br/>

          <b>RECLAMATION - MEDIATION</b><br/><br/>

          En cas de litige, vous devez vous adresser en priorité au service client de l'entreprise aux coordonnées suivantes : synesthesia.fr@gmail.com.<br/><br/>

          En cas d'échec de la demande de réclamation auprès du service client ou en l'absence de réponse de ce service dans un délai de dix (10) jours, le Client peut soumettre le différend relatif au bon de commande ou aux présentes CGV l'opposant à l’Exploitant au médiateur suivant : Lyon Médiation, 140 rue Louis Becker 69100 VILLEURBANNE, 0481681121, Lyon-Mediation.fr.<br/><br/>

          Le médiateur tentera, en toute indépendance et impartialité, de rapprocher les parties en vue d'aboutir à une solution amiable.  Les parties restent libres d'accepter ou de refuser le recours à la médiation ainsi que, en cas de recours à la médiation, d'accepter ou de refuser la solution proposée par le médiateur.<br/><br/>

          <b>DROIT APPLICABLE</b><br/><br/>

          Ces Conditions Générales sont régies, interprétées et appliquées conformément au droit français.<br/><br/>

          <b>ACCEPTATION DES CONDITIONS GÉNÉRALES PAR LE CLIENT</b><br/><br/>

          Le Client reconnait avoir lu attentivement les présentes Conditions Générales.<br/><br/>

          En s’inscrivant sur le Site, le Client confirme avoir pris connaissance des Conditions Générales et les accepter, le rendant contractuellement lié par les termes des présentes Conditions Générales.<br/><br/>

          Les Conditions Générales applicables au Client sont celles disponibles à la date de la commande dont une copie datée à ce jour peut être remise à sa demande au Client, il est donc précisé que toute modification des Conditions Générales qui serait effectuée par l’Exploitant ne s’appliquera pas à toute commande intervenue antérieurement, sauf accord exprès du Client à l’origine d’une commande donnée.<br/><br/>
        </span>
      </div>
      <div className="orderActionsLayout" style={{ width: "auto", marginBottom: "15px", justifyContent: "flex-start" }}>
        <div className="orderActionButton buttonConfirm" onClick={onConfirmAction} role="button" style={{ margin: "0 5px" }}>Ok</div>
      </div>
    </Modal>
  );
})
