import React from 'react'
import { withRouter } from 'react-router'
import Modal from './Modal.component'
import { useEnterCode } from '../hooks/asyncHooks'
import { promiseFetch } from '../helperFunctions'
import c from '../Constants'

const url = process.env.REACT_APP_URL

/*==============================================================================
Main component
==============================================================================*/
export default withRouter(function AddCodeModal({ ...props }) {
  /* Message box data */
  const [msgBoxData, setMsgBoxData] = React.useState(null);

  /* Force focus on the input text */
  const keyInputRef = React.useRef(null);
  React.useEffect(() => {
    keyInputRef.current!==null && keyInputRef.current.focus();
  }, [keyInputRef]);
  /* Pid of input */
  const [key, updateKey] = useEnterCode(6);
  const handleKeyInput = (e) => updateKey(e.target.value);

  /* Check if the key is correct from the db and add to session */
  const handleCheckAndAddKey = () => {
    promiseFetch(`${url}/api/keys/checkAndAddToSession/${key}`)
    .then(res => {
      if (res.status===500) {
        setMsgBoxData({ text: "Erreur serveur", classes: ["colorError"] });
        return;
      }
      if (res.data.valid!==true) {
        setMsgBoxData({ text: "Code incorrect", classes: ["colorError"] });
      } else {
        setMsgBoxData({ text: "Code valide", classes: ["colorSuccess"], isLoading: true });
        window.setTimeout(() => {
          props.history.replace('/#signin');
        }, c.MSG_TIMEOUT_MID);
      }
    })
  };

  /* Trigger the server request when the key length reaches 6 */
  React.useEffect(() => {
    if (key.length===6) {
      handleCheckAndAddKey();
    } else {
      setMsgBoxData(null);
    }
  }, [key]);

  /* If user presses enter */
  const onKeySubmit = (e) => { e.preventDefault(); setMsgBoxData({ text: "Le code doit faire 6 caractères", classes: ["colorInfo"] }); };

  return (
    <Modal className="addCodeBox" msgBoxData={msgBoxData}>
      <h1 className="modalTitle">Ajouter un code</h1>
      <p className="addCodeDescription">Si vous avez acheté un bracelet en magasin, veuillez entrer le code ci-dessous pour accéder aux méditations associées aux thèmes du bracelet.</p>
      <form onSubmit={onKeySubmit}>
        <input className="addCodeInput" type="text" placeholder="Code" value={key} onChange={handleKeyInput} ref={keyInputRef}></input>
      </form>
      <p className="addCodeSubDescription">La création d’un compte est nécessaire afin d'accéder à vos méditations sans avoir à réitérer cette opération.</p>
    </Modal>
  );
})
