import { ModalContext } from '../Modal'
import React from 'react'
import { withRouter } from 'react-router-dom'
import MessageBox from '../components/MessageBox.component'
import { promiseFetch } from '../helperFunctions'
import LoadingSpinner from '../components/LoadingSpinner.component'
import { isChrome } from 'react-device-detect'
import c from '../Constants'

/* Constants */
const url = process.env.REACT_APP_URL;

/*------------------------------------------------------------------------------
Hook useFilterMeditations
------------------------------------------------------------------------------*/
function useFilterMeditations(meditationsData, currentIndex) {
  const [filteredMeditationsData, setFilteredMeditationsData] = React.useState(null);
  React.useEffect(() => {
    if (meditationsData) {
      /* "Tous" selected */
      if (currentIndex===0) {
        setFilteredMeditationsData(meditationsData);
      } else {
        const filterType = Object.keys(c.MEDITATIONS_TYPE_ATTR)[currentIndex-1];
        setFilteredMeditationsData(meditationsData.filter(meditation => meditation.type===filterType||meditation.isNew));
      }
    }
  }, [meditationsData, currentIndex]);
  return filteredMeditationsData;
}

/*------------------------------------------------------------------------------
Hook useFetchMeditations
------------------------------------------------------------------------------*/
function useFetchMeditations(setMsgBoxData) {
  const [meditations, setMeditations] = React.useState(null);
  const [shouldRefresh, setShouldRefresh] = React.useState(true);
  React.useEffect(() => {
    if (shouldRefresh) {
      setShouldRefresh(false);
      promiseFetch(`${url}/api/meditations`)
        .then(res => {
          if (res.status===200 && res.data) {
            /* Sort by numerical value */
            res.data.sort((a, b) => {
              if (isNaN(a.number) || isNaN(b.number)) {
                return (a.number > b.number ? 1 : -1);
              }
              return a.number - b.number;
            });
            /* Then sort by type */
            res.data.sort((a, b) => c.MEDITATIONS_TYPE_ATTR[a.type].sortOrder-c.MEDITATIONS_TYPE_ATTR[b.type].sortOrder);
            setMeditations(res.data);
          } else {
            setMsgBoxData({ text: "Impossible de récupérer les méditations", classes: ["colorError"] });
          }
        })
    }
  }, [shouldRefresh]);
  return [meditations, setMeditations, setShouldRefresh];
}

/*------------------------------------------------------------------------------
Hook useHandleSelectableQuantity
------------------------------------------------------------------------------*/
function useHandleSelectableValue(value, updateSelected) {
  const [selectedValue, setSelectedValue] = React.useState(value);
  const handleSelectedValue = (e) => {
    setSelectedValue(e.target.value);
    updateSelected && updateSelected(e.target.value);
  }
  React.useLayoutEffect(() => {
    if (value!==null) {
      setSelectedValue(value);
    }
  }, [value]);
  return [selectedValue, handleSelectedValue];
}

/*------------------------------------------------------------------------------
Hook useGetFilepaths
------------------------------------------------------------------------------*/
function useGetFilepaths(meditationData, setMsgBoxData) {
  /* Hooks */
  const [filepaths, setFilepaths] = React.useState(meditationData.filepaths.map(x => ({serverSide: x, upload: null})));
  React.useEffect(() => {
    if (meditationData) {
      setFilepaths(meditationData.filepaths.map(x => ({serverSide: x, upload: null})));
    }
  }, [meditationData]);

  /* Callbacks */
  const onAddFilepath = (e) => {
    e && e.preventDefault();
    /* Get the basename from a filepath */
    const getBaseName = function (str) {
      return str.split('\\').pop().split('/').pop();
    };
    if (e.target.files.length>0) {
      const serverSideUrl = c.SERVERSIDE_UPLOAD_DIR+getBaseName(e.target.files[0].name);
      setFilepaths(filepaths => {
        return [...filepaths, { serverSide: serverSideUrl, upload: e.target.files[0] }];
      });
    }
  };

  const onRemoveFilepath = (e, idx) => {
    e && e.preventDefault();
    setFilepaths(filepaths => {
      let res = [...filepaths]; // Clone the object so React knows there's been a change.
      res.splice(idx, 1);
      return res;
    });
  };

  /* Callback to upload the files to the server */
  const uploadCallback = () => {
    /* Upload only the new files (those that have a non-null value at key 'upload') */
    const files = filepaths.map(x => x.upload).filter(x => x);
    /* If we have no files to upload */
    if (files && files.length===0) {
      return new Promise((resolve) => resolve([]));
    }
    /* → Construct the data to send */
    const formData = new FormData();
    files.forEach((file, i) => {
      formData.append(i, file, file.name);
    });
    setMsgBoxData({ text: `Upload du fichier ${files.map(file => file.name).join(', ')}`, classes: ["colorInfo"], isLoading: true });
    /* → Send the data */
    return new Promise((resolve, reject) => {
      promiseFetch(`${url}/api/meditations/upload`, {
        method: "post",
        body: formData
      })
        .then(res => {
          if (res.status===200 && res.data) {
            window.setTimeout(() => {
              setMsgBoxData(null);
              resolve(res.data);
            }, c.MSG_TIMEOUT_FAST);
          } else {
            setMsgBoxData({ text: res.data, classes: ["colorError"] });
            reject(res.data);
          }
        })
    });
  };

  return { filepaths, onAddFilepath, onRemoveFilepath, uploadCallback };
}

/*------------------------------------------------------------------------------
Hook useGetMeditationNumbers
------------------------------------------------------------------------------*/
function useGetMeditationNumbers(meditationData, num=10) {
  const [selectedNumber, setSelectedNumber] = React.useState(meditationData.number);
  const selectableNumbers = Array.from(Array(num).keys());
  React.useEffect(() => {
    if (meditationData) {
      setSelectedNumber(meditationData.number);
    }
  }, [meditationData]);
  /* Callback */
  const updateSelectedNumber = (value) => {
    setSelectedNumber(value);
  };
  return [selectedNumber, selectableNumbers, updateSelectedNumber];
}

/*==============================================================================
Component SelectionBox
==============================================================================*/
function SelectionBox({ name, value, choices, updateSelected, width=undefined }) {
  const [selectedValue, handleSelectedValue] = useHandleSelectableValue(value, updateSelected);
  return (
    <div className={!isChrome ? "selectionBox" : "selectionBoxChrome"}>
      <p>{name}</p>
      <select name={name} onChange={handleSelectedValue} value={selectedValue} style={width ? { width: `${width}px`, margin: "0 0 0 -1px" } : {}}>
        {choices.map((x, i) => {
          return <option value={x} key={i} style={i===0?{ display: "none" }:{}}>{x}</option>;
        })}
      </select>
    </div>
  );
}

/*==============================================================================
Component FilepathsSelectionList
==============================================================================*/
function FilepathsSelectionList({ name, filepaths, index, onAddItem, onRemoveItem }) {
  return (
    <>
      <div className="selectionBox">{name}</div>
      {filepaths.map((value, idx) => {
        return <div style={{ display: "flex", flexDirection: "row" }} key={value.serverSide}>
          <p className="filepathItemText">{value.serverSide}</p>
          {onRemoveItem && <div className="selectionBoxButton" onClick={(e) => onRemoveItem(e, idx)} role="button">Retirer</div>}
        </div>
      })}
      {onAddItem && 
        <div className={!isChrome ? "selectionBox" : "selectionBoxChrome"} style={{ margin: '-5px 0 5px' }}>
          <label htmlFor={"fileInput"+index}>
            <p className="selectionBoxButton" style={{ margin: "4px 0 0 0" }}>+ Ajouter</p>
          </label>
          <input type="file" name="audio" id={"fileInput"+index} accept="audio/*" style={{ display: "none" }}
            onChange={onAddItem} />
        </div>
      }
    </>
  );
}

/*==============================================================================
Component TextInput
==============================================================================*/
function TextInput({ name, value, updateValue, dkey=null, style={} }) {
  /* Submit callback */
  const updateInputValue = (e) => {
    e.preventDefault();
    if (dkey!==null) {
      updateValue(dkey, e.target.value);
    } else {
      updateValue(e.target.value);
    }
  };
  return (
    <div className="adminProductTextInputLayout" style={style}>
      <p style={{ width: "64px" }}>{name}</p>
      <input className="adminProductTextInput" type="text" value={value} onChange={updateInputValue} />
    </div>
  );
}

/*------------------------------------------------------------------------------
Hook useGetSelectedType
------------------------------------------------------------------------------*/
function useGetSelectedType(productType) {
  const [selectedType, setSelectedType] = React.useState(productType);
  React.useEffect(() => {
    if (productType) {
      setSelectedType(productType);
    }
  }, [productType]);
  const updateSelectedType = (value) => {
    setSelectedType(value);
  };
  return [selectedType, updateSelectedType];
}

/*------------------------------------------------------------------------------
Hook useGetName
------------------------------------------------------------------------------*/
function useGetName(meditationData) {
  const [name, setName] = React.useState(meditationData.name);
  React.useEffect(() => {
    if (meditationData.name) {
      setName(meditationData.name);
    }
  }, [meditationData.name]);
  return [name, setName];
}

/*==============================================================================
Component MeditationActions
==============================================================================*/
const MeditationActions = withRouter(({ meditationData, meditationsData, setMeditationsData, doRefresh, doRefreshThis, doDeleteThis, selectedType, updateSelectedType, setMsgBoxData, ...props }) => {
  /* Hooks */
  const { filepaths, onAddFilepath, onRemoveFilepath, uploadCallback } = useGetFilepaths(meditationData, setMsgBoxData);
  const [selectedNumber, selectableNumbers, updateSelectedNumber] = useGetMeditationNumbers(meditationData, 100);
  const [name, setName] = useGetName(meditationData);

  React.useEffect(() => {
    setMsgBoxData(null);
  }, []);

  /* Callback pour faire la sauvegarde du groupe */
  const onSave = () => {
    /* → Verify that the number is not used for the type yet */
    const item = meditationsData.find(x => x.type===selectedType && x.number===selectedNumber);
    if ((selectedType!==meditationData.type || selectedNumber!==meditationData.number) && item) {
      setMsgBoxData({ text: `Le numéro "${selectedNumber}" est déjà utilisé pour le type "${selectedType}"`, classes: ["colorError"] });
    } else if (selectedType==="") {
      setMsgBoxData({ text: `Veuillez sélectionner un type pour les méditations`, classes: ["colorError"] });
    } else if (selectedNumber==="") {
      setMsgBoxData({ text: `Veuillez sélectionner un numéro pour les méditations`, classes: ["colorError"] });
    } else if (name==="") {
      setMsgBoxData({ text: `Veuillez entrer un nom pour les méditations`, classes: ["colorError"] });
    } else {
      if (uploadCallback) {
        /* → Upload the audio files to the server */
        uploadCallback()
          .then(newPaths => {
            setMsgBoxData({ text: "Sauvegarde de la méditation", classes: ["colorInfo"], isLoading: true });
            /* Construct the newFilePaths to write in the meditation */            
            const fp = [...filepaths.map(x => x.serverSide), ...newPaths];
            const newFilepaths = fp.filter((x, i) => {
              return fp.indexOf(x)===i;
            });
            /* → Request to modify meditation */
            promiseFetch(`${url}/api/meditations/modify`, {
              method: 'POST',
              headers: { "Content-Type": "application/json; charset=utf-8" },
              body: JSON.stringify({
                currentType: (meditationData.isNew ? null : meditationData.type),
                currentNumber: (meditationData.isNew ? null : meditationData.number),
                type: selectedType,
                number: selectedNumber,
                name: name,
                filepaths: newFilepaths,
                generateKey: (meditationData.isNew && c.MEDITATIONS_TYPE_ATTR[selectedType].generateKeyAtCreation)
              })
            })
              .then(res => {
                if (res.status===200 && res.data) {
                  window.setTimeout(() => {
                    /* → Update the local data */
                    setMeditationsData(meditationsData => {
                      const index = meditationsData.findIndex(x => x.type===meditationData.type && x.number===meditationData.number);
                      if (index!==-1) {
                        meditationsData[index] = res.data;
                        return [...meditationsData];
                      }
                      return meditationsData;
                    });
                    setMsgBoxData(null);
                  }, c.MSG_TIMEOUT_FAST);
                } else {
                  setMsgBoxData({ text: res.data, classes: ["colorError"], isLoading: false });
                }
              });
          })
      }
    }
  };

  return (
    <div className="orderPageSummary" style={{ width: "650px", padding: "15px", margin: "0 auto 20px", background: "inherit" }}>
      <div className="ordersEntry">
        <div className="adminProductActionsLayout">
          <SelectionBox name="Type" value={selectedType} choices={[""].concat(Object.keys(c.MEDITATIONS_TYPE_ATTR))} updateSelected={updateSelectedType} />
          <TextInput name="Nom" value={name} updateValue={setName} />
          <SelectionBox name="Numéro" value={selectedNumber} choices={selectableNumbers} updateSelected={updateSelectedNumber} width={60}/>
          {meditationData.generatedKey &&
            <div className={!isChrome ? "selectionBox" : "selectionBoxChrome"}>
              <p>Clé</p>
              <div className="adminMeditationsKey">{meditationData.generatedKey}</div>
            </div>
          }
          {filepaths &&
            <FilepathsSelectionList name="Fichiers" filepaths={filepaths} index={`${selectedType}-${selectedNumber}`} onAddItem={onAddFilepath} onRemoveItem={onRemoveFilepath} />
          }
        </div>
      </div>
      <div className="adminProductBottomLayout">
        <div className="adminProductButtonsLayout">
          <div className="orderActionButton buttonReject" style={{ margin: "0 auto 0 0" }} onClick={doDeleteThis} role="button">Supprimer</div>
          <div className="orderActionButton" onClick={doRefreshThis} role="button">Annuler</div>
          <div className="orderActionButton buttonConfirm" onClick={onSave} role="button">Sauvegarder les changements</div>
        </div>
      </div>
    </div>
  );
});

/*==============================================================================
Component MeditationEntryItem
==============================================================================*/
const MeditationEntryItem = withRouter(({ meditationData, meditationsData, setMeditationsData, zIndex, doRefresh, doDelete, ...props }) => {
  /* Hooks */
  const [msgBoxData, setMsgBoxData] = React.useState(null);
  const msgBoxAnchorRef = React.useRef(null);

  /* Resets msgBoxData at unmount */
  React.useEffect(() => {
    return () => { setMsgBoxData(null); };
  }, []);

  const itemExpandedRef = React.useRef(null);
  React.useEffect(() => {
    if (meditationData && itemExpandedRef.current) {
      itemExpandedRef.current.style.display = "none";
    }
  }, [meditationData, itemExpandedRef]);
  /* onClick callback function */
  const onClick = (e) => {
    e.preventDefault();
    if (itemExpandedRef.current) {
      itemExpandedRef.current.style.display = (itemExpandedRef.current.style.display==="none" ? "flex" : "none");
      setMsgBoxData(null);
    }
  };
  /* Delete component callback */
  const deleteComponent = () => {
    doDelete(meditationData);
  };
  /* Used to switch components */
  const [selectedType, updateSelectedType] = useGetSelectedType(meditationData.type);
  /* Used to refresh the component when we cancel */
  const [key, setKey] = React.useState(0);
  const refreshComponent = () => {
    setKey(key => key + 1);
    updateSelectedType(meditationData.type);
  };

  return (
    <>
      <div className="orderEntryItemBox" style={{ zIndex: zIndex }} >
        <div className="orderEntryItemHeader" onClickCapture={onClick} style={{ color: 'var(--color-text)' }}role="button">
          {meditationData.isNew ?
            <p className="productEntryItemCell" style={{ width: "75%" }}><b>Nouvelle méditation</b></p> :
            <p className="productEntryItemCell" style={{ width: "75%" }}><b>{`${meditationData.type}, ${meditationData.number}`}</b>{` / ${meditationData.name}`}</p>
          }
          <p className="productEntryItemCell" style={{ margin: "auto 16px auto auto" }}>{`${meditationData.filepaths.length} méditations`}</p>
        </div>
        <div className="orderEntryItemExpanded" ref={itemExpandedRef} style={{ display: "none" }}>
          <MeditationActions meditationData={meditationData} meditationsData={meditationsData} setMeditationsData={setMeditationsData}
            doRefresh={doRefresh} selectedType={selectedType} updateSelectedType={updateSelectedType} setMsgBoxData={setMsgBoxData}
            key={key} doRefreshThis={refreshComponent} doDeleteThis={deleteComponent} />
        </div>
        {msgBoxData!==undefined && <MessageBox msgBoxData={msgBoxData} componentRef={msgBoxAnchorRef} mode="absolute2" />}
      </div>
      <div className="orderEmptyMsgBoxAnchor" ref={msgBoxAnchorRef}></div>
    </>
  );
})

/*==============================================================================
Component MeditationEntriesLeftMenu
==============================================================================*/
function MeditationEntriesLeftMenu({ currentIndex, setCurrentIndex, ...props }) {
  const onClick = (e, index) => {
    setCurrentIndex(index);
  }
  return (
    <nav className="orderEntriesLeftMenu">
      <ul>
        {["Tous", ...Object.values(c.MEDITATIONS_TYPE_ATTR).map(x => x.category)].map((label, i) => {
          const style = (currentIndex===i ? { fontWeight: 700 } : {});
          return <li key={i} title={label} onClick={(e) => onClick(e, i)} style={style} role="button">
            {label}
          </li>;
        })}
      </ul>
    </nav>
  );
}

/*==============================================================================
Component MeditationEntriesRightButton
==============================================================================*/
function MeditationEntriesRightButton({ addMeditation, ...props }) {
  const onClick = (e) => {
    addMeditation();
  }
  return (
    <div className="groupEntriesRightButton" onClick={onClick} role="button">
      + Ajouter une méditation
    </div>
  );
}

/*==============================================================================
Main component
==============================================================================*/
export default withRouter(function AdminMeditationsPage({ setMsgBoxData, ...props }) {
  const { setModalProps } = React.useContext(ModalContext);
  const entriesLayoutRef = React.useRef(null);
  /* Hooks */
  const [currentIndex, setCurrentIndex] = React.useState(0);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentIndex]);
  const [meditationsData, setMeditationsData, doRefresh] = useFetchMeditations(setMsgBoxData);
  const filteredMeditationsData = useFilterMeditations(meditationsData, currentIndex);
  /* → Open a confirmation modal */
  const doDelete = (meditationData) => {
    /* Callback for confirmation modal */
    const nextCallback = (data, props, setMsgBoxData) => {
      setMsgBoxData({ text: "Suppression de la méditation", classes: ["colorInfo"], isLoading: true });
      /* → Request to server to delete the meditation */
      promiseFetch(`${url}/api/meditations/delete`, {
        method: 'DELETE',
        headers: { "Content-Type": "application/json; charset=utf-8" },
        body: JSON.stringify({
          type: data.meditationData.type,
          number: data.meditationData.number
        })
      })
        .then(res => {
          if (res.status===200 && res.data) {
            /* → Update local data */
            data.setMeditationsData(meditationsData => {
              return meditationsData.filter(x => !(x.type===data.meditationData.type && x.number===data.meditationData.number));
            });
            window.setTimeout(() => {
              setMsgBoxData(null);
              /* → Redirection */
              props.history.goBack();
            }, c.MSG_TIMEOUT_FAST);
          } else {
            setMsgBoxData({ text: "Une erreur inconnue est survenue", classes: ["colorError"] });
          }
        })
    };

    setModalProps({ meditationData, setMeditationsData, next: nextCallback,
      text: <><b>Voulez-vous vraiment supprimer cette méditation ?</b><br/><br/>
        Les clients ayant des produits utilisants ce thème n'auront plus accès aux méditations.</>
    });
    props.history.push('#deletemeditation');
  };

  const addMeditation = () => {
    setMeditationsData(meditationsData => {
      if (!meditationsData.find(meditation => meditation.isNew)) {
        meditationsData.push({ type: "", name: "", number: "1", filepaths: [], isNew: true });
      }
      return [...meditationsData];
    });
    /* Scroll to the added item */
    if (entriesLayoutRef.current && entriesLayoutRef.current.lastChild) {
      const childPos = entriesLayoutRef.current.lastChild.getBoundingClientRect().top;
      window.scrollTo({ top: childPos + window.pageYOffset - window.innerHeight / 2, behavior: 'smooth' });
    }
  };

  return (
    <>
      {filteredMeditationsData ?
        <>
          <MeditationEntriesLeftMenu currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} />
          <MeditationEntriesRightButton addMeditation={addMeditation} />
          <div className="orderEntriesLayout" ref={entriesLayoutRef}>
            {filteredMeditationsData.map((meditationData, i) => {
              const key = `${meditationData.type}${meditationData.name}${meditationData.number}`;
              return <MeditationEntryItem key={key} meditationData={meditationData} setMeditationsData={setMeditationsData}
              meditationsData={meditationsData} zIndex={filteredMeditationsData.length - i} doRefresh={doRefresh} doDelete={doDelete} />;
            })}
          </div>
        </> :
        <LoadingSpinner />
      }
    </>
  );
})
