import React from 'react'
import { LoadingSpinnerSmall } from './LoadingSpinner.component'
import { useWindowDimensions } from '../hooks/asyncHooks'
import c from '../Constants'

function useGetClassesAndText(lastData, msgBoxData) {
  const [classes, setClasses] = React.useState(null);
  const [text, setText] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(null);
  React.useEffect(() => {
    if (lastData) {
      setClasses(msgBoxData ? msgBoxData.classes : (lastData ? lastData.classes : []));
      setText(msgBoxData ? msgBoxData.text : (lastData ? lastData.text : ""));
      setIsLoading(msgBoxData ? msgBoxData.isLoading : (lastData ? lastData.isLoading : ""));
    }
  }, [lastData]);
  return [classes, text, isLoading];
}

export default function MessageBox({ msgBoxData, componentRef, mode="fixed", ...props }) {
  const { width, height } = useWindowDimensions();
  const windowWidth = width;
  /* Use a ref to get the width and bottom position of the modal window for the messageBox */
  const [msgBoxAttr, setMsgBoxAttr] = React.useState({ width: 0, top: 0 });
  const [hiddenClass, setHiddenClass] = React.useState("messageBoxHidden");
  const [lastData, setLastData] = React.useState(null);
  const [classes, text, isLoading] = useGetClassesAndText(lastData, msgBoxData);
  /* */
  React.useEffect(() => {
    if (msgBoxData!==null) {
      setHiddenClass("");
      setLastData(msgBoxData);
      /* Update the position of the message */
      if (componentRef.current!==null) {
        const boundingRect = componentRef.current.getBoundingClientRect();
        const width = Math.max(boundingRect.width, 300);
        /* Make at least show inside the screen */
        let top = `${boundingRect.bottom < height - c.MIN_POS_BOTTOM ? boundingRect.bottom : height - c.MIN_POS_BOTTOM}px`;
        /* Compensate for mobile */
        if (windowWidth<=c.MOBILE_BREAK_WIDTH) {
          top = `calc(${boundingRect.bottom + componentRef.current.parentNode.scrollTop}px)`;
        }
        setMsgBoxAttr({
          ...{
            width: `${width-16}px`, /* -16 for the padding */
            top: top,
            position: "fixed",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center"
          },
          ...(mode==="fixed" ? {
            left: `${boundingRect.left - (width-boundingRect.width)/2}px`,
            position: "fixed"
          } : mode==="absolute" ? {
            left: `${boundingRect.left + window.pageXOffset}px`,
            position: "absolute"
          } : {
            left: `${boundingRect.left - (width-boundingRect.width)/2}px`,
            top: boundingRect.bottom + window.pageYOffset,
            position: "absolute"
          })
        });
      }
    } else {
      setHiddenClass("messageBoxHidden");
    }
  }, [msgBoxData, componentRef.current]);

  return (
    <>
      {classes && text &&
        <div className={["messageBox", hiddenClass, ...classes].join(' ')} style={msgBoxAttr} {...props}>
          {text}
          {isLoading===true && <LoadingSpinnerSmall className="msgBoxLoadingSpinner" />}
        </div>
      }
    </>
  );
}
