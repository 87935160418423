import { ModalContext } from '../Modal'
import React from 'react'
import { withRouter } from 'react-router'
import LoadingSpinner from './LoadingSpinner.component'
import ProductCard from './ProductCard.component'
import { useWindowDimensions } from '../hooks/asyncHooks'
import { convertSearchParamsToObject, addParamsToSearchParams, promiseFetch } from '../helperFunctions'
import c from '../Constants'

const url = process.env.REACT_APP_URL;

/*
 - On garde une valeur en plus pour le ratio de l'image dans la DB (product.imageRatio)
 - On charge tous les items aavec des images vides en display: none, quand on scroll on charge les images et les rend visible
 - On calcule la position d'une catégorie de cette manière :
*/

/*------------------------------------------------------------------------------
Helper sumLinkedPidsPrices
------------------------------------------------------------------------------*/
function sumLinkedPidsPrices(linkedPids, productsDataRaw) {
  let price = 0;
  if (linkedPids && linkedPids.length>0) {
    linkedPids.forEach(pid => {
      const linkedProduct = productsDataRaw.find(data => data.id===pid);
      if (linkedProduct) {
        price += linkedProduct.price;
      }
    })
  }
  return price;
}

/*------------------------------------------------------------------------------
Helper isSameCategory
------------------------------------------------------------------------------*/
function isSameCategory(pid, type, category) {
  if (type==="Bracelet") {
    return pid.slice(1,3)===category;
  } else {
    /* If it's a Quantique we need to offset the category for VQ sub-category... */
    const icat = parseInt(category)-12;
    const cat = (pid.slice(1,3)==='VQ'?`${icat<10?'0':''}${icat}`:category);
    return pid.slice(4,6)===cat;
  }
};

/*------------------------------------------------------------------------------
Hook useGetCategoryPositions
------------------------------------------------------------------------------*/
function useGetCategoryPositions(productsData, meditationsData, offsets, arrColumnsLength, contentBottom) {
  const [categoryPositions, setCategoryPositions] = React.useState(null);
  const { width } = useWindowDimensions();

  React.useEffect(() => {
    if (productsData && productsData.length>0 && meditationsData && meditationsData.length>0 && offsets.length>0) {
      const type = productsData[0].type;
      /* Get the CSS variables for the text height */
      const lineHeight = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--product-card-text-height'));
      const textPadding = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--product-card-text-padding'));
      const marginTop = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--product-card-price-margin-top'));
      const textHeight = lineHeight + textPadding + marginTop;
      /* Get the CSS variables for the other sizes */
      const headerSize = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--header-size'));
      let imageWidth;
      if (width<=c.MOBILE_BREAK_WIDTH) {
        imageWidth = width/2 - 20*2;
      } else {
        imageWidth = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--product-card-w'));
      }
      const marginBottom = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--product-card-margin-bottom'));
      /* filter those of same type */
      const filteredMeditationsData = meditationsData.filter(meditationData => meditationData.type===type);
      const positions = {};

      filteredMeditationsData.forEach(meditation => {
        const number = parseInt(meditation.number);
        const category = number<10?'0'+meditation.number:meditation.number;
        let offsetsData = [];

        productsData.forEach((item, index) => {
          if (isSameCategory(item.id, type, category)) {
            offsetsData.push({y: offsets[index], index: index});
            if (index>=arrColumnsLength) {
              for (let i=index-arrColumnsLength; i>=0; i-=arrColumnsLength) {
                const imageHeight = Math.ceil(imageWidth / parseFloat(productsData[i].imageRatio));
                offsetsData[offsetsData.length-1].y += imageHeight+marginBottom+textHeight+offsets[i];
              }
            }
          }
        })
        let mintop = (offsetsData.length>0?Math.min(...(offsetsData.map(x => x.y)))+contentBottom-headerSize*2:0);
        positions[number-1] = mintop;
      })
      setCategoryPositions(positions);
    }
  }, [productsData, meditationsData, arrColumnsLength, contentBottom, offsets]);
  return categoryPositions;
}

/*------------------------------------------------------------------------------
Helper createItemsData
------------------------------------------------------------------------------*/
function createItemsData(productsData, productsDataRaw, onCardClicked) {
  return productsData.map((productData, j) => {
    /* Compute the sum of the products linked */
    let priceWithLinked = productData.price + sumLinkedPidsPrices(productData.linkedPids, productsDataRaw);
    return { productData: productData, price: priceWithLinked, key: j, dataKey: j, cardClicked: onCardClicked };
  });
}

/*------------------------------------------------------------------------------
Hook useGetOffsets
------------------------------------------------------------------------------*/
function useGetOffsets(productsData) {
  const [offsets, setOffsets] = React.useState(productsData.map(productData => {
    return Math.floor(Math.random()*40);
  }));
  return offsets;
}

/*------------------------------------------------------------------------------
Hook useGetOffsets
------------------------------------------------------------------------------*/
function useGetNavigationCategories(meditationsData, categorySelected) {
  const [navCategories, setNavCategories] = React.useState(null);
  React.useEffect(() => {
    if (meditationsData && meditationsData.length>0) {
      const meditationsDataCopy = [...meditationsData].filter(x => x.type===categorySelected);
      meditationsDataCopy.sort((a, b) => parseInt(a.number)-parseInt(b.number));
      setNavCategories(meditationsDataCopy.map(x => x.name));
    }
  }, [meditationsData, categorySelected]);
  return navCategories;
}

/*==============================================================================
Component InfiniteScroll
==============================================================================*/
function InfiniteScroll({ productsData, productsDataRaw, meditationsData, arrColumns, onCardClicked, hide, contentBottom,
  categorySelected, handleCategoriesPositions }) {
  const [itemsData] = React.useState(createItemsData(productsData, productsDataRaw, onCardClicked));
  const offsets = useGetOffsets(productsData);
  const categoryPositions = useGetCategoryPositions(productsData, meditationsData, offsets, arrColumns, contentBottom);
  const navigationCategories = useGetNavigationCategories(meditationsData, categorySelected);

  React.useEffect(() => {
    if (navigationCategories && navigationCategories.length>0 && categoryPositions) {
      handleCategoriesPositions(navigationCategories, categoryPositions);
    }
  }, [navigationCategories, categoryPositions]);

  return (
    <div style={ (hide ? { display: "none" } : {}) }>
      <div className="products">
        {[...Array(arrColumns).keys()].map((col) => {
          return <div className="productsColumn" key={col}>
            {itemsData.map((item,i) => {
              if (i%arrColumns===col) {
                return <ProductCard productData={item.productData} price={item.price} key={item.key}
                  dataKey={item.dataKey} offset={offsets[i]} cardClicked={item.cardClicked} />
              } else {
                return null;
              }
            })}
          </div>
        })}
      </div>
    </div>
  );
}

/*------------------------------------------------------------------------------
Hook to get the number of columns that should be displayed depending on the width
------------------------------------------------------------------------------*/
function useGetArrColumns(updateHpadding, setProductColumnsAmount) {
  const { width } = useWindowDimensions();
  const [arrColumns, setArrColumns] = React.useState(null);

  React.useLayoutEffect(() => {
    /* Get the css properties */
    let cardWidth = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--product-card-w'));
    const cardSpacing = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--product-card-spacing'));
    const minHpadding = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--content-padding-h'));

    if (width<=c.MOBILE_BREAK_WIDTH) {
      cardWidth = width/2 - 20*2;
    }
    /* Compute the number of columns to display */
    let columns = Math.floor((width-minHpadding*2+cardSpacing) / (cardWidth+cardSpacing));
    columns = Math.min(Math.max(columns, 2), 3); //Between 2 and 3 columns
    // setArrColumns(Array.from(new Array(columns), () => 0)); //<- put in state
    setArrColumns(columns); //<- put in state
    setProductColumnsAmount(columns);
    /* Callback to parent to update the horizontal padding */
    const newhpadding = Math.max((width-cardWidth*columns-cardSpacing*(columns-1))/2, minHpadding);
    updateHpadding(newhpadding);
  }, [width]);
  return arrColumns;
}

/*------------------------------------------------------------------------------
Hook
------------------------------------------------------------------------------*/
function useFetchMeditations() {
  const [meditationsData, setMeditationsData] = React.useState(null);
  React.useEffect(() => {
    /* → Fetching products from db */
    promiseFetch(`${url}/api/meditations/getCategoryData`)
      .then((res) => {
        if (res.status===200 && res.data) {
          setMeditationsData(res.data);
        }
      })
  }, []);
  return meditationsData;
}

/*------------------------------------------------------------------------------
Hook to get the products from the DB and filter in categories
------------------------------------------------------------------------------*/
function useFetchProductsDataCategorized(categories, setModalProps, props) {
  const [productsDataCategorized, setProductsDataCategorized] = React.useState(null);
  const [productsDataRaw, setProductsDataRaw] = React.useState(null);
  React.useEffect(() => {
    if (categories) {
      /* → Fetching products from db */
      promiseFetch(`${url}/api/products`)
        .then((res) => {
          if (res.status===200 && res.data) {
            /* → Filter out the products that are disabled */
            const products = res.data.filter(item => !item.disabled);
            setProductsDataRaw(products);
            /* → Filtering in different categories */
            const entries = categories.map(cat => {
              let productsCat = products.filter(item => item.type===cat);
              productsCat.sort((first, second) => first.id.slice(1) > second.id.slice(1) ? 1 : -1);
              return [cat, productsCat];
            });
            setProductsDataCategorized(Object.fromEntries(entries));

            /* After refresh if we're at location hash #product we pass the loaded data to the modal */
            if (props.location.hash==='#product') {
              const params = convertSearchParamsToObject(props.location.search);
              if (params.pid && params.pid!=="") {
                const productData = res.data.find(entry => entry.id==='#'+params.pid);
                if (productData) {
                  setModalProps({ productData: productData });
                }
              }
            }
          }
        })
    }
  }, [categories]);
  return [productsDataCategorized, productsDataRaw];
}

/*------------------------------------------------------------------------------
Hook to handle the independent scroll values between the categories
------------------------------------------------------------------------------*/
function useHandleCategoriesScrollPosition(categorySelected) {
  const [prevCategory, setPrevCategory] = React.useState(null);
  const [savedPosition, setSavedPosition] = React.useState({});
  const [scrollPosition, setScrollPosition] = React.useState(0);

  const handleScroll = () => {
    setScrollPosition(window.pageYOffset);
  };
  /* Event listener to get the correct scroll position */
  React.useLayoutEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      return window.removeEventListener("scroll", handleScroll);
    };
  }, [])

  React.useLayoutEffect(() => {
    setSavedPosition(savedPosition => {
      savedPosition[categorySelected] = scrollPosition;
      window.scrollTo(0, savedPosition[prevCategory] ? savedPosition[prevCategory] : 0);
      return savedPosition;
    });
    setPrevCategory(categorySelected);
  }, [categorySelected]);

  return savedPosition;
}

/*==============================================================================
Main component
==============================================================================*/
export default withRouter(function ProductCardColumns({ updateHpadding, categorySelected, categories, contentBottom,
  handleCategoriesPositions, setProductColumnsAmount, ...props }) {
  const { setModalProps } = React.useContext(ModalContext);
  const arrColumns = useGetArrColumns(updateHpadding, setProductColumnsAmount);
  const meditationsData = useFetchMeditations();
  const [productsDataCategorized, productsDataRaw] = useFetchProductsDataCategorized(categories, setModalProps, props);
  useHandleCategoriesScrollPosition(categorySelected);

  /* Handle the display of the product details */
  const onCardClicked = (key, productData) => {
    if (productData && productData.id) {
      const searchParams = addParamsToSearchParams(props.location.search, { pid: productData.id.slice(1) });
      props.history.push(searchParams+'#product');
      setModalProps({ productData: productData });
    }
  }

  return (
    <>
      {productsDataCategorized && arrColumns!==null ?
        <>
          {Object.entries(productsDataCategorized).map((entry, i) => {
            return (
              <InfiniteScroll productsData={entry[1]} productsDataRaw={productsDataRaw} meditationsData={meditationsData}
                arrColumns={arrColumns} onCardClicked={onCardClicked} hide={categorySelected!==entry[0]}
                contentBottom={contentBottom} categorySelected={categorySelected} handleCategoriesPositions={handleCategoriesPositions}
                key={i} />
            );
          })}
        </> :
        <LoadingSpinner />
      }
    </>
  );
})
