import { AuthContext } from '../Auth'
import { ModalContext } from '../Modal'
import React from 'react'
import { ProfileHeader } from '../components/Header.component'
import MeditationCardColumn from '../components/MeditationCardColumn.component'
import AudioPlayerBar from '../components/AudioPlayerBar.component'
import MessageBox from '../components/MessageBox.component'
import { FooterProfile } from '../components/Footer.component'
import { promiseFetch, stringToHtmlText } from '../helperFunctions'
import { useEnterCode, useFetchOnSubmitWithSet, useWindowDimensions } from '../hooks/asyncHooks'
import { ReactComponent as ArrowIcon } from "../resources/icons/arrow-down.svg"
import "./ProfilePage.css"
import { Redirect } from 'react-router'
import LoadingSpinner from '../components/LoadingSpinner.component'
import c from '../Constants'

const url = process.env.REACT_APP_URL;

/*==============================================================================
Hook useHandleKeys
==============================================================================*/
function useHandleKeys(setMsgBoxData, hasCheckedOrder) {
  /* Keys in the user entry */
  const [keysEntered, setKeysEntered, handleKeysEntered] = useFetchOnSubmitWithSet(`${url}/api/users/getKeysEntered`);
  /* Key of input */
  const [key, updateKey] = useEnterCode(6);
  const handleKeyInput = (e) => updateKey(e.target.value);

  /* Checking if there's a key to add in session */
  React.useEffect(() => {
    if (hasCheckedOrder) {
      promiseFetch(`${url}/api/keys/checkSessionForKey`)
        .then(res => {
          if (res.status===200 && res.data!==null) {
            updateKey(res.data.key);
          } else {
            handleKeysEntered();
          }
        });
    }
  }, [hasCheckedOrder]);

  /* Main logic */
  const handleAddKey = () => {
    /* Resolution function */
    const resolution = (res) => window.setTimeout(() => {
      updateKey("");
      setKeysEntered(res);
    }, c.MSG_TIMEOUT_MID);

    /* → Check if key is valid */
    promiseFetch(`${url}/api/keys/${key}`)
      .then(res => {
        if (res.status===200) {
          if (res.data.valid) {
            /* → Add key to user entry */
            promiseFetch(`${url}/api/users/addkey`, {
              method: 'POST',
              headers: { "Content-Type": "application/json; charset=utf-8" },
              body: JSON.stringify({ keyEntered: key })
            })
              .then(res => {
                if (res.status===500) {
                  setMsgBoxData({ text: "Erreur serveur", classes: ["colorError"] });
                  return;
                } else if (res.status===299) {
                  /* → Wait for 1sec  →  Clear the input box  →  Update the entries displayed in meditationsCardColumn  */
                  setMsgBoxData({ text: "Code déjà associé", classes: ["colorInfo"], isLoading: true });
                  resolution(res);
                } else if (res.status===200) {
                  /* → Wait for 1sec  →  Clear the input box  →  Update the entries displayed in meditationsCardColumn  */
                  setMsgBoxData({ text: "Code ajouté", classes: ["colorSuccess"], isLoading: true });
                  resolution(res);
                }
              });
          } else {
            setMsgBoxData({ text: "Code incorrect", classes: ["colorError"] });
          }
        }
      });
  };

  /* Key input handling */
  React.useEffect(() => {
    if (key.length===6) {
      handleAddKey();
    } else {
      setMsgBoxData(null);
    }
  }, [key]);

  /* Use a ref to get the position of the component under which to slide */
  const addCodeInputRef = React.useRef(null);
  /* If user presses enter */
  const onKeySubmit = (e) => { e.preventDefault(); setMsgBoxData({ text: "Le code doit faire 6 caractères", classes: ["colorInfo"] }); };
  /* Header custom code input */
  const AddCodeInput = (
    <form onSubmit={onKeySubmit}>
      <input className="addCodeTextInput" type="text" placeholder="Ajouter un code" value={key} onChange={handleKeyInput} ref={addCodeInputRef}/>
    </form>
  );

  return [keysEntered, AddCodeInput, addCodeInputRef];
}

/*==============================================================================
Hook useFetchOrderFromSession
==============================================================================*/
function useFetchOrderFromSession() {
  const [orderData, setOrderData] = React.useState(null);
  const [hasCheckedOrder, setHasCheckedOrder] = React.useState(false);
  React.useEffect(() => {
    promiseFetch(`${url}/api/orders/getFromSession`)
      .then(res => {
        if (res.status===200) {
          setOrderData(res.data);
          setHasCheckedOrder(true);
        }
      })
  }, []);
  return [orderData, hasCheckedOrder];
}

/*==============================================================================
Main component
==============================================================================*/
export default function ProfilePage(props) {
  /* Using ModalContext */
  const { cartAmount } = React.useContext(ModalContext);
  /* Using AuthContext */
  const { logout } = React.useContext(AuthContext);
  const { width } = useWindowDimensions();
  /* Message Box data */
  const [msgBoxData, setMsgBoxData] = React.useState(null);
  /* → First check if there's an order in process */
  const [orderData, hasCheckedOrder] = useFetchOrderFromSession();

  /* The meditations to pass to the AudioPlayer */
  const [tracks, setTracks] = React.useState({});
  const handleTracks = (newTracks) => {
    setTracks((tracks) => {
      return { ...tracks, [newTracks.pid]: newTracks.tracks };
    });
  };

  /* The selected track when we click in the list */
  const [selectedTrack, setSelectedTrack] = React.useState(null);
  const onMeditationClicked = (key, code) => {
    setSelectedTrack([key, code]);
  };

  /* Redirection to shop */
  const onRedirectionToShop = () => { props.history.push('/') };

  /* Hook for user keys handling (retrieval and logic) */
  const [keysEntered, AddCodeInput, addCodeInputRef] = useHandleKeys(setMsgBoxData, hasCheckedOrder);

  return (
    <>{hasCheckedOrder ? /* → If we checked the order from session */
      <>{orderData ? /* → If there's an order */
        <Redirect to={{ pathname: "/order", state: { orderData: orderData } }} /> :
        <>
          <ProfileHeader buttons={[
            { name: `Panier`, amount: cartAmount, func: () => props.history.push('#cart') },
            { name: "Commandes", func: () => props.history.push('#orders') },
            { name: "Compte", func: () => props.history.push('#modifyaccount') },
            { custom: AddCodeInput },
            { name: "Se déconnecter", func: logout }
          ]}>
            {width<=c.MOBILE_BREAK_WIDTH &&
              <div className="returnButton" onClick={onRedirectionToShop} role="button">
                <ArrowIcon className="returnButtonIcon" fill="#000" />
              </div>
            }
          </ProfileHeader>
          {(tracks && Object.keys(tracks).length===0) &&
            <div className="meditationsCardEmpty">
              {stringToHtmlText("Aucune méditation n'est disponible pour l'instant.\n\nVeuillez ajouter un code ou faire l'achat d'un bracelet\nsur le site ou en magasin pour y avoir accès.")}
            </div>
          }
          <MeditationCardColumn keysEntered={keysEntered} handleTracks={handleTracks}
            onMeditationClicked={onMeditationClicked} selectedTrack={selectedTrack} />
          <FooterProfile className="footerProfile"/>
          <div className="audioPlayerBox">
            {width>c.MOBILE_BREAK_WIDTH &&
              <div className="returnButton" onClick={onRedirectionToShop} role="button">
                <ArrowIcon className="returnButtonIcon" fill="#000" />
                Accueil / Boutique
              </div>
            }
            <AudioPlayerBar tracks={tracks} selectedTrack={selectedTrack} />
          </div>
          {msgBoxData!==undefined && <MessageBox msgBoxData={msgBoxData} componentRef={addCodeInputRef} />}
        </>
      }</> :
      <LoadingSpinner />
    }</>
  );
}
