import { ModalContext } from '../Modal'
import React from 'react'
import { withRouter } from 'react-router'
import Header from '../components/Header.component'
import ProductCardColumns from '../components/ProductCardColumns.component'
import ProductCategoryNavigation from '../components/ProductCategoryNavigation.component'
import Footer from '../components/Footer.component'
// import MessageBox from '../components/MessageBox.component'
import { useWindowDimensions } from '../hooks/asyncHooks'
import { ProductDescriptionBracelet, ProductDescriptionQuantiques, ProductDescriptionAbout, ProductDescriptionAboutMobile } from '../components/ProductdescriptionAbout.component'

import c from '../Constants'

import "./MainPage.css"

const CATEGORY_HEADER_ITEMS = [
  { title: "Notre Vision", text: "Notre Vision", type: "About", description: ProductDescriptionAbout },
  { title: "Bracelets Evolution", text: "Evolution", type: "Bracelet", description: ProductDescriptionBracelet },
  { title: "Bracelets Quantiques", text: "Quantiques", type: "Quantique", description: ProductDescriptionQuantiques }
];

/*==============================================================================
Component CategoryHeader
==============================================================================*/
function CategoryHeader({ categorySelected, setCategorySelected, categoriesPositions, productCategorySelected, setProductCategorySelected, productColumnsAmount }) {
  const { width } = useWindowDimensions();
  return (
    <>
      {width>c.MOBILE_BREAK_WIDTH ?
        <CategoryHeaderList setCategorySelected={setCategorySelected} categoriesPositions={categoriesPositions}
          productCategorySelected={productCategorySelected} setProductCategorySelected={setProductCategorySelected}
          productColumnsAmount={productColumnsAmount} /> :
        <CategoryHeaderListMobile categorySelected={categorySelected} setCategorySelected={setCategorySelected} categoriesPositions={categoriesPositions}
          productCategorySelected={productCategorySelected} setProductCategorySelected={setProductCategorySelected} />
      }
    </>
  );
}
/*==============================================================================
Component CategoryHeaderList
==============================================================================*/
function CategoryHeaderList({ setCategorySelected, categoriesPositions, productCategorySelected, setProductCategorySelected, productColumnsAmount }) {
  const { width } = useWindowDimensions();
  /* Handle the category selection */
  const [currentSelected, setCurrentSelected] = React.useState({ x: 0, w: 0 });
  /* Update the position of the line at mounting and width resize */
  const refs = React.useRef([]);
  React.useLayoutEffect(() => {
    if (refs.current[productCategorySelected.id]) {
      setCurrentSelected({
        x: refs.current[productCategorySelected.id].offsetLeft,
        w: refs.current[productCategorySelected.id].offsetWidth
      });
      setProductCategorySelected(selected => ({
        id: selected.id,
        x: refs.current[productCategorySelected.id].offsetLeft,
        w: refs.current[productCategorySelected.id].offsetWidth
      }));
    }
  }, [refs, width]);
  /* Callbacks */
  const handleClick = function (e, index) {
    setCurrentSelected({
      x: e.target.offsetLeft,
      w: e.target.offsetWidth
    });
    setProductCategorySelected(productCategorySelected => { return { ...productCategorySelected, id: index } });
    setCategorySelected(index);
  };
  const handleMouseEnter = function (e) {
    setProductCategorySelected(productCategorySelected => {
      return {
        id: productCategorySelected.id,
        x: e.target.offsetLeft,
        w: e.target.offsetWidth
      }
    });
  };
  const handleMouseLeave = function () {
    setProductCategorySelected(productCategorySelected => { return { ...currentSelected, id: productCategorySelected.id } });
  };

  return (
    <div className="categoryHeaderList2">
      <nav className="categoryHeaderList" onMouseLeave={handleMouseLeave}>
        {CATEGORY_HEADER_ITEMS.map((item, idx) => {
          const title = (productColumnsAmount===2 ? item.text : item.title);
          return <div className="categoryHeaderButton" key={idx} ref={(ref) => refs.current.push(ref)} onClick={(e) => handleClick(e, idx)}
            onMouseEnter={handleMouseEnter} style={idx===productCategorySelected.id ? { fontWeight: "600", letterSpacing: "-0.03em" } : {}} role="button">
              {title.replace(/ /g, "\u00a0")}
            </div>
        })}
        <hr style={{
          transform: `translateX(${productCategorySelected.x}px)`,
          width: `${productCategorySelected.w}px`
        }} />
      </nav>
      {productCategorySelected.id!==0 &&
        <ProductCategoryNavigation categoriesPositions={categoriesPositions} />
      }
    </div>
  );
}

/*==============================================================================
Component CategoryHeaderListMobile
==============================================================================*/
function CategoryHeaderListMobile({ categorySelected, setCategorySelected, categoriesPositions, productCategorySelected, setProductCategorySelected }) {
  const { width } = useWindowDimensions();
  /* Update the position of the line at mounting and width resize */
  const [hasInit, setHasInit] = React.useState(false);
  const refs = React.useRef([]);
  React.useLayoutEffect(() => {
    if (refs.current[productCategorySelected.id]) {
      setHasInit(true);
      setProductCategorySelected(selected => ({
        id: selected.id,
        x: refs.current[productCategorySelected.id].offsetLeft,
        w: refs.current[productCategorySelected.id].offsetWidth
      }));
    }
  }, [refs, width]);
  /* Callbacks */
  const handleClick = function (e, index) {
    setProductCategorySelected({
      id: index,
      x: refs.current[index].offsetLeft,
      w: refs.current[index].offsetWidth
    });
    setCategorySelected(index);
  };
  /* Bug fixing (recompute the position after id has been changed) */
  React.useLayoutEffect(() => {
    setProductCategorySelected(productCategorySelected => ({
      id: productCategorySelected.id,
      x: refs.current[productCategorySelected.id].offsetLeft,
      w: refs.current[productCategorySelected.id].offsetWidth
    }));
  }, [productCategorySelected.id]);

  return (
      <nav className="categoryHeaderList">
        {CATEGORY_HEADER_ITEMS.map((item, idx) => {
          return <div className="categoryHeaderButton" key={idx} ref={(ref) => refs.current.push(ref)} onClick={(e) => handleClick(e, idx)}
              style={idx===productCategorySelected.id ? { fontWeight: "600", letterSpacing: "-0.05em" } : {}} role="button">
              {item.text}
            </div>
        })}
        {productCategorySelected.id!==0 &&
          <ProductCategoryNavigation categoriesPositions={categoriesPositions} />
        }
        {hasInit &&
          <hr style={{
            transform: `translateX(${productCategorySelected.x}px)`,
            width: `${productCategorySelected.w}px`
          }} />
        }
      </nav>
  );
}

/*------------------------------------------------------------------------------
Hook to get the product category description
------------------------------------------------------------------------------*/
function useGetProductDescription(categorySelected) {
  const { width } = useWindowDimensions();
  const [ProductDescription, setProductDescription] = React.useState(CATEGORY_HEADER_ITEMS[categorySelected].description);
  React.useEffect(() => {
    setProductDescription(CATEGORY_HEADER_ITEMS[categorySelected].description);
  }, [categorySelected]);
  /*Sorry*/
  React.useEffect(() => {
    if (categorySelected===0) {
      if (width <= c.COLUMNS_BREAK_WIDTH_TMP) {
        setProductDescription(ProductDescriptionAboutMobile);
      } else {
        setProductDescription(CATEGORY_HEADER_ITEMS[categorySelected].description);
      }
    }
  }, [width, categorySelected]);
  return ProductDescription;
}

/*==============================================================================
Main component
==============================================================================*/
export default withRouter(function MainPage({ ...props }) {
  const { cartAmount } = React.useContext(ModalContext);
  /* Handling the list selection */
  const [categorySelected, setCategorySelected] = React.useState(0);
  const [productCategorySelected, setProductCategorySelected] = React.useState({ id: 0, x: 0, w: 0 });
  const [productColumnsAmount, setProductColumnsAmount] = React.useState(-1);
  /* Padding updated with callback */
  const [hpadding, setHpadding] = React.useState(0);
  /* The categories types */
  const [categories] = React.useState(CATEGORY_HEADER_ITEMS.map(item => item.type));
  /* Custom components for categories */
  const ProductDescription = useGetProductDescription(categorySelected);
  /* Reference to the images and categoryHeader for sticky header handling */
  const coverImageRef = React.useRef(null);
  // const msgBoxAnchorRef = React.useRef(null);
  // const [msgBoxData, setMsgBoxData] = React.useState(null);

  /* Switching styles for the cover image depending on the category */
  React.useLayoutEffect(() => {
    coverImageRef.current.className = `coverImage${categorySelected+1}`;
    coverImageRef.current.children[0].className = `coverTitle coverTitle${categorySelected+1}`;
    coverImageRef.current.children[0].innerHTML = CATEGORY_HEADER_ITEMS[categorySelected].text;
  }, [categorySelected])

  /* Add an event listener to call callback when scrolling */
  React.useEffect(() => {
    // setMsgBoxData({ text: "Fermeture du magasin en ligne exceptionnelle jusqu'au 5 septembre", classes: ["colorInfo"] });
    /* Getting the needed css properties */
    const coverImageSize = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--cover-image-size'));
    const headerHeight = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--header-size'));
    /* Scrolling handler */
    const handleScroll = (e) => {
      if (coverImageRef.current !== null) {
        const opacity = (window.pageYOffset > coverImageSize - headerHeight ? "0" : "1");
        coverImageRef.current.children[0].style.opacity = opacity;
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', () => handleScroll);
  }, []);

  /* Handling content bottom */
  const [contentBottom, setContentBottom] = React.useState(0);
  React.useLayoutEffect(() => {
    const bottom = document.getElementById("textContent")?.getBoundingClientRect().bottom;
    if (bottom!==undefined) {
      const bodytop = document.body.getBoundingClientRect().top;
      setContentBottom(bottom-bodytop);
    }
  }, [ProductDescription]);

  /* Handling the categories positions */
  const [categoriesPositions, setCategoriesPositions] = React.useState(null);
  const handleCategoriesPositions = (categories, positions) => {
    if (categories.length===Object.keys(positions).length) {
      const entries = categories.map((category, i) => [category, positions[i]]);
      setCategoriesPositions(Object.fromEntries(entries));
    }
  };

  /* For mobile when clicking on the home button */
  const handleSetFirstCategorySelected = () => {
    setCategorySelected(0);
    setProductCategorySelected({ id: 0, x: 0, w: 0 });
  }

  return (
    <>
      <Header buttons={[
        { name: `Panier`, amount: cartAmount, func: () => props.history.push('#cart') },
        { name: "Ajouter un code", func: () => props.history.push('#addcode') },
        { name: "Mon compte", func: () => props.history.push('#signin') }
      ]} handleSetFirstCategorySelected={handleSetFirstCategorySelected} />

      {/* <div className="tmpMsgBoxAnchor" ref={msgBoxAnchorRef}></div>
      {msgBoxData!==undefined && <MessageBox msgBoxData={msgBoxData} componentRef={msgBoxAnchorRef} mode="fixed" />} */}

      <div className="coverImage1" ref={coverImageRef}><h1/></div>
      <div className="categoryHeaderBox" />
      <div className="contentContainer" style={{ margin:`0px ${hpadding}px 0px ${hpadding}px` }}>
        <CategoryHeader categorySelected={categorySelected} setCategorySelected={setCategorySelected} categoriesPositions={categoriesPositions}
          productCategorySelected={productCategorySelected} setProductCategorySelected={setProductCategorySelected}
          productColumnsAmount={productColumnsAmount} />
        <div className="content" id="textContent">{ProductDescription}</div>
        <ProductCardColumns updateHpadding={setHpadding} categorySelected={CATEGORY_HEADER_ITEMS[categorySelected].type}
          categories={categories} contentBottom={contentBottom} handleCategoriesPositions={handleCategoriesPositions}
          setProductColumnsAmount={setProductColumnsAmount}/>
      </div>
      <Footer className="footer"/>
    </>
  );
})
