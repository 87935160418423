
const c = {
  /* The themes of color for the app */
  THEME_LIGHT: {
    "--color-text": "#201f1e",
    "--color-text-mid": "#524f4c",
    "--color-text-light": "#9d9692",
    "--color-text-lighter": "#d0cac4",
    "--color-text-white": "#e8e5dd",
    "--color-background": "#F4F3EE",
    "--color-white": "#FDFCFB",
    "--color-dark": "#222",
    "--color-link": "#2d53a5",
    "--color-link-alt": "#6290f3",
    "--color-success": "#16a765",
    "--color-error": "#f51d35",
    "--color-info": "#4c66ca",
    "--color-hover": "#d6f1ff",
    "--color-shadow-lighter": "rgba(0, 0, 0, 0.05)",
    "--color-shadow-light": "rgba(0, 0, 0, 0.16)",
    "--color-shadow-mid": "rgba(0, 0, 0, 0.25)",
    "--color-shadow-dark": "rgba(0, 0, 0, 0.44)",
    "--color-background-light": "rgba(0, 0, 0, 0.16)",
    "--color-background-dark": "rgba(0, 0, 0, 0.33)",
    "--color-background-darker": "rgba(0, 0, 0, 0.5)",
    "--image-about-logo-filter": "invert(1)"
  },
  THEME_DARK: {
    "--color-text": "#E6E6E6",
    "--color-text-mid": "#C0C0C0",
    "--color-text-light": "#B0B0B0",
    "--color-text-lighter": "#666",
    "--color-text-white": "#222021",
    "--color-background": "#282627",
    "--color-white": "#FFF",
    "--color-dark": "#222",
    "--color-link": "#6290f3",
    "--color-link-alt": "#2d53a5",
    "--color-success": "#16a765",
    "--color-error": "#f51d35",
    "--color-info": "#4c66ca",
    "--color-hover": "#4e4f52",
    "--color-shadow-lighter": "rgba(0, 0, 0, 0.25)",
    "--color-shadow-light": "rgba(0, 0, 0, 0.35)",
    "--color-shadow-mid": "rgba(0, 0, 0, 0.5)",
    "--color-shadow-dark": "rgba(0, 0, 0, 0.88)",
    "--color-background-light": "rgba(0, 0, 0, 0.16)",
    "--color-background-dark": "rgba(0, 0, 0, 0.54)",
    "--color-background-darker": "rgba(0, 0, 0, 0.7)",
    "--image-about-logo-filter": "none"
  },
  /* The screen width (in px) under which we consider the device to be a mobile */
  MOBILE_BREAK_WIDTH: 768,
  COLUMNS_BREAK_WIDTH_TMP: 1210,
  /* The timeout we show a message */
  MSG_TIMEOUT_FAST: 1000,
  MSG_TIMEOUT_MID: 1500,
  MSG_TIMEOUT_SLOW: 2000,
  /* The minimum bottom position (in px) of the message */
  MIN_POS_BOTTOM: 60,
  /* The attributes for the types of groupes */
  GROUPS_TYPE_ATTR: {
    "Bracelet": {
      category: "Bracelets",
      tag: "Bracelet",
      sortOrder: 1,
    },
    "Quantique": {
      category: "Quantiques",
      tag: "Quantique",
      sortOrder: 2,
    },
    "Autre": {
      category: "Autres",
      tag: "Autre",
      sortOrder: 3,
    }
  },
  SIZE_CHOICES: ['Taille XS', 'Taille S', 'Taille M', 'Taille L'],
  SIZE_DEFAULT_CHOICE: 'Taille M',
  /* The attributes for the types of meditations */
  MEDITATIONS_TYPE_ATTR: {
    "Bracelet": {
      category: "Bracelets",
      tag: "Bracelet",
      sortOrder: 1,
      generateKeyAtCreation: false
    },
    "Quantique": {
      category: "Quantiques",
      tag: "Quantique",
      sortOrder: 2,
      generateKeyAtCreation: false
    },
    "Personnalisé": {
      category: "Personnalisées",
      tag: "Personnalisé",
      title: "Méditations personnalisées",
      itemName: "Méditation",
      sortOrder: 3,
      generateKeyAtCreation: true
    },
    "Journalier": {
      category: "Journalières",
      tag: "Journalier",
      title: "Méditations journalières",
      itemName: "Jour",
      sortOrder: 4,
      generateKeyAtCreation: true
    }
  },
  /* The attributes for the types of products */
  PRODUCT_TYPE_ATTR: {
    "Bracelet": {
      category: "Bracelets",
      tag: "Bracelet",
      sortOrder: 1,
      isExtra: false,
      abbrv: ""
    },
    "Quantique": {
      category: "Quantiques",
      tag: "Quantique",
      sortOrder: 2,
      isExtra: false,
      abbrv: ""
    },
    "Huile essentielle": {
      category: "Huiles essentielles",
      tag: "Huile essentielle",
      sortOrder: 3,
      isExtra: true,
      abbrv: "HE"
    },
    "Composé floral": {
      category: "Composés de fleurs",
      tag: "Composé floral",
      sortOrder: 4,
      isExtra: true,
      abbrv: "CF"
    }
  },
  /* The empty key for products */
  EMPTY_KEY: "??",
  EMPTY_ID: "#??-??",
  /* The serverside local path directory for the meditations */
  SERVERSIDE_UPLOAD_DIR: "upload/meditations/",
  /* Days under which an order with state "En attente" is considered critical */
  REMAINING_DAYS_CRITICAL: 2,
  /* Days after which an order with state "En attente" is cancelled */
  DAYS_BEFORE_CANCELLING: 7,
  /* The different order status */
  ORDER_STATUS_ATTR: {
    "En attente": {
      color: "#4C66CA",
      category: "A confirmer",
      tag: "A confirmer"
    },
    "En préparation": {
      color: "#000",
      category: "A envoyer",
      tag: "A envoyer"
    },
    "Envoyé": {
      color: "#16A765",
      category: "Envoyés",
      tag: "Envoyé"
    },
    "Annulé": {
      color: "#F51D35",
      category: "Annulés",
      tag: "Annulé"
    },
    "Expiré": {
      color: "#F51D35",
      category: "Expirés",
      tag: "Expiré"
    },
    "En traitement": {
      color: "#B1B1B1",
      category: "Inachevés",
      tag: "Inachevé"
    },
  },
  /* The descriptions keys for the products */
  DESCRIPTION_KEYS: [
    { desc: "Intention de travail", tag: "Intention" },
    { desc: "Composition des pierres", tag: "Composition" },
    { desc: "Recommandations d'huiles essentielles", tag: "Recommandations HE" },
    { desc: "Recommandations du composé floral", tag: "Recommandations CF" }
  ],
  /* The subdirectories of the product images */
  IMAGES_SUBDIRS: ['main', 'model'],
  IMAGES_KEYCONV: {'main':'imageMain', 'model':'imageSecond'},
  /* The name of the packages containing custom méditations */
  CUSTOM_PACKAGE_NAMES: [
    "Bracelet soin Personnalisé",
    "Bracelet soin Accompagnement 31 jours"
  ],
  /* The extras that are selectable inn the product details page */
  SELECTABLE_EXTRAS: {
    'Huile essentielle': {
      index: 0,
      text: "l'huile essentielle",
      linkText: "Fiche produit du site Herbes & Traditions"
    },
    'Composé floral': {
      index: 1,
      text: "le composé floral",
      linkText: "Fiche produit du site Deva"
    }
  },
  /* The text for the meditations displayed in the summaryTable */
  MEDITATION_ENTRY_TEXT: {
    "#M1": "Méditations correspondantes",
    "#MC4": "4 Méditations personnalisées",
    "#MC31": "31 Méditations personnalisées",
  },
  /* The maximum selectable quantity of an item */
  MAX_SELECTABLE_QUANTITY: 10
};

export default c;