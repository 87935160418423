import React from 'react'
import { floatToPrice, getLowFromPriceRange } from '../helperFunctions'
import c from '../Constants'

/*==============================================================================
Component SummaryTableEntry
==============================================================================*/
function SummaryTableEntry({ price, size, isIncluded, children }) {
  return <p className={"productPackageSummaryItem"+(isIncluded?"Included":"")}>
    {children}
    {size && size!=="" && <span className="productPackageSummarySize">{size.replace(/ /g, "\u00a0")}</span>}
    <span>{isIncluded?"Inclus":`+${price}`}</span>
  </p>;
}

/*------------------------------------------------------------------------------
Hook useGetNumberInGroup
------------------------------------------------------------------------------*/
export function useGetNumberInGroup(productData) {
  const [numberInGroup, setNumberInGroup] = React.useState("");
  React.useEffect(() => {
    if (productData.type==="Bracelet") {
      const arr = productData.id.split('-');
      setNumberInGroup(arr.length===2 ? `, ${parseInt(arr[1])}` : "");
    }
  }, [productData]);
  return numberInGroup;
}

/*------------------------------------------------------------------------------
Hook useGetMeditationsEntries
------------------------------------------------------------------------------*/
function useGetMeditationsEntries(packageData) {
  const [meditationEntries, setMeditationEntries] = React.useState(null);
  React.useEffect(() => {
    if (packageData.extras) {
      /* The meditations extras */
      let packageMeditationExtra = packageData.extras.find(item => item.type==="Méditation");
      if (packageMeditationExtra!==undefined && packageMeditationExtra.pidList.length>0) {
        const entries = packageMeditationExtra.pidList.map((item, i) => {
          if (c.MEDITATION_ENTRY_TEXT.hasOwnProperty(item)) {
            return (<div key={i+16}>
              {item!=='#M1' &&
                <SummaryTableEntry isIncluded={true}>
                  {c.MEDITATION_ENTRY_TEXT['#M1']}
                </SummaryTableEntry>
              }
              <SummaryTableEntry isIncluded={true}>
                {c.MEDITATION_ENTRY_TEXT[item]}
              </SummaryTableEntry>
            </div>);
          }
          return null;
        });
        setMeditationEntries(entries);
      }
    }
  }, [packageData]);
  return meditationEntries;
}

/*------------------------------------------------------------------------------
Hook useGetLinkedExtras
------------------------------------------------------------------------------*/
function useGetLinkedExtras(extrasLinked) {
  const [linkedEntries, setLinkedEntries] = React.useState(null);
  React.useEffect(() => {
    if (extrasLinked) {
      setLinkedEntries(
        extrasLinked.map((item, i) => {
          if (item) {
            return <SummaryTableEntry price={floatToPrice(item.price)} isIncluded={true} key={i+32}>
              {`${item.type} / ${item.name}`}
            </SummaryTableEntry>
          }
          return null;
        })
      );
    } else {
      setLinkedEntries(null);
    }
  }, [extrasLinked]);
  return linkedEntries;
}

/*------------------------------------------------------------------------------
Hook useGetSelectedExtras
------------------------------------------------------------------------------*/
function useGetSelectedExtras(extrasSelected, packageData, setTotalPrice) {
  const [extrasEntries, setExtrasEntries] = React.useState(null);
  React.useEffect(() => {
    if (packageData) {
      const packagePriceLow = getLowFromPriceRange(packageData.priceRange);
      let prices = [0];
      let res = [];
      packageData.extras.forEach((packageExtra, i) => {
        /* → if the package has an extra included, isn't unique and with no pid defined, it's an extra that is chosen by us */
        if (packageExtra.isIncluded && !packageExtra.isUnique && packageExtra.pidList.length===0) {
          res.push(<SummaryTableEntry price={""} isIncluded={packageExtra.isIncluded} key={i+48}>
            {packageExtra.type}
          </SummaryTableEntry>);
        /* → else */
        } else {
          if (extrasSelected) {
            const extra = extrasSelected.filter(x => x).find(extraSelected => extraSelected.type===packageExtra.type);
            if (extra) {
              if (!packageExtra.isIncluded) {
                prices.push(extra.price);
              }
              res.push(<SummaryTableEntry price={floatToPrice(extra.price)} isIncluded={packageExtra.isIncluded} key={i+48}>
                {`${packageExtra.type} / ${extra.name}`}
              </SummaryTableEntry>);
            }
          }
        }
      });

      if (res.length>0) {
        setExtrasEntries(res);
        setTotalPrice && setTotalPrice(packagePriceLow + prices.reduce((acc, curr) => acc+curr))
      } else {
        setExtrasEntries(null);
        setTotalPrice && setTotalPrice(packagePriceLow);
      }
    }
  }, [extrasSelected, packageData]);
  return extrasEntries;
}

/*==============================================================================
Component SummaryTable
==============================================================================*/
export default function SummaryTable({ productData, packageData, extrasSelected, extrasLinked, size, setTotalPrice }) {
  /* States */
  const numberInGroup = useGetNumberInGroup(productData);
  const meditationEntries = useGetMeditationsEntries(packageData);
  const linkedEntries = useGetLinkedExtras(extrasLinked);
  const extrasEntries = useGetSelectedExtras(extrasSelected, packageData, setTotalPrice);

  return (
    <>
      <hr/>
      <SummaryTableEntry size={size} isIncluded={true} key={0}>
        {`${productData.type} / ${productData.name}${numberInGroup}`}
      </SummaryTableEntry>
      {meditationEntries}
      {linkedEntries}
      {extrasEntries}
      <hr/>
    </>
  );
}

/*==============================================================================
Component SummaryTablePrices
==============================================================================*/
export function SummaryTablePrices({ orderItem, selectedQuantity, handleSelectedQuantity, deleteButton }) {
  return (
    <>
      <div className="cartActionsRowLayout">
        {deleteButton!==undefined &&
          <>{deleteButton}</>
        }
        {handleSelectedQuantity!==undefined ?
          <>
            <div className="cartEntryLabel">
              Quantité
              <select name="quantity" onChange={handleSelectedQuantity} value={selectedQuantity}>
                {[...Array(c.MAX_SELECTABLE_QUANTITY).keys()].map(i => <option value={i+1} key={i+1}>{i+1}</option>)}
              </select>
            </div>
          </> :
          <p className="ordersEntryLabel">
            Quantité
            <span className="ordersEntryQuantity">{orderItem.quantity}</span>
          </p>
        }
        <p className="productPackageSummaryUnitPrice">
          Prix unitaire
          <span className="productPackageSummaryUnitPriceAmount">{floatToPrice(orderItem.subTotal)}</span>
        </p>
      </div>
      <p className="productPackageSummaryTotal">
        Sous-total
        <span className="productPackageSummaryTotalAmount">{
          floatToPrice(orderItem.subTotal * (handleSelectedQuantity ? selectedQuantity : orderItem.quantity))}</span>
      </p>
    </>
  );
}
