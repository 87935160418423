const images = require.context('./resources/images', true);

const url = process.env.REACT_APP_URL;

/* getLocalImageSrc:
*/
export function getLocalImageSrc(imageName) {
  try {
    return images(`./${imageName}`).default;
  } catch (error) {
    return null;
  }
}

export function getPublicImageSrc(imageName) {
  if (imageName && imageName!=="") {
    return `${url}/images/${imageName}`;
  }
  return null;
}

/* stringToHtmlText:
Converts a simple string to a HTML text to interpret the newlines */
export function stringToHtmlText(text) {
  return text.split('\n').map((item, i) => <span key={i}>{item}<br/></span>)
}

/* extrasStringToHtmlText:
Converts a simple string to a HTML text to interpret the newlines for the extras */
export function extrasStringToHtmlText(text, linkName) {
  return text.split('\n\n').map((item, i) => {
    if (item==="") return null;
    return (i===2?
      <a key={i} href={item} rel="noreferrer" target="_blank">{linkName}</a>:
      <span key={i}>{item}<br/><br/></span>)
  });
}

/* floatToPrice:
Converts a float to a price as a string */
export function floatToPrice(price) {
  let res = `${price}`.replace('.',',');
  const lio = res.lastIndexOf(',');
  res += (lio===-1?',00':'0'.repeat(Math.max(0,3+lio-res.length)));
  return res+'€';
}

/* formatDate:
Converts a date to a string, ex: 17 Février 2021 */
export function formatDate(date) {
  const months = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
  const values = date.slice(0,10).split('-').reverse();
  values[1] = months[parseInt(values[1]) - 1];
  return values.join(' ');
}

/* getMoneticoDate:
Converts a date to a string formatted for monetico requests */
export function getMoneticoDate(date = null) {
  return (date ? new Date(date) : new Date()).toLocaleString('en-GB', {timeZone: 'Europe/Paris'}).replace(', ', ':');
}

/* getMoneticoPhone:
Formats a phone number for monetico requests */
export function getMoneticoPhone(mobilePhone) {
  return `+33-${mobilePhone.length===10 ? mobilePhone.slice(1) : mobilePhone}`;
}

/* getLowFromPriceRange:
Returns the low price from the price range (ex, "29, 48" → "29") */
export function getLowFromPriceRange(priceRange) {
  return parseFloat(priceRange.split(',')[0]);
}

/* codeToProductId:
Converts a code to a pid (ex, #18YF07 → #18-07) */
export function codeToProductId(code) {
  if (code.length===7) {
    return `${code.slice(0,3)}-${code.slice(-2)}`
  }
  return -1;
}

/* Note:
  On ajoute credentials: 'include' dans les options des requêtes pour passer
  les credentials quand on fait des requêtes CORS depuis https://www.synesthesia.fr
  sinon req.isAuthenticated() échoue car l'api réside au domaine https://synesthesia.fr
 */
export async function promiseFetch(url, init = {}) {
  return fetch(url, { credentials: 'include', ...init })
    .then(res => {
      return Promise.all([res.json(), res.status]);
    })
    .then(([json, status]) => {
      return { data: json, status: status, completed: true };
    })
    .catch(e => {
      console.log(e);
      return { data: null, status: 500, completed: true };
    });
}

export function convertSearchParamsToObject(searchParams) {
  return Object.fromEntries(searchParams.slice(1).split('&').filter(x => x!=="").map(entry => entry.split('=')));
}

export function convertObjectToSearchParams(params) {
  return '?'+Object.entries(params).map(entry => entry.join('=')).join('&');
}

export function addParamsToSearchParams(searchParams, newParams) {
  const params = {...convertSearchParamsToObject(searchParams), ...newParams};
  return convertObjectToSearchParams(params);
}