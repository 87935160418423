import React from 'react'
import { getPublicImageSrc, floatToPrice } from '../helperFunctions'
import { useWindowDimensions } from '../hooks/asyncHooks'
import { isMobile } from 'react-device-detect'
import { useInView } from 'react-intersection-observer';
import c from '../Constants'
/* Fixes the IntersectionObserver missing reference issue on Safari */
async function loadPolyfills() {
  if (typeof window.IntersectionObserver === 'undefined') {
    await import('intersection-observer');
  }
}
loadPolyfills();

/*------------------------------------------------------------------------------
Helper getImageHeight
------------------------------------------------------------------------------*/
function getImageHeight(imageRatio, width=-1) {
  const imageWidth = (width!==-1?width/2 - 20*2:parseInt(getComputedStyle(document.documentElement).getPropertyValue('--product-card-w')));
  return Math.ceil(imageWidth/parseFloat(imageRatio));
};

/*------------------------------------------------------------------------------
Hook useGetImageHeight
------------------------------------------------------------------------------*/
function useGetImageHeight(imageRatio) {
  const [imageHeight, setImageHeight] = React.useState(getImageHeight(imageRatio));
  const { width } = useWindowDimensions();
  /* We update the imageWidth when the size decreases below MOBILE_BREAK_WIDTH */
  React.useLayoutEffect(() => {
    if (width<=c.MOBILE_BREAK_WIDTH) {
      setImageHeight(getImageHeight(imageRatio, width));
    }
  }, [width]);
  return imageHeight;
}

/*------------------------------------------------------------------------------
Hook useGetGroupNumber
------------------------------------------------------------------------------*/
function useGetGroupNumber(productData) {
  const [groupNumber, setGroupNumber] = React.useState("");
  React.useEffect(() => {
    if (productData) {
      /* If the groupId of the pid is not a number, the product does not belong to a group we should display (ex, #VQ-07 is false) */
      if (!isNaN(parseInt(productData.id.slice(1,3)))) {
        setGroupNumber(`, ${parseInt(productData.id.split('-')[1])}`);
      }
    }
  }, [productData]);
  return groupNumber;
}

/*==============================================================================
Main component
==============================================================================*/
export default function ProductCard({ productData, price, dataKey, offset, cardClicked }) {
  /* */
  const [hasLoaded, setHasLoaded] = React.useState(false);
  const { ref, inView } = useInView({
    rootMargin: "100px",
    threshold: 0,
  });
  React.useLayoutEffect(() => {inView && setHasLoaded(true)}, [inView]);
  /* image height */
  const imageHeight = useGetImageHeight(productData.imageRatio);
  /* The alt text for the images */
  const [imageAltText] = React.useState(`${productData.type} du thème ${productData.name}`);
  /* Get the group number of the product */
  const groupNumber = useGetGroupNumber(productData);
  /* Images will fade in and out when switching */
  const [imageOpacity, setImagesOpacity] = React.useState([1, 0]);
  const invertImagesOpacity = () => {
    const nv = (!isMobile ? 0 : 0.75);
    setImagesOpacity(val => val[0]===nv?[1,nv]:[nv,1]);
  };
  /* Callback */
  const onCardClicked = () => {
    cardClicked(dataKey, productData);
  }

  return (
    <div className="productCard" onClick={onCardClicked} style={{marginTop: `${offset}px`}} ref={ref}>
      <div className="productImageContainer"
        onMouseOverCapture={invertImagesOpacity}
        onMouseOutCapture={invertImagesOpacity}
      >
        {hasLoaded===false ?
          <div className="productEmptyImage" style={{ height: `${imageHeight}px`}}></div> :
          <>
            <img className="productImage" src={getPublicImageSrc(productData.imageMain)}
              style={{ opacity: imageOpacity[0], height: `${imageHeight}px` }} alt={imageAltText} />
            {!isMobile && <img className="productImageSub" src={getPublicImageSrc(productData.imageSecond)}
              style={{ opacity: imageOpacity[1], height: `${imageHeight}px`, marginTop: `calc(${imageHeight}px * -1)` }}
              alt={`${imageAltText} sur poignet`} />}
          </>
        }
      </div>
      <p className="productNameCentered">{productData.name+groupNumber}</p>
      <p className="productPriceCentered">{floatToPrice(price)}</p>
    </div>
  );
}
