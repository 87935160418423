import { AuthContext } from '../Auth'
import React from 'react'
import { withRouter } from 'react-router'
import Modal from './Modal.component'
import SummaryTable, { SummaryTablePrices } from './SummaryTable.component'
import LoadingSpinner from './LoadingSpinner.component'
import { useFetchOnSubmit, useFetchOnSubmitWithSet } from '../hooks/asyncHooks'
import { floatToPrice, getPublicImageSrc, promiseFetch, getLowFromPriceRange } from '../helperFunctions'
import c from '../Constants'

const url = process.env.REACT_APP_URL;

/*==============================================================================
Hook useHandleSelectableQuantity
==============================================================================*/
function useHandleSelectableQuantity(cartItem, updateSubTotalCallback, updateQuantityCallback) {
  const [selectedQuantity, setSelectedQuantity] = React.useState(cartItem.quantity);
  const handleSelectedQuantity = (e) => {
    updateQuantityCallback(e.target.value, cartItem.product.id);
    setSelectedQuantity(e.target.value);
  }
  React.useLayoutEffect(() => {
    if (cartItem) {
      let quantity = selectedQuantity;
      /* Fixes the bug of item quantity shifting when removing above item */
      if (cartItem.quantity!==selectedQuantity) {
        setSelectedQuantity(cartItem.quantity);
        quantity = cartItem.quantity;
      }
      updateSubTotalCallback(cartItem.subTotal * quantity, cartItem.product.id);
    }
  }, [selectedQuantity, cartItem]);
  return [selectedQuantity, handleSelectedQuantity];
}

/*==============================================================================
Component CartEntry
==============================================================================*/
function CartEntry({ cartItem, updateSubTotalCallback, updateQuantityCallback, deleteCallback, zIndex }) {
  /* Quantity */
  const [selectedQuantity, handleSelectedQuantity] = useHandleSelectableQuantity(cartItem, updateSubTotalCallback, updateQuantityCallback);
  const [packagePriceLow] = React.useState(getLowFromPriceRange(cartItem.package.priceRange));

  return (
    <>
      {cartItem &&
        <div className="modalListEntry cartListEntry" style={{ zIndex: zIndex }}>
          <img className="cartEntryImage" src={getPublicImageSrc(cartItem.product.imageMain)}
            alt={`${cartItem.product.type} du thème ${cartItem.product.name}`}/>
          <div className="cartEntryInfoLayout">
            <h2 className="cartEntryName">{cartItem.package.name}
              <span className="cartEntryPrice">{floatToPrice(packagePriceLow)}</span>
            </h2>
            <SummaryTable productData={cartItem.product} packageData={cartItem.package} size={cartItem.size?`Taille ${cartItem.size}`:null}
              extrasSelected={cartItem.extrasSelected} extrasLinked={cartItem.extrasLinked} />
            <SummaryTablePrices orderItem={cartItem} selectedQuantity={selectedQuantity}
              handleSelectedQuantity={c.CUSTOM_PACKAGE_NAMES.includes(cartItem.package.name) ? undefined : handleSelectedQuantity}
              deleteButton={<div className="cartEntryButton" onClick={() => deleteCallback(cartItem.product.id)} role="button">Retirer</div>}
            />
          </div>
        </div>
      }
    </>
  );
}

/*==============================================================================
Custom hooks
==============================================================================*/
/*useUpdateCartRequest:
  Creates a dynamic request triggered by the returned function
*/
function useUpdateCartRequest(url, setCartContent, handleResponseCallback = null) {
  /* Logic to send a POST method to the db to update the cart content */
  const [requestParams, setRequestParams] = React.useState(null);
  const [response, handleRequest] = useFetchOnSubmit(url, {
    method: 'POST',
    headers: { "Content-Type": "application/json; charset=utf-8" },
    body: requestParams===null ? "" : JSON.stringify(requestParams)
  });
  /* We send the request to db when the params are ready */
  React.useEffect(() => { (requestParams!==null) && handleRequest() }, [requestParams]);
  /* We update the cart content when the request is finished */
  React.useEffect(() => {
    if (response.data!==null) {
      setCartContent(cartContent => { return { ...cartContent, data: response.data } });
      handleResponseCallback && handleResponseCallback(response.data);
    }
  }, [response]);
  return [response, setRequestParams];
}

/*useHandleCart:
*/
function useHandleCart(updateAddedToCart) {
  const [cartContent, setCartContent, fetchCartContent] = useFetchOnSubmitWithSet(`${url}/api/products/getCart`);
  React.useEffect(() => {
    fetchCartContent();
  }, []);
  /* Update the cart amount when changing content */
  React.useEffect(() => {
    if (cartContent.data) {
      updateAddedToCart(cartContent.data);
    }
  }, [cartContent]);
  /* Request to change quantity of product in cart */
  const [, setQuantityRequestParams] = useUpdateCartRequest(`${url}/api/products/setCartProductQuantity`,
    setCartContent, updateAddedToCart);
  /* Request to delete product from cart */
  const [, setDeleteRequestParams] = useUpdateCartRequest(`${url}/api/products/deleteFromCart`,
    setCartContent, updateAddedToCart);
  return [cartContent, setQuantityRequestParams, setDeleteRequestParams];
}

/*useHandlePrices:
*/
function useHandlePrices() {
  const [totalPrice, setTotalPrice] = React.useState(0);
  const [prices, setPrices] = React.useState({});
  React.useLayoutEffect(() => {
    if (prices!==undefined && prices!==null && prices!=={}) {
      let total = 0;
      Object.keys(prices).forEach(key => total += prices[key]);
      setTotalPrice(total);
    }
  }, [prices]);
  return [totalPrice, setPrices];
}

/*==============================================================================
Main component
==============================================================================*/
export default withRouter(function CartModal({ updateAddedToCart, ...props }) {
  const { isInMaintenance, isShopInMaintenance, isLoading, isAdmin } = React.useContext(AuthContext);
  const [msgBoxData, setMsgBoxData] = React.useState(null);
  /* Fetching cart content from db */
  const [cartContent, setQuantityRequestParams, setDeleteRequestParams] = useHandleCart(updateAddedToCart);
  /* We store the prices in an object */
  const [totalPrice, setPrices] = useHandlePrices();

  /* Callbacks */
  const updateSubTotalCallback = (subTotal, pid) => {
    setPrices(prices => { return { ...prices, [pid]: subTotal } });
  };
  const updateQuantityCallback = (quantity, pid) => {
    setQuantityRequestParams({ quantity: quantity, pid: pid });
  };
  const deleteCallback = (pid) => {
    setDeleteRequestParams({ pid: pid });
    setPrices(prices => {
      delete prices[pid];
      return prices;
    });
  };
  const orderOnClick = () => {
    /* → On bloque l'achat si on est en maintenance */
    if (isAdmin===false && (isLoading===true || isInMaintenance===true || isShopInMaintenance===true)) {
      setMsgBoxData({ text: "Le magasin est actuellement en maintenance, veuillez réessayer plus tard", classes: ["colorError"], isLoading: true });
      window.setTimeout(() => {
        setMsgBoxData(null);
      }, c.MSG_TIMEOUT_MID);
      return ;
    }
    if (cartContent.data && cartContent.data.length>0) {
      /* → Save the order to the session and redirect to handle it */
      promiseFetch(`${url}/api/orders/saveToSession`, {
        method: 'POST',
        headers: { "Content-Type": "application/json; charset=utf-8" },
        body: JSON.stringify({
          items: cartContent.data,
          amount: totalPrice,
          deliveryFee: (totalPrice>150 ? 0 : 5)
        })
      })
        .then(res => {
          if (res.status===200 && res.data) {
            props.history.push('/#signin'); //TODO: ajouter un message de demande de création de compte
          } else {
            setMsgBoxData({ text: "Impossible d'ajouter la commande", classes: ["colorError"] });
          }
        })
    } else {
      setMsgBoxData({ text: "Veuillez d'abord ajouter un produit au panier", classes: ["colorError"] });
    }
  };

  return (
    <Modal className="cartBox" msgBoxData={msgBoxData}>
      {cartContent.data!==null ?
        <>
          <div className="modalListHeader" style={{ zIndex: cartContent.data.length+1 }}>
            <h1 className="modalTitle">Panier</h1>
          </div>
          {cartContent.data.length===0 ?
            <div className="cartEmptyDescription"><b>Le panier est vide.</b><p>Pour ajouter un article au panier veuillez cliquer sur un article pour afficher le détail puis cliquez sur le bouton “Ajouter au panier” pour l’ajouter.</p></div> :
            <>
              <div className="extraListContent">
                {cartContent.data.map((cartItem, i) => {
                  return <CartEntry cartItem={cartItem}
                    updateSubTotalCallback={updateSubTotalCallback}
                    updateQuantityCallback={updateQuantityCallback}
                    deleteCallback={deleteCallback}
                    zIndex={cartContent.data.length-i}
                    key={i}
                  />;
                })}
              </div>
              <div className="cartFooter">
                <div className="cartFooterDescription">
                  Livraison offerte dès 150€ d’achats. Paiements sécurisés Visa et Mastercards.
                </div>
                <div className="cartFooterInfos">
                  <div className="cartFooterLabel">Total TTC
                    <div className="cartFooterPrice">{floatToPrice(totalPrice)}</div>
                  </div>
                  <div className="cartFooterPriceInfo">(Hors frais de livraison)</div>
                </div>
              </div>
              <div className="cartButtonOrder" onClick={orderOnClick} role="button">Commander</div>
            </>
          }
        </> :
      <LoadingSpinner />}
    </Modal>
  );
})