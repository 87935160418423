import React from 'react'
import { ReactComponent as PauseIcon } from "../resources/icons/pause.svg"
import { ReactComponent as PlayIcon } from "../resources/icons/play.svg"
import { ReactComponent as VolumeMuteIcon } from "../resources/icons/volume-mute.svg"
import { ReactComponent as VolumeLowIcon } from "../resources/icons/volume-low.svg"
import { ReactComponent as VolumeHighIcon } from "../resources/icons/volume-high.svg"
import { LoadingSpinnerSmall } from '../components/LoadingSpinner.component'
import { isSafari, isIOS } from 'react-device-detect'
import { useWindowDimensions } from '../hooks/asyncHooks'
import c from '../Constants'

/*==============================================================================
Helper functions
==============================================================================*/
function formatSeconds(timeInSeconds) {
  const pad = function(num, size) { return ('000' + num).slice(size * -1); }
  const time = parseFloat(timeInSeconds).toFixed(3);
  const hours = Math.floor(time / 60 / 60);
  const minutes = Math.floor(time / 60) % 60;
  const seconds = Math.floor(time - minutes * 60);
  return `${(hours > 0 ? pad(hours, 2)+':' : '')}${pad(minutes, 2)}:${pad(seconds, 2)}`;
}

/*==============================================================================
Component ControlPlayPause
==============================================================================*/
function ControlPlayPause({ className, playing, onClick }) {
  return (
    <>{playing ?
      <PauseIcon className={className} fill="#000" width={13} height={13} onClick={onClick} role="button" /> :
      <PlayIcon className={className} fill="#000" width={13} height={13} onClick={onClick} role="button" />
    }</>
  );
}

/*==============================================================================
Component ControlNavigationBar
==============================================================================*/
function ControlNavigationBar({ startPosition, forcePosition, onPositionUpdate, onDragChanged, cursorClassName, ...props }) {
  /* Hooks */
  const [state, setState] = React.useState({
    position: 0,
    dragging: false,
    requestUpdate: false,
    hasInit: false
  });
  const updateState = (newState) => {
    setState(state => ({...state, ...newState}));
  }

  /* Refs */
  const navRef = React.createRef(null);
  /* The position of the cursor in the navigation bar */
  const [cursorPosition, setCursorPosition] = React.useState(0);

  /* Fonctions de conversions */
  const positionToPercentage = (pos) => {
    if (navRef.current!==null) {
      const navigation = navRef.current.getBoundingClientRect();
      const erw = navRef.current.clientLeft;
      return pos / (navigation.width - erw * 2);
    }
    return 0;
  }
  const percentageToPosition = (percentage) => {
    if (navRef.current!==null) {
      const navigation = navRef.current.getBoundingClientRect();
      const erw = navRef.current.clientLeft;
      return percentage * (navigation.width - erw * 2);
    }
    return 0;
  }

  React.useEffect(() => {
    const cnavref = navRef.current;
    if (cnavref!==null) {
      /* Récupération des boundingClientRects */
      const navigation = cnavref.getBoundingClientRect();
      /* Moving logic */
      const handleMouseEvent = (e) => {
        /* clientLeft is the borderSize of the element and must be subtracted from the result of getBoundingClientRect() */
        const erw = cnavref.clientLeft;
        const positionX = e.clientX || (e.targetTouches && e.targetTouches[0].pageX);
        if (positionX < navigation.left+erw) {
          updateState({ position: 0 });
        } else if (positionX > navigation.right-erw) {
          updateState({ position: navigation.width - erw*2 });
        } else {
          updateState({ position: positionX - navigation.left - erw });
        }
      }
      /* Drag start and clic (left mouse button) */
      const handleMousedown = (e) => {
        if (e.which===1 || !e.clientX) {
          updateState({ dragging: true, requestUpdate: true });
        }
      };
      /* Drag end (left mouse button) */
      const handleMouseup = (e) => {
        if (e.which===1 || !e.clientX) {
          updateState({ dragging: false });
        }
      };
      /* */
      window.addEventListener('mousemove', handleMouseEvent);
      cnavref.addEventListener('mousedown', handleMousedown);
      window.addEventListener('mouseup', handleMouseup);
      window.addEventListener('touchmove', handleMouseEvent);
      cnavref.addEventListener('touchstart', handleMousedown, { passive: true});
      window.addEventListener('touchend', handleMouseup);
      return () => {
        window.removeEventListener('mousemove', handleMouseEvent);
        cnavref.removeEventListener('mousedown', handleMousedown);
        window.removeEventListener('mouseup', handleMouseup);
        window.removeEventListener('touchmove', handleMouseEvent);
        cnavref.removeEventListener('touchstart', handleMousedown);
        window.removeEventListener('touchend', handleMouseup);
      }
    }
  }, [navRef]) // eslint-disable-line

  /* Drag logic */
  React.useLayoutEffect(() => {
    // if (state.dragging===false) {
      onDragChanged!==undefined && onDragChanged(state.dragging);
    // }
  }, [state.dragging]) // eslint-disable-line

  /* Rendering */
  React.useLayoutEffect(() => {
    if (state.dragging===true || state.requestUpdate===true) {
      setCursorPosition(state.position);
      updateState({ requestUpdate: false });
      onPositionUpdate(positionToPercentage(state.position));
    } else {
      setCursorPosition(percentageToPosition(forcePosition));
    }
    if (startPosition!==undefined && state.hasInit===false) {
      updateState({ position: percentageToPosition(startPosition), requestUpdate: true, hasInit: true });
    }
  }, [state.requestUpdate, state.position, forcePosition]) // eslint-disable-line

  return (
    <div ref={navRef} {...props}>
      <div className="audioPlayerBarFill" style={{ width: `${cursorPosition}px` }} disabled="disable"/>
      <div className={cursorClassName} style={{ transform: `translateX(${cursorPosition}px)` }} disabled="disable"/>
    </div>
  );
}

/*==============================================================================
Component ControlVolume
==============================================================================*/
function ControlVolume({ className, onVolumeUpdate, globalVolume }) {
  /* We  */
  const [savedVolume, setSavedVolume] = React.useState(-1);
  const [forcePosition, setForcePosition] = React.useState(-1);
  const onClickFromMute = () => {
    onVolumeUpdate(savedVolume!==-1 ? savedVolume : 50);
    setForcePosition(savedVolume!==-1 ? savedVolume : 50);
  }
  const onClickToMute = () => {
    setSavedVolume(globalVolume);
    onVolumeUpdate(0);
    setForcePosition(0);
  }

  React.useEffect(() => {
    setForcePosition(-1);
  }, [onVolumeUpdate]);

  return (
    <div className="audioPlayerVolumeLayout">
      <ControlNavigationBar
        className="audioPlayerVolumeBar"
        cursorClassName="audioPlayerVolumeBarCursor"
        startPosition={globalVolume}
        forcePosition={forcePosition===-1?undefined:forcePosition}
        onPositionUpdate={onVolumeUpdate}
      />
      {globalVolume===0 ?
        <VolumeMuteIcon className={className} onClick={onClickFromMute} fill="#000" role="button" /> :
        globalVolume < 0.5 ?
          <VolumeLowIcon className={className} onClick={onClickToMute} fill="#000" role="button" /> :
          <VolumeHighIcon className={className} onClick={onClickToMute} fill="#000" role="button" />
      }
    </div>
  );
}

/*==============================================================================
Component AudioPlayerItem
==============================================================================*/
function AudioPlayerItem({ track, globalVolume, setGlobalVolume }) {
  /* Hooks */
  const { width } = useWindowDimensions();
  const [state, setState] = React.useState({
    playing: (!track||isSafari||isIOS)?false:true,
    ctime: 0,
    duration: 0
  })
  const updateState = (newState) => {
    setState(state => ({...state, ...newState}))
  }

  /* Refs */
  const player = React.createRef();

  /* Callbacks */
  const onPlayPause = () => {
    if (state.playing) {
      player.current.pause();
      updateState({ playing: false });
    } else {
      player.current.currentTime = state.ctime;
      player.current.play();
      updateState({ playing: true });
    }
  }

  /* Handle dragging to change the time displayed when moving */
  const [isDragging, setIsDragging] = React.useState(false);
  const onDragChanged = (isDragging) => {
    if (isDragging===false) {
      setShouldUpdate(true);
    }
    setIsDragging(isDragging);
  };

  /* Setup du player and update when dragging changes */
  React.useEffect(() => {
    const cplayer = player.current;
    if (cplayer!==null) {
      if (!isSafari && !isIOS && !isDragging) {
        cplayer.play();
      }
      /* Ajout d'event listeners sur timeupdate, durationchange et ended */
      const handleTimeupdate = e => {
        if (!isDragging) {
          updateState({ ctime: e.target.currentTime });
        }
      }
      cplayer.addEventListener("timeupdate", handleTimeupdate);
      const handleDurationchange = e => updateState({ duration: e.target.duration });
      cplayer.addEventListener("durationchange", handleDurationchange);
      const handleEnded = e => updateState({ playing: false, ctime: 0 });
      cplayer.addEventListener("ended", handleEnded);
      return () => {
        cplayer.removeEventListener("timeupdate", handleTimeupdate);
        cplayer.removeEventListener("durationchange", handleDurationchange);
        cplayer.removeEventListener("ended", handleEnded);
      }
    }
  }, [isDragging]) // eslint-disable-line

  /* Gestion du volume */
  const [volume, setVolume] = React.useState(1);
  React.useEffect(() => {
    if (player.current!==null) {
      player.current.volume = volume;
    }
  }, [player, volume]);
  const onVolumeUpdate = (vol) => {
    const volume = vol > 1 ? 1 : vol;
    setVolume(volume);
    setGlobalVolume(volume);
  }

  /* Valeur retournée pour le drag de barre de navigation */
  const [value, setValue] = React.useState(null);
  const onTrackPositionUpdate = (p) => { setValue(p) };
  const [shouldUpdate, setShouldUpdate] = React.useState(false);
  React.useEffect(() => {
    if (value!==null && shouldUpdate===true && track) {
      const newctime = state.duration * value;
      player.current.currentTime = newctime;
      player.current.play();
      updateState({ playing: true, ctime: newctime });
    } else if (value!==null && isDragging===true) {
      const newctime = state.duration * value;
      updateState({ ctime: newctime });
    }
    setShouldUpdate(false);
  }, [value, shouldUpdate]) // eslint-disable-line

  return (
    <>
      <div className="audioPlayerLayout">
        <div className="audioPlayerName"
          unselectable="on"
          onMouseDown={() => { return false; }}
        >
          {track?track.name:'Sélectionnez une méditation'}
        </div>
        <div className="audioPlayerSubLayout">
          <ControlPlayPause
            className="audioPlayerPlayButton"
            playing={state.playing}
            onClick={track?onPlayPause:null}
          />
          <ControlNavigationBar
            className="audioPlayerBar"
            cursorClassName="audioPlayerBarCursor"
            forcePosition={state.ctime/state.duration}
            onPositionUpdate={onTrackPositionUpdate}
            onDragChanged={onDragChanged}
          />
          <div className="audioPlayerCurrentTime"
            unselectable="on"
            onMouseDown={() => { return false; }}
          >
            {track?formatSeconds(state.ctime):'0:00'}
          </div>
        </div>
        {width>c.MOBILE_BREAK_WIDTH &&
          <ControlVolume
            className="audioPlayerVolumeButton"
            onVolumeUpdate={onVolumeUpdate}
            globalVolume={globalVolume}
          />
        }
        <div className="audioPlayerDuration"
          unselectable="on"
          onMouseDown={() => { return false; }}
        >
          {track?<>
              {state.duration===0 ?
                <LoadingSpinnerSmall className="audioPlayerLoadingSpinner" />:
                formatSeconds(state.duration)
              }</> :
            '0:00'}
        </div>
      </div>
      {track && <audio ref={player} src={`/stream/${track.filepath}`} />}
    </>
  );
}

/*==============================================================================
Main component
==============================================================================*/
export default function AudioPlayerBar({ tracks, selectedTrack }) {
  /* Track selection logic */
  const [track, setTrack] = React.useState(null);
  React.useEffect(() => {
    if (selectedTrack!==null && tracks!==null && tracks[selectedTrack[1]]!==undefined) {
      setTrack({ _id: JSON.stringify(selectedTrack), ...tracks[selectedTrack[1]][selectedTrack[0]] });
    }
  }, [tracks, selectedTrack]);

  /* Global volume */
  const [globalVolume, setGlobalVolume] = React.useState(0.75);
  const handleGlobalVolume = (volume) => {
    setGlobalVolume(volume);
  };

  return (<>
    {track!==null?<AudioPlayerItem key={track._id} track={track} globalVolume={globalVolume} setGlobalVolume={handleGlobalVolume} />:<AudioPlayerItem globalVolume={globalVolume} setGlobalVolume={handleGlobalVolume} />}
  </>);
}
