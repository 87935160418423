import React from 'react'
import { withRouter } from 'react-router'
import Modal from './Modal.component'

export default withRouter(function WithdrawalFormModal({ ...props }) {
  const [msgBoxData, setMsgBoxData] = React.useState(null);

  /* Handle product deletion logic */
  const onConfirmAction = (e) => {
    e.preventDefault();
    const newtext = document.getElementById('withdrawalTextarea').value;
    /* Copying textarea content to the clipboard */
    navigator.clipboard.writeText(newtext).then(function() {
      setMsgBoxData({ text: "Texte copié dans le presse-papier", classes: ["colorSuccess"], isLoading: true });
      window.setTimeout(() => {
        setMsgBoxData(null);
      }, 1500);
    });
  };

  /* Textarea is a bit dumb with newlines so we must proceed like this */
  const [defaultText] = React.useState(`À l'attention de ESPACE MARIELLE GIRAULT, 2 rue de thou - 69001 Lyon - France, espacemg@gmail.com :\n\nJe/nous (*) vous notifie/notifions (*) par la présente ma/notre (*) rétractation du contrat portant sur la vente du bien ci-dessous :\n\nCommandé le (*)/reçu le (*) :\n\nNom du (des) consommateur(s) :\n\nAdresse du (des) consommateur(s) :\n\nSignature du (des) consommateur(s) (uniquement en cas de notification du présent formulaire sur papier) :\n\nDate :\n`);
  React.useEffect(() => {
    if (defaultText) {
      const textarea = document.getElementById('withdrawalTextarea');
      const text = defaultText.replace("\n", String.fromCharCode(13, 10));
      textarea.value = text;
    }
  }, [defaultText]);

  return (
    <Modal className="withdrawalFormModalBox" msgBoxData={msgBoxData}>
      <h1 className="modalTitle">Formulaire de rétractation</h1>
      <textarea className="withdrawalFormTextArea" rows="15" id="withdrawalTextarea" readOnly={true}></textarea>
      <p>Veuillez nous transmettre ce formulaire rempli par email à l'adresse <b>synesthesia.fr@gmail.com</b> avec pour objet <b>Formulaire de rétractation</b> ou bien rempli et signé par lettre à l'adresse postale indiquée sur la première ligne du formulaire.<br/><br/>
      Veuillez nous renseigner le/les noms du/des produits concernés ainsi que le/les numéros de commandes concernés.<br/><br/>
      Veuillez vous référer à nos <a href="https://www.synesthesia.fr/#cgv">conditions générales de vente</a> pour plus d'informations.
      </p>
      <div className="orderActionsLayout" style={{ width: "auto", marginBottom: "15px", justifyContent: "flex-start" }}>
        <div className="orderActionButton buttonConfirm" onClick={onConfirmAction} role="button" style={{ margin: "0 5px" }}>Copier dans le presse papier</div>
      </div>
    </Modal>
  );
})
