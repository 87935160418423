import { AuthContext } from '../Auth'
import React from 'react'
import { withRouter } from 'react-router' 
import Modal from './Modal.component'
import { useEnterText } from '../hooks/asyncHooks'
import { promiseFetch } from '../helperFunctions'
import c from '../Constants'

const url = process.env.REACT_APP_URL;

/*==============================================================================
Main component
==============================================================================*/
export default withRouter(function PasswordResetModal({ ...props }) {
  const { isAdmin } = React.useContext(AuthContext);
  /* Message box data */
  const [msgBoxData, setMsgBoxData] = React.useState(null);
  /*Force focus on the input text*/
  const newPasswordInputRef = React.useRef(null);
  React.useEffect(() => {
    newPasswordInputRef.current!==null && newPasswordInputRef.current.focus();
  }, [newPasswordInputRef]);

  /* Hooks to handle the form */
  const [newPassword, updateNewPassword] = useEnterText();
  const [confirmation, updateConfirmation] = useEnterText();
  const handleUpdateNewPassword = (text) => { setMsgBoxData(null); updateNewPassword(text); };
  const handleUpdateConfirmation = (text) => { setMsgBoxData(null); updateConfirmation(text); };

  const onSubmitModifyPassword = (e) => {
    e.preventDefault();
    /* → We verify that the form values are correct */
    if (newPassword.length===0) {
      setMsgBoxData({ text: "Veuillez entrer votre nouveau mot de passe", classes: ["colorError"] });
    } else if (newPassword.length < 7) {
      setMsgBoxData({ text: "Le mot de passe doit contenir au moins 7 caractères", classes: ["colorError"] });
    } else if (confirmation.length===0) {
      setMsgBoxData({ text: "Veuillez confirmer le nouveau mot de passe", classes: ["colorError"] });
    } else if (newPassword!==confirmation) {
      setMsgBoxData({ text: "Le mot de passe de confirmation est différent", classes: ["colorError"] });
    } else {
      /* → We change the password */
      promiseFetch(`${url}/api/users/changepwd`, {
        method: 'POST',
        headers: { "Content-Type": "application/json; charset=utf-8" },
        body: JSON.stringify({ password: newPassword })
      })
        .then(res => {
          if (res.status===200) {
            /* → We display a validation text */
            setMsgBoxData({ text: "Mot de passe modifié", classes: ["colorSuccess"], isLoading: true });
            /* → We delete the userReset from the db */
            promiseFetch(`${url}/api/userresets/remove`)
              .then(res => {
                /* → And redirect to profile after 1.5sec */
                window.setTimeout(() => {
                  if (isAdmin) {
                    props.history.replace('/admin');
                  } else {
                    props.history.replace('/profile');
                  }
                }, c.MSG_TIMEOUT_FAST);
              })
          } else {
            setMsgBoxData({ text: "Impossible de changer le mot de passe", classes: ["colorError"] });
          }
        })
    }
  }

  return (
    <Modal className="forgottenPasswordBox" msgBoxData={msgBoxData}>
      <h1 className="modalTitle">Nouveau mot de passe</h1>
      <p className="addCodeDescription">Veuillez entrer un nouveau mot de passe suite à la procédure de réinitialisation.</p>
      <form onSubmit={onSubmitModifyPassword}>
        <input className="modifyAccountInput" type="password" placeholder="Nouveau mot de passe" ref={newPasswordInputRef} autoComplete="new-password"
          value={newPassword} onChange={handleUpdateNewPassword} />
        <input className="modifyAccountInput" type="password" placeholder="Confirmation" autoComplete="new-password"
          value={confirmation} onChange={handleUpdateConfirmation} />
        <input className="buttonPrimaryAction" type="submit" value="Valider" />
      </form>
    </Modal>
  );
})
