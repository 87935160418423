import { AuthContext } from '../Auth'
import { ModalContext } from '../Modal'
import React from 'react'
import { withRouter } from 'react-router'
import Modal from './Modal.component'
import SummaryTable from './SummaryTable.component'
import { isChrome } from 'react-device-detect'
import { promiseFetch, getPublicImageSrc, floatToPrice, stringToHtmlText,
  convertSearchParamsToObject, convertObjectToSearchParams } from '../helperFunctions'
import { useWindowDimensions, useImagePreloader } from '../hooks/asyncHooks'
import { ReactComponent as ArrowRight } from "../resources/icons/arrow-right-outline.svg"
import { ReactComponent as Add } from "../resources/icons/add.svg"
import { ReactComponent as Remove } from "../resources/icons/remove.svg"
import LoadingSpinner from './LoadingSpinner.component'
import c from '../Constants'

/* Constantes */
const url = process.env.REACT_APP_URL;

/*------------------------------------------------------------------------------
Helper formatPriceRange :
  "30" → "30,00€"
  "30," → "Dès 30,00€"
  "30, 40" → "30,00€ ~ 40,00€"
--------------------------------------------------------------------------------*/
function formatPriceRange(priceRange) {
  const arr = priceRange.replace(' ','').split(',').map(item => (item!==''?floatToPrice(item):''));
  return (arr.length===1 ? arr[0] : (arr[1]===''?`Dès ${arr[0]}`:`${arr[0]} ~ ${arr[1]}`));
}

/*------------------------------------------------------------------------------
Helper filterExtrasDataWithPackageAndType
--------------------------------------------------------------------------------*/
function filterExtrasDataWithPackageAndType(packageData, extrasData, type) {
  const packageExtras = packageData.extras.find(extra => extra.type===type);
  if (packageExtras!==undefined) {
    /* Filters the object keys from extrasData to keep only those in the package */
    const entries = extrasData.filter(item => {
      /* We don't care about pidList anymore, we display all HE and CF that are not onlyLinked */
      return (item!==null && item.onlyLinked!==true && item.id.slice(1,3)===c.PRODUCT_TYPE_ATTR[type].abbrv);
    });
    return entries;
  }
  return null;
}

/*==============================================================================
Component ButtonAddExtra
==============================================================================*/
function ButtonAddExtra({ packageExtras, extrasSelected, type, onClick }) {
  /* State */
  const [state, setState] = React.useState(null);

  React.useEffect(() => {
    if (packageExtras) {
      /* Is the extra selected ? */
      const isSelected = (extrasSelected ? (extrasSelected.find(extra => extra && extra.type===type)!==undefined) : false);
      /* Does the package has extras */
      let hasExtras = false;
      const item = packageExtras.find(item => item.type===type);
      if (item && item.isIncluded===false) {
        hasExtras = true;
      }
      setState({ hasExtras: hasExtras, isIncluded: item.isIncluded, isUnique: item.isUnique, isSelected: isSelected });
    }
  }, [packageExtras, extrasSelected, type]);

  return (
    <>
      {state!==null && state.hasExtras && !state.isUnique && c.SELECTABLE_EXTRAS.hasOwnProperty(type) &&
        <div className="productPackageAddExtra" onClick={() => { onClick(state.isSelected, state.isIncluded) }} role="button">
          {state.isSelected ?
            <>
              {!state.isIncluded && <>
                <Remove className="productPackageAddIcon" />
                {`Retirer ${c.SELECTABLE_EXTRAS[type].text}`}
              </>}
            </> :
            <>
              {!state.isIncluded && <>
                <Add className="productPackageAddIcon" />
                {`Ajouter ${c.SELECTABLE_EXTRAS[type].text}`}
              </>}
            </>
          }
        </div>
      }
    </>
  );
}

/*==============================================================================
Component PackageInfos
==============================================================================*/
const PackageInfos = withRouter(({ packageData, packagesAmount, currentIndex,
  extrasData, extrasSelected, descriptions, onNavLeftClicked, onNavRightClicked, setMsgBoxData, ...props }) => {
  const { setSuperModalProps } = React.useContext(ModalContext);
  /* Opening the superModal to browse the extras */
  const openSuperModalWithType = (type) => {
    const res = packageData.extras.find(extra => extra.type===type);
    if (res) {
      props.history.push(props.location.search+"#product-extras");
      const extras = filterExtrasDataWithPackageAndType(packageData, extrasData, type);
      const isIncluded = res.isIncluded;
      setSuperModalProps({ extras: extras, isIncluded: isIncluded, type: type, index: c.SELECTABLE_EXTRAS[type].index });
    } else {
      setMsgBoxData({ text: "Une erreur est survenue", classes: ["colorError"] });
    }
  }

  const removeExtraWithType = (type) => {
    const params = convertSearchParamsToObject(props.location.search);
    /* Logic for list of selection in params.selected */
    if (params.selected===undefined || params.selected==="")
      params["selected"] = ",";
    let selected = params["selected"].split(',');
    selected[c.SELECTABLE_EXTRAS[type].index] = "";
    params["selected"] = selected.join(',');
    const searchParams = convertObjectToSearchParams(params);
    /* Push to same location with new params */
    props.history.replace(searchParams+props.location.hash);
  }

  const onFirstExtraClicked = (isSelected, isIncluded) => {
    setMsgBoxData(null);
    if (isSelected===true && isIncluded===false) {
      removeExtraWithType('Huile essentielle');
    } else {
      openSuperModalWithType('Huile essentielle');
    }
  }
  const onSecondExtraClicked = (isSelected, isIncluded) => {
    setMsgBoxData(null);
    if (isSelected===true && isIncluded===false) {
      removeExtraWithType('Composé floral');
    } else {
      openSuperModalWithType('Composé floral');
    }
  }

  return (
    <>
    {packageData &&
    <>
      {/* <h2 className="productPackageSection">
        Formules
        <nav className="productPackageNavigation">
          <ArrowLeft className="productPackageNavigationArrow" onClick={onNavLeftClicked} role="button"/>
          {`${currentIndex+1} / ${packagesAmount}`}
          <ArrowLeft className="productPackageNavigationArrow" style={{ transform: "scaleX(-1)" }} onClick={onNavRightClicked} role="button"/>
        </nav>
      </h2> */}
      <div className="productPackageBox">
        <h3 className="productPackageTitle">
          {packageData.name}
          <div className="productPackagePriceRange">{formatPriceRange(packageData.priceRange)}</div>
        </h3>
        <hr/>
        <p className="productPackageDescription">
          {stringToHtmlText(packageData.description)}<br/>
        </p>
        {descriptions?.["Recommandations d'huiles essentielles"] &&
          <p className="productPackageDescription">
            {"Huiles essentielles recommandées : "+descriptions["Recommandations d'huiles essentielles"]}
          </p>
        }
        {descriptions?.["Recommandations du composé floral"] &&
          <p className="productPackageDescription">
            {"Composé floral recommandé : "+descriptions["Recommandations du composé floral"]}
          </p>
        }
        <ButtonAddExtra packageExtras={packageData.extras} extrasSelected={extrasSelected} type='Huile essentielle' onClick={onFirstExtraClicked} />
        <ButtonAddExtra packageExtras={packageData.extras} extrasSelected={extrasSelected} type='Composé floral' onClick={onSecondExtraClicked} />
      </div>
      </>
    }
    </>
  );
})

/*------------------------------------------------------------------------------
Hook useFetchPackagesAndExtrasData
--------------------------------------------------------------------------------*/
function useFetchPackagesAndExtrasData(data, setMsgBoxData) {
  const [packagesData, setPackagesData] = React.useState(null);
  const [extrasData, setExtrasData] = React.useState(null);
  const [isLoaded, setIsLoaded] = React.useState(null);

  /* → Fetching the packages data */
  React.useEffect(() => {
    if (data && data.productData) {
      promiseFetch(`${url}/api/packages`)
        .then(res => {
          if (res.status===200 && res.data!==null && res.data.length!==0) {
            /* Filter only the packages of same type as product and sort by id*/
            let packagesData = res.data.filter(item => item.type===data.productData.type);
            packagesData.sort((a, b) => a.id - b.id);
            setPackagesData(packagesData);

            /* Get the extras types from the packages */
            let types = packagesData.map(packageData => {
              return packageData.extras.map(extra => extra.type in c.SELECTABLE_EXTRAS ? extra.type : undefined);
            }).flat().filter(x => x!==undefined);
            types = [...new Set(types)];

            /* → Fetching the extras product data */
            promiseFetch(`${url}/api/products/getWithTypes/${encodeURIComponent(types)}`)
              .then(res => {
                if (res.status===200 && res.data!==null) {
                  /* → Filter out the products that are disabled */
                  const extras = res.data.filter(item => !item.disabled);
                  /* → Sort the results */
                  extras.sort((first, second) => (''+first.id).localeCompare(second.id))
                  setExtrasData(extras);
                  setIsLoaded(true);
                }
              });
          } else {
            setMsgBoxData({ text: "Erreur lors du chargement des infos produit", classes: ["colorError"] });
          }
        });
    }
  }, [data]);
  return [packagesData, extrasData, isLoaded];
}

/*------------------------------------------------------------------------------
Hook useFetchExtrasLinked
--------------------------------------------------------------------------------*/
function useFetchExtrasLinked(data) {
  const [extrasLinked, setExtrasLinked] = React.useState(null);
  React.useEffect(() => {
    if (data && data.productData && data.productData.linkedPids && data.productData.linkedPids.length>0) {
      /* → Fetching the extra product data */
      promiseFetch(`${url}/api/products/getWithPids/${encodeURIComponent(data.productData.linkedPids)}`)
        .then(res => {
          if (res.status===200 && res.data!==null) {
            /* → Filter out the products that are disabled */
            const products = res.data.filter(item => !item.disabled);
            setExtrasLinked(products);
          }
        });
    }
  }, [data]);
  return extrasLinked;
}

/*------------------------------------------------------------------------------
Hook useGetExtrasSelected
--------------------------------------------------------------------------------*/
function useGetExtrasSelected(extrasData, selectedExtras) {
  const [extrasSelected, setExtrasSelected] = React.useState(null);
  React.useEffect(() => {
    if (extrasData && selectedExtras) {
      const extrasSelected = selectedExtras.map(selected => extrasData.find(item => item.id===selected));
      setExtrasSelected(extrasSelected);
    }
  }, [extrasData, selectedExtras]);
  return extrasSelected;
}

/*------------------------------------------------------------------------------
Helper function to get the position from the url search params
--------------------------------------------------------------------------------*/
function getIndexFromSearchParams(props) {
  if (props.location.search) {
    const params = convertSearchParamsToObject(props.location.search);
    if (params.package && params.package!=="") {
      return parseInt(params.package)-1;
    }
  }
  return 0;
}

/*------------------------------------------------------------------------------
Hook to handle the navigation in the packages
--------------------------------------------------------------------------------*/
function useHandleNavigation(props, packagesData) {
  /* At construction we check if we have a package param and set the index accordingly */
  const [currentIndex, setCurrentIndex] = React.useState(getIndexFromSearchParams(props));
  const [packagesNumber, setPackagesNumber] = React.useState(1);

  /* Saving the current navigation index in the url search params */
  React.useEffect(() => {
    const params = convertSearchParamsToObject(props.location.search);
    if (parseInt(params.package)!==currentIndex+1) {
      params["package"] = currentIndex+1;
      props.history.replace(convertObjectToSearchParams(params)+props.location.hash);
    }
  }, [currentIndex]);
  /* Navigation callbacks */
  const onNavLeftClicked = () => {
    setCurrentIndex(index => (index-1 >= 0 ? index-1 : packagesNumber-1));
  }
  const onNavRightClicked = () => {
    setCurrentIndex(index => (index+1 < packagesNumber ? index+1 : 0));
  }
  /* Getting the number of packages */
  React.useEffect(() => {
    packagesData && setPackagesNumber(packagesData.length);
  }, [packagesData]);

  return [currentIndex, onNavLeftClicked, onNavRightClicked];
}

/*==============================================================================
Component ProductDetailsImage
==============================================================================*/
function ProductDetailsImage({ productData, ...props }) {
  const [isImageReady, imageSrc] = useImagePreloader(getPublicImageSrc(productData.imageMain));
  const [isImageSubReady, imageSubSrc] = useImagePreloader(getPublicImageSrc(productData.imageSecond));
  /* Refs */
  const imgRef = React.useRef(null);
  const imgSubRef = React.useRef(null);
  /* Alt text for the images */
  const [imageAltText] = React.useState(`${productData.type} du thème ${productData.name}`);
  /* Images will fade in and out when switching */
  const [imageOpacity, setImagesOpacity] = React.useState([1, 0]);
  const invertImagesOpacity = () => {
    const nv = (imageSubSrc ? 0 : 0.75);
    setImagesOpacity(val => val[0]===nv?[1,nv]:[nv,1]);
  };
  /* Used to be sure the image has a correct getBoundingClientRect().height */
  const [loaded, setLoaded] = React.useState(false);
  const handleSetLoaded = (e) => { setLoaded(true) };
  React.useEffect(() => {
    if (loaded && imgSubRef.current) {
      const imgHeight = imgRef.current.getBoundingClientRect().height;
      imgSubRef.current.style.height = `${imgHeight}px`;
      imgSubRef.current.style.marginTop = `calc(${imgHeight}px * -1)`;
    }
  }, [loaded, imgSubRef.current]);

  return (
    <div className="productDetailsImageContainer" onClick={invertImagesOpacity}>
      <img className="productDetailsImage" src={imageSrc} ref={imgRef} onLoad={handleSetLoaded} style={{ opacity: imageOpacity[0] }} alt={imageAltText} />
      {imageSubSrc &&
        <img className="productDetailsImageSub" src={imageSubSrc} ref={imgSubRef} style={{ opacity: imageOpacity[1] }} alt={`${imageAltText} sur poignet`} />
      }
      <ArrowRight className="productDetailsImageArrow" role="button"/>
    </div>
  );
}

/*------------------------------------------------------------------------------
Hook useProductDescription
------------------------------------------------------------------------------*/
function useProductDescription(data) {
  const [description, setDescription] = React.useState(null);
  React.useEffect(() => {
    if (data.productData?.description) {
      setDescription(Object.fromEntries(data.productData.description.split('\n').filter(x => x).map((x, i) => {
        const arr = x.split(':');
        if (arr.length > 1) {
          return [arr[0].trim(), arr[1].trim()];
        } else {
          return [`#${i}`, x];
        }
      })));
    }
  }, [data.productData]);
  return description;
}


/*------------------------------------------------------------------------------
Hook useGetSelectedSize
------------------------------------------------------------------------------*/
function useGetSelectedSize(size) {
  const [selectedSize, setSelectedSize] = React.useState(size);
  React.useEffect(() => {
    if (size) setSelectedSize(size);
  }, [size]);
  const updateSelectedSize = (value) => {
    setSelectedSize(value);
  };
  return [selectedSize, updateSelectedSize];
}

/*------------------------------------------------------------------------------
Hook useHandleSelectableQuantity
------------------------------------------------------------------------------*/
function useHandleSelectableValue(value, updateSelected) {
  const [selectedValue, setSelectedValue] = React.useState(value);
  const handleSelectedValue = (e) => {
    setSelectedValue(e.target.value);
    updateSelected && updateSelected(e.target.value);
  }
  React.useLayoutEffect(() => {
    if (value!==null) {
      setSelectedValue(value);
    }
  }, [value]);
  return [selectedValue, handleSelectedValue];
}

/*------------------------------------------------------------------------------
Hook useSizeGuideModal
------------------------------------------------------------------------------*/
function useSizeGuideModal(props) {
  const { setSuperModalProps } = React.useContext(ModalContext);
  /* Opening the superModal to browse the extras */
  const sizeGuideOnClick = (e) => {
    e.preventDefault();
    props.history.push(props.location.search+"#size-guide");
    setSuperModalProps({ });
  }
  return sizeGuideOnClick;
}

/*==============================================================================
Component SelectionBox
==============================================================================*/
function SelectionBox({ name="", value, choices, updateSelected, width=undefined }) {
  const [selectedValue, handleSelectedValue] = useHandleSelectableValue(value, updateSelected);
  return (
    <div className={!isChrome ? "productSizeSelectionBox" : "productSizeSelectionBoxChrome"}>
      {name!=="" && <p>{name}</p>}
      <select name={name} onChange={handleSelectedValue} value={selectedValue} style={width ? { width: `${width}px`, margin: "0 0 0 -1px" } : {}}>
        {choices.map((x, i) => {
          return <option value={x} key={i} style={i===0?{ display: "none" }:{}}>{x}</option>;
        })}
      </select>
    </div>
  );
}

/*==============================================================================
Main component
==============================================================================*/
export default withRouter(function ProductDetailsModal({ data, pid, selectedExtras, updateAddedToCart, ...props }) {
  const { isInMaintenance, isShopInMaintenance, isAdmin, isLoading } = React.useContext(AuthContext);
  /* Message box data */
  const [msgBoxData, setMsgBoxData] = React.useState(null);

  /* Hooks */
  const { width } = useWindowDimensions();
  const [packagesData, extrasData, isLoaded] = useFetchPackagesAndExtrasData(data, setMsgBoxData);
  const extrasSelected = useGetExtrasSelected(extrasData, selectedExtras);
  const extrasLinked = useFetchExtrasLinked(data);
  const [currentIndex, onNavLeftClicked, onNavRightClicked] = useHandleNavigation(props, packagesData);
  const [totalPrice, setTotalPrice] = React.useState(null);
  const descriptions = useProductDescription(data);
  const [selectedSize, updateSelectedSize] = useGetSelectedSize(c.SIZE_DEFAULT_CHOICE);
  const sizeGuideOnClick = useSizeGuideModal(props);

  const [buttonState, setButtonState] = React.useState({
    text: "Ajouter au panier",
    className: ""
  });

  /* Ajout du produit au panier de la session */
  const onAddToCart = () => {
    /* → On bloque l'ajout au panier si on est en maintenance */
    if (isAdmin===false && (isLoading===true || isInMaintenance===true || isShopInMaintenance===true)) {
      setMsgBoxData({ text: "Le magasin est actuellement en maintenance, veuillez réessayer plus tard", classes: ["colorError"], isLoading: true });
      window.setTimeout(() => {
        setMsgBoxData(null);
      }, c.MSG_TIMEOUT_MID);
      return ;
    }
    /* → Filters out the selected extras that are included in the package */
    let filteredSelectedExtras = (extrasSelected ? [...extrasSelected] : null);
    if (packagesData[currentIndex] && filteredSelectedExtras) {
      packagesData[currentIndex].extras.forEach(extra => {
        if (extra.isIncluded && extra.type in c.SELECTABLE_EXTRAS) {
          filteredSelectedExtras[c.SELECTABLE_EXTRAS[extra.type].index] = undefined;
        }
      })
    }
    /* → Then add to cart */
    promiseFetch(`${url}/api/products/addToCart`, {
      method: 'POST',
      headers: { "Content-Type": "application/json; charset=utf-8" },
      body: JSON.stringify({
        product: data.productData,
        package: packagesData[currentIndex],
        extrasSelected: filteredSelectedExtras,
        extrasLinked: extrasLinked,
        subTotal: totalPrice,
        size: selectedSize.replace("Taille ", "")
      })
    })
      .then(res => {
        if (res.data==="r_exists") {
          setButtonState({ text: "Produit déjà ajouté au panier", className: "buttonDisabled" });
        } else if (res.status===400 || res.data==="r_noresult") {
          setButtonState({ text: "Impossible d'ajouter au panier", className: "buttonError" });
        } else if (res.data!==null) {
          /* → And redirect to home */
          updateAddedToCart(res.data);
          setMsgBoxData({ text: "Produit ajouté au panier", classes: ["colorSuccess"], isLoading: true });
          window.setTimeout(() => {
            props.history.push('/#cart');
          }, c.MSG_TIMEOUT_FAST);
        }
      });
  }

  return (
    <Modal className="productDetailsBox" msgBoxData={msgBoxData}>
      {isLoaded ?
        <>
          <div className="productTopLayout">
            <h3 className="productName">{data.productData.name}
              {data.productData.subtitle && <b>{' '+`- ${data.productData.subtitle}`.replace(/ /g, "\u00a0\u00a0")}</b>}
            </h3>
          </div>
          {descriptions?.["Intention de travail"] && <p className="productDescriptionIntent">{descriptions["Intention de travail"]}</p>}
          <div className="productBottomLayout">
            <div className="productLeftLayout">
              <ProductDetailsImage productData={data.productData} />
              <p className="productDescription">
                {descriptions?.["Composition des pierres"] && <span><b>{'Pierres: '}</b>{descriptions["Composition des pierres"]}<br/></span>}
                <span>Les pierres sont naturelles, elles peuvent donc présenter des variations de couleurs, de textures ou de formes entre la photo et la réalité.</span>
              </p>
            </div>
            <div className="productRightLayout">
              <PackageInfos packageData={packagesData[currentIndex]} packagesAmount={packagesData.length} currentIndex={currentIndex}
                extrasData={extrasData} extrasSelected={extrasSelected} setMsgBoxData={setMsgBoxData} descriptions={descriptions}
                onNavLeftClicked={onNavLeftClicked} onNavRightClicked={onNavRightClicked} />
              <h2 className="productPackageSection">Récapitulatif</h2>
              <div className="productSummarySection">
                <SummaryTable productData={data.productData} packageData={packagesData[currentIndex]}
                  extrasSelected={extrasSelected} extrasLinked={extrasLinked} setTotalPrice={setTotalPrice} />
                <p className="productPackageSummaryTotal">
                  Total
                  <span className="productPackageSummaryTotalAmount">{floatToPrice(totalPrice)}</span>
                </p>
              </div>

              <div className="productRightBottomLayout">
                <SelectionBox name="" value={selectedSize} choices={[''].concat(c.SIZE_CHOICES)} updateSelected={updateSelectedSize} />
                {/* <a className="productSizeGuide" href="#">{width<=c.MOBILE_BREAK_WIDTH?"Guide":"Guide des tailles"}</a> */}
                <a className="productSizeGuide" href="https://www.synesthesia.fr/#size-guide" onClick={sizeGuideOnClick}>{width<=c.MOBILE_BREAK_WIDTH?"Guide":"Guide des tailles"}</a>
                <div className={"buttonSecondaryAction "+buttonState.className}
                  style={{ margin: `auto ${width<=c.MOBILE_BREAK_WIDTH ? 4 : 18}px auto auto`, alignSelf: "flex-end", position: "relative" }}
                  onClick={onAddToCart}
                  role="button"
                >{buttonState.text}</div>
              </div>
            </div>
          </div>
        </> :
        <LoadingSpinner className="productDetailsSpinner"/>
      }
    </Modal>
  );
})
