import React from 'react'
import { useFetch, useFetchOnSubmit, useImagePreloader } from '../hooks/asyncHooks'
import { getPublicImageSrc, codeToProductId } from "../helperFunctions"
import { isMobile } from 'react-device-detect'
import c from '../Constants'

const url = process.env.REACT_APP_URL;

/*------------------------------------------------------------------------------
Helper formatMeditations
------------------------------------------------------------------------------*/
function formatMeditations(meditations, type) {
  return meditations.map(x => x.filepaths.map((y, i) => {
    let name = (i>0?`${x.name} - ${i+1}`:x.name);
    /* → If it's a custom group we use custom names instead of group name */
    if (c.MEDITATIONS_TYPE_ATTR[type] && c.MEDITATIONS_TYPE_ATTR[type].itemName!==undefined) {
      name = `${c.MEDITATIONS_TYPE_ATTR[type].itemName} - ${i+1}`;
    }
    return { filepath: y, name: name };
  })).flat();
}

/*------------------------------------------------------------------------------
Helper getGroupNumberFromId
------------------------------------------------------------------------------*/
function getGroupNumberFromId(pid, type) {
  if (type==="Bracelet") {
    return parseInt(pid.slice(1,3));
  } else {
    const num = parseInt(pid.slice(4));
    return (pid.slice(1,3)==="DP"?num:num+12);
  }
}

/*------------------------------------------------------------------------------
Hooks useMeditationsNumbersFromGroups
  Retourne les méditations qui correspondent au numéro et au type du produit référencé.
------------------------------------------------------------------------------*/
function useMeditationsNumbersFromGroups(number, type) {
  /* Get the product infos from the db */
  const [groups] = useFetch(`${url}/api/groups/getWithNumberAndType?number=${number}&type=${type}`);
  const [numbers, setNumbers] = React.useState(null);
  React.useEffect(() => {
    if (groups!==null) {
      /* Quick and dirty fix for quantiques groups that should only contain the one number */
      if (type==="Quantique") {
        setNumbers([number]);
      } else {
        const numbers = Object.keys(Object.fromEntries(groups.map(group => {
          return group.ids.map(id => [id, undefined]);
        }).flat()));
        setNumbers(numbers.map(x => (x[0]==='0'?x.slice(1):x)));
      }
    }
  }, [groups]);
  return numbers;
}

/*==============================================================================
Component MeditationsList
==============================================================================*/
function MeditationsList({ meditations, type, onMeditationClicked, selectedTrack, setIsEmpty }) {
  /* Create the names from the MeditationsGroup name and order in list */
  const [formattedMeditations] = React.useState(formatMeditations(meditations, type));
  /* Update state isEmpty if we don't have any meditations */
  React.useEffect(() => {
    if (formattedMeditations && formattedMeditations.length===0) {
      setIsEmpty && setIsEmpty(true);
    }
  }, [formattedMeditations]);

  if (formattedMeditations && formattedMeditations.length>0) {
    const meditationsArray = new Array(formattedMeditations.length*2-1).fill(0);
    /* Create an array twice as big and use map so that even numbers are the meditations and odd numbers the <hr> */
    return meditationsArray.map((_,i) => {
      const isSelected = (selectedTrack!==undefined && selectedTrack===i/2);
      const className = (isSelected?"meditationItemSelected":"meditationItem");
      return (i%2===0?
        <div className={className} key={i} onClick={() => onMeditationClicked(i/2)} role="button">
          {formattedMeditations[i/2].name}
        </div> :
        <hr key={i}/>
      );
    });
  } else {
    return null;
  }
}

/*------------------------------------------------------------------------------
Helper getDaysNameInWeekRelativeToMonth
------------------------------------------------------------------------------*/
function getDaysNameInWeekRelativeToMonth() {
  const DAYS = ['dim','lun','mar','mer','jeu','ven','sam'];
  const date = new Date();
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const sday = firstDay.getDay();
  return new Array(7).fill(0).map((_,i)=>DAYS[(sday+i)%7]);
}

/*==============================================================================
Component MeditationsDayGrid
==============================================================================*/
function MeditationsDayGrid({ meditationsNumber, onMeditationClicked, selectedTrack, setIsEmpty }) {
  /* Create the names from the MeditationsGroup name and order in list */
  const [formattedMeditations] = React.useState(() => {
    return new Array(31).fill(0).map((_,i)=>`${i+1<10?'0':''}${i+1}`);
  });
  const days = getDaysNameInWeekRelativeToMonth();
  /* Update state isEmpty if we don't have any meditations */
  React.useEffect(() => {
    if (meditationsNumber===0) {
      setIsEmpty && setIsEmpty(true);
    }
  }, [meditationsNumber]);

  if (meditationsNumber>0) {
    const meditationsArray = new Array(31+7).fill(0);
    /* Create an array twice as big and use map so that even numbers are the meditations and odd numbers the <hr> */
    return meditationsArray.map((_,i) => {
      if (i<7) {
        return <div className="meditationGridHeader" key={i}>{days[i]}</div>
      } else {
        const j = i-7;
        const isSelected = (selectedTrack!==undefined && selectedTrack===j);
        const isAvailable = (j<meditationsNumber);
        const className = (isAvailable?(isSelected?"meditationGridItemSelected":"meditationGridItem"):"meditationGridItemUnavailable");
        return (
          <div className={className} key={i} onClick={isAvailable?() => onMeditationClicked(j):()=>{}} role="button">
            {j<formattedMeditations.length?formattedMeditations[j]:"/"}
          </div>
        );
      }
    });
  } else {
    return null;
  }
}

/*==============================================================================
Main component
==============================================================================*/
export default function MeditationCard({ code, productData, handleTracks, onMeditationClicked, selectedTrack, zIndex }) {
  /* Make the css height match the dynamically calculated height of the img */
  const [inflHeight, setInflHeight] = React.useState(0);
  const onImageLoad = (e) => {
    setInflHeight(e.target.offsetHeight);
  };
  /* We load the group numbers first */
  const numbers = useMeditationsNumbersFromGroups(getGroupNumberFromId(productData.id, productData.type), productData.type);
  /* Then we fetch the meditations */
  const [meditations, handleMeditations] = useFetchOnSubmit(`${url}/api/meditations/withTypeAndNumbers?type=${productData.type}&numbers=${encodeURI(numbers)}`);
  /* Update the name for the older codes logic */
  const [name, setName] = React.useState(productData.name);
  React.useEffect(() => {
    if (numbers!=null) {
      handleMeditations();
    }
  }, [numbers]);
  React.useEffect(() => {
    if (meditations.data!==null && meditations.data.length!==0) {
      /* Change name if productData is dummy (for older keys) */
      if (productData.isDummy) {
        const number = parseInt(code.slice(1,3));
        const meditation = meditations.data.find(meditation => parseInt(meditation.number)===number);
        if (meditation) {
          setName(meditation.name);
        }
      }
      handleTracks({
        pid: codeToProductId(code),
        tracks: formatMeditations(meditations.data, productData.type)
      });
    }
  }, [meditations]);
  /* Preloads the image */
  const [isImageReady, imageSrc] = useImagePreloader(getPublicImageSrc(productData.imageMain));
  /* State to hide the meditationCard if it does not contain meditations */
  const [isEmpty, setIsEmpty] = React.useState(false);

  return (
    <>
      {meditations.data!==null && meditations.data.length!==0 && isImageReady && !isEmpty &&
        <div className="meditationsCard" style={{ zIndex: zIndex }}>
          <img className="meditationsImage" onLoad={onImageLoad} src={imageSrc} alt={`Méditation ${name}`}/>
          <div className="meditationsInfoLayout" style={{height: `${inflHeight}px`}}>
            <div className="meditationsName">{name}</div>
            {!isMobile &&
              <div className="meditationsId">{productData.id}</div>
            }
            <div className="meditationsCode">{code.slice(1)}</div>
            <hr/>
            <div className="meditationsList">
              <MeditationsList meditations={meditations.data} type={productData.type}
                onMeditationClicked={onMeditationClicked} selectedTrack={selectedTrack} setIsEmpty={setIsEmpty}/>
            </div>
          </div>
        </div>
      }
    </>
  );
}

/*==============================================================================
alt component
==============================================================================*/
export function MeditationCardFromMeditationData({ code, meditationData, handleTracks, onMeditationClicked, selectedTrack, zIndex }) {
  React.useEffect(() => {
    if (meditationData) {
      handleTracks({
        pid: code,
        tracks: formatMeditations([meditationData], meditationData.type)
      });
    }
  }, [meditationData]);
  /* State to hide the meditationCard if it does not contain meditations */
  const [isEmpty, setIsEmpty] = React.useState(false);

  return (
    <>
      {meditationData!==null && !isEmpty &&
        <div className="meditationsCard" style={{ zIndex: zIndex }}>
          <div className="meditationsInfoLayout" style={{ margin: "0" }}>
            <div className="meditationsName">{`${c.MEDITATIONS_TYPE_ATTR[meditationData.type].title} / ${meditationData.name}`}</div>
            <div className="meditationsCode">{code}</div>
            <hr/>
            {meditationData.type!=="Journalier" ?
              <div className="meditationsList">
                <MeditationsList meditations={[meditationData]} type={meditationData.type}
                  onMeditationClicked={onMeditationClicked} selectedTrack={selectedTrack} setIsEmpty={setIsEmpty} />
              </div> :
              <div className="meditationsGrid">
                <MeditationsDayGrid meditationsNumber={meditationData.filepaths.length} onMeditationClicked={onMeditationClicked}
                  selectedTrack={selectedTrack} setIsEmpty={setIsEmpty} />
              </div>
            }
          </div>
        </div>
      }
    </>
  );
}
