import { ModalContext } from '../Modal'
import React from 'react'
import { withRouter } from 'react-router-dom'
import MessageBox from '../components/MessageBox.component'
import { promiseFetch } from '../helperFunctions'
import LoadingSpinner from '../components/LoadingSpinner.component'
import { isChrome } from 'react-device-detect'
import c from '../Constants'

/* Constants */
const url = process.env.REACT_APP_URL;

/*------------------------------------------------------------------------------
Hook useFilterGroups
------------------------------------------------------------------------------*/
function useFilterGroups(groupsData, currentIndex) {
  const [filteredGroupsData, setFilteredGroupsData] = React.useState(null);
  React.useEffect(() => {
    if (groupsData) {
      /* "Tous" selected */
      if (currentIndex===0) {
        setFilteredGroupsData(groupsData);
      } else {
        const filterType = Object.keys(c.GROUPS_TYPE_ATTR)[currentIndex-1];
        setFilteredGroupsData(groupsData.filter(group => group.type===filterType||group.isNew));
      }
    }
  }, [groupsData, currentIndex]);
  return filteredGroupsData;
}

/*------------------------------------------------------------------------------
Hook useFetchGroups
------------------------------------------------------------------------------*/
function useFetchGroups(setMsgBoxData) {
  const [groups, setGroups] = React.useState(null);
  const [shouldRefresh, setShouldRefresh] = React.useState(true);
  React.useEffect(() => {
    if (shouldRefresh) {
      setShouldRefresh(false);
      promiseFetch(`${url}/api/groups`)
        .then(res => {
          if (res.status===200 && res.data) {
            /* Sort by numerical value */
            res.data.sort((a, b) => {
              if (isNaN(a.number) || isNaN(b.number)) {
                return (a.number > b.number ? 1 : -1);
              }
              return a.number - b.number;
            });
            /* Then sort by type */
            res.data.sort((a, b) => c.GROUPS_TYPE_ATTR[a.type].sortOrder-c.GROUPS_TYPE_ATTR[b.type].sortOrder);
            setGroups(res.data);
          } else {
            setMsgBoxData({ text: "Impossible de récupérer les groupes", classes: ["colorError"] });
          }
        });
    }
  }, [shouldRefresh]);
  return [groups, setGroups, setShouldRefresh];
}

/*------------------------------------------------------------------------------
Hook useFetchMeditations
------------------------------------------------------------------------------*/
function useFetchMeditations(setMsgBoxData) {
  const [meditations, setMeditations] = React.useState(null);
  const [shouldRefresh, setShouldRefresh] = React.useState(true);
  React.useEffect(() => {
    if (shouldRefresh) {
      setShouldRefresh(false);
      promiseFetch(`${url}/api/meditations`)
        .then(res => {
          if (res.status===200 && res.data) {
            setMeditations(res.data);
          } else {
            setMsgBoxData({ text: "Impossible de récupérer les méditations", classes: ["colorError"] });
          }
        })
    }
  }, [shouldRefresh]);
  return [meditations, setShouldRefresh];
}

/*------------------------------------------------------------------------------
Hook useHandleSelectableQuantity
------------------------------------------------------------------------------*/
function useHandleSelectableValue(value, updateSelected) {
  const [selectedValue, setSelectedValue] = React.useState(value);
  const handleSelectedValue = (e) => {
    setSelectedValue(e.target.value);
    updateSelected && updateSelected(e.target.value);
  }
  React.useLayoutEffect(() => {
    if (value!==null) {
      setSelectedValue(value);
    }
  }, [value]);
  return [selectedValue, handleSelectedValue];
}

/*------------------------------------------------------------------------------
Hook useGetFilteredThemes
------------------------------------------------------------------------------*/
function useGetFilteredThemes(meditationsData, groupType) {
  const [filteredThemes, setFilteredThemes] = React.useState(null);
  React.useEffect(() => {
    if (meditationsData && groupType) {
      setFilteredThemes(meditationsData.filter(x => x.type===groupType));
    }
  }, [meditationsData, groupType]);
  return filteredThemes;
}

/*------------------------------------------------------------------------------
Hook useGetSelectableThemes
------------------------------------------------------------------------------*/
function useGetSelectableThemes(meditationsData) {
  const [selectableThemes, setSelectableThemes] = React.useState(null);
  React.useEffect(() => {
    if (meditationsData) {
      let meditations = meditationsData;
      meditations = meditations.sort((a, b) => parseInt(a.number)-parseInt(b.number)).map(x => `${x.number} - ${x.name}`);
      setSelectableThemes(meditations);
    }
  }, [meditationsData]);
  return selectableThemes;
}

/*------------------------------------------------------------------------------
Hook useGetGroupIds
------------------------------------------------------------------------------*/
function useGetGroupIds(groupData, groupType, meditationsData) {
  /* Hooks */
  const [selectedThemes, setSelectedThemes] = React.useState([]);
  const filteredThemes = useGetFilteredThemes(meditationsData, groupType);
  const selectableThemes = useGetSelectableThemes(filteredThemes);
  React.useEffect(() => {
    if (selectableThemes && groupData) {
      setSelectedThemes(selectableThemes.filter(x => groupData.ids.includes(x.split(' - ')[0])));
    }
  }, [selectableThemes, groupData]);
  /* Callbacks */
  const onAddTheme = (e) => {
    e && e.preventDefault();
    setSelectedThemes(selectedThemes => ([...selectedThemes, ""]));
  };

  const onRemoveTheme = (e, idx) => {
    e && e.preventDefault();
    setSelectedThemes(selectedThemes => {
      let res = [...selectedThemes]; // Clone the object so React knows there's been a change.
      res.splice(idx, 1);
      if (Object.values(res).length===0) {
        res.push("");
      }
      return res;
    });
  };

  const onUpdateTheme = (idx, value) => {
    setSelectedThemes(selectedThemes => {
      const res = [...selectedThemes];
      res[idx] = value;
      return res;
    });
  };

  return { selectedThemes, selectableThemes, onAddTheme, onRemoveTheme, onUpdateTheme };
}

/*------------------------------------------------------------------------------
Hook useGetGroupNumbers
------------------------------------------------------------------------------*/
function useGetGroupNumbers(groupData, num=10) {
  const [selectedNumber, setSelectedNumber] = React.useState(groupData.number);
  const selectableNumbers = [...Array.from(Array(num).keys()), 'DP', 'VQ'];
  React.useEffect(() => {
    if (groupData) {
      setSelectedNumber(groupData.number);
    }
  }, [groupData]);
  /* Callback */
  const updateSelectedNumber = (value) => {
    setSelectedNumber(value);
  };
  return [selectedNumber, selectableNumbers, updateSelectedNumber];
}

/*==============================================================================
Component SelectionBox
==============================================================================*/
function SelectionBox({ name, value, choices, updateSelected, width=undefined }) {
  const [selectedValue, handleSelectedValue] = useHandleSelectableValue(value, updateSelected);
  return (
    <div className={!isChrome ? "selectionBox" : "selectionBoxChrome"}>
      <p>{name}</p>
      <select name={name} onChange={handleSelectedValue} value={selectedValue} style={width ? { width: `${width}px`, margin: "0 0 0 -1px" } : {}}>
        {choices.map((x, i) => {
          return <option value={x} key={i} style={i===0?{ display: "none" }:{}}>{x}</option>;
        })}
      </select>
    </div>
  );
}

/*==============================================================================
Component SelectionBoxList
==============================================================================*/
function SelectionBoxList({ name, values, choices, onAddItem, onRemoveItem, onUpdateItem }) {
  return (
    <>
      {values.map((value, idx) => {
        /* Only display the choices that are not yet selected */
        let newchoices = choices.filter(choice => !values.includes(choice)).concat(value);
        newchoices=newchoices.sort((a, b) => parseInt(a.split(' - ')[0])-parseInt(b.split(' - ')[0]));

        return <div style={{ display: "flex", flexDirection: "row" }} key={idx}>
          <SelectionBox name={idx===0?name:""} value={value} choices={[""].concat(newchoices)}
            updateSelected={(value) => onUpdateItem(idx, value)} />
          {onRemoveItem && <div className="selectionBoxButton" onClick={(e) => onRemoveItem(e, idx)} role="button">Retirer</div>}
        </div>
      })}
      {onAddItem && 
        <div className={!isChrome ? "selectionBox" : "selectionBoxChrome"} style={{ margin: '-5px 0 5px' }}>
          <p></p>
          <div className="selectionBoxButton" onClick={onAddItem} style={{ margin: "0" }} role="button">+ Ajouter</div>
        </div>
      }
    </>
  );
}

/*==============================================================================
Component TextInput
==============================================================================*/
function TextInput({ name, value, updateValue, dkey=null, style={} }) {
  /* Submit callback */
  const updateInputValue = (e) => {
    e.preventDefault();
    if (dkey!==null) {
      updateValue(dkey, e.target.value);
    } else {
      updateValue(e.target.value);
    }
  };
  return (
    <div className="adminProductTextInputLayout" style={style}>
      <p style={{ width: "64px" }}>{name}</p>
      <input className="adminProductTextInput" type="text" value={value} onChange={updateInputValue} />
    </div>
  );
}

/*------------------------------------------------------------------------------
Hook useGetSelectedType
------------------------------------------------------------------------------*/
function useGetSelectedType(productType) {
  const [selectedType, setSelectedType] = React.useState(productType);
  React.useEffect(() => {
    if (productType) {
      setSelectedType(productType);
    }
  }, [productType]);
  const updateSelectedType = (value) => {
    setSelectedType(value);
  };
  return [selectedType, updateSelectedType];
}

/*------------------------------------------------------------------------------
Hook useGetName
------------------------------------------------------------------------------*/
function useGetName(groupData) {
  const [nanme, setName] = React.useState(groupData.name);
  React.useEffect(() => {
    if (groupData.name) {
      setName(groupData.name);
    }
  }, [groupData.name]);
  return [nanme, setName];
}

/*==============================================================================
Component GroupActions
==============================================================================*/
const GroupActions = withRouter(({ groupData, groupsData, setGroupsData, meditationsData, index, doRefresh, doRefreshThis, doDeleteThis, selectedType, updateSelectedType, setMsgBoxData, ...props }) => {
  /* Hooks */
  const { selectedThemes, selectableThemes, onAddTheme, onRemoveTheme, onUpdateTheme } = useGetGroupIds(groupData, selectedType, meditationsData);
  const [selectedNumber, selectableNumbers, updateSelectedNumber] = useGetGroupNumbers(groupData, 32);
  const [name, setName] = useGetName(groupData);

  React.useEffect(() => {
    setMsgBoxData(null);
  }, []);

  /* Callback pour faire la sauvegarde du groupe */
  const onSave = () => {
    /* → Verify that the number is not used for the type yet */
    const item = groupsData.find(x => x.type===selectedType && x.number===selectedNumber);
    if (!(selectedType===groupData.type && selectedNumber===groupData.number) && item) {
      setMsgBoxData({ text: `Le numéro "${selectedNumber}" est déjà utilisé pour le type "${selectedType}"`, classes: ["colorError"] });
    } else if (selectedType==="") {
      setMsgBoxData({ text: `Veuillez sélectionner un type`, classes: ["colorError"] });
    } else if (selectedNumber==="") {
      setMsgBoxData({ text: `Veuillez sélectionner un numéro`, classes: ["colorError"] });
    } else if (selectedThemes.length===0 || selectedThemes[0]==="") {
      setMsgBoxData({ text: `Veuillez ajouter au moins un thème`, classes: ["colorError"] });
    } else {
      setMsgBoxData({ text: "Sauvegarde du groupe", classes: ["colorInfo"], isLoading: true });
      /* → Request to modify group */
      promiseFetch(`${url}/api/groups/modify`, {
        method: 'POST',
        headers: { "Content-Type": "application/json; charset=utf-8" },
        body: JSON.stringify({
          current: (groupData.isNew ? null : groupData), // the current group to change
          type: selectedType,
          name: name,
          number: selectedNumber,
          ids: selectedThemes.filter(x => x).map(x => x.split(' - ')[0]),
        })
      })
        .then(res => {
          if (res.status===200 && res.data) {
            window.setTimeout(() => {
              /* → Update the local data */
              setGroupsData(groupsData => {
                const index = groupsData.findIndex(x => x.type===groupData.type && x.number===groupData.number);
                if (index!==-1) {
                  groupsData[index] = res.data;
                  return [...groupsData];
                }
                return groupsData;
              });
              setMsgBoxData(null);
            }, c.MSG_TIMEOUT_FAST);
          } else {
            setMsgBoxData({ text: res.data, classes: ["colorError"], isLoading: false });
          }
        });
    }
  };

  return (
    <div className="orderPageSummary" style={{ width: "650px", padding: "15px", margin: "0 auto 20px", background: "inherit" }}>
      <div className="ordersEntry">
        <div className="adminProductActionsLayout">
          <SelectionBox name="Type" value={selectedType} choices={[""].concat(Object.keys(c.GROUPS_TYPE_ATTR))} updateSelected={updateSelectedType} />
          <TextInput name="Nom" value={name} updateValue={setName} />
          <SelectionBox name="Numéro" value={selectedNumber} choices={selectableNumbers} updateSelected={updateSelectedNumber} width={60}/>
          {selectableThemes &&
            <SelectionBoxList name="Thèmes" values={selectedThemes} choices={selectableThemes} onAddItem={onAddTheme}
              onRemoveItem={onRemoveTheme} onUpdateItem={onUpdateTheme} />
          }
        </div>
      </div>
      <div className="adminProductBottomLayout">
        <div className="adminProductButtonsLayout">
          <div className="orderActionButton buttonReject" style={{ margin: "0 auto 0 0" }} onClick={doDeleteThis} role="button">Supprimer</div>
          <div className="orderActionButton" onClick={doRefreshThis} role="button">Annuler</div>
          <div className="orderActionButton buttonConfirm" onClick={onSave} role="button">Sauvegarder les changements</div>
        </div>
      </div>
    </div>
  );
});

/*==============================================================================
Component GroupEntryItem
==============================================================================*/
const GroupEntryItem = withRouter(({ groupData, groupsData, setGroupsData, meditationsData, index, zIndex, doRefresh, doDelete, ...props }) => {
  /* Hooks */
  const [msgBoxData, setMsgBoxData] = React.useState(null);
  const msgBoxAnchorRef = React.useRef(null);

  /* Resets msgBoxData at unmount */
  React.useEffect(() => {
    return () => { setMsgBoxData(null); };
  }, []);

  const itemExpandedRef = React.useRef(null);
  React.useEffect(() => {
    if (groupData && itemExpandedRef.current) {
      itemExpandedRef.current.style.display = "none";
    }
  }, [groupData, itemExpandedRef]);
  /* onClick callback function */
  const onClick = (e) => {
    e.preventDefault();
    if (itemExpandedRef.current) {
      itemExpandedRef.current.style.display = (itemExpandedRef.current.style.display==="none" ? "flex" : "none");
      setMsgBoxData(null);
    }
  };
  /* Delete component callback */
  const deleteComponent = () => {
    doDelete(groupData);
  };
  /* Used to switch components */
  const [selectedType, updateSelectedType] = useGetSelectedType(groupData.type);
  /* Used to refresh the component when we cancel */
  const [key, setKey] = React.useState(0);
  const refreshComponent = () => {
    setKey(key => key + 1);
    updateSelectedType(groupData.type);
  };

  return (
    <>
      <div className="orderEntryItemBox" style={{ zIndex: zIndex }} >
        <div className="orderEntryItemHeader" onClickCapture={onClick} style={{ color: 'var(--color-text)' }}role="button">
          {groupData.isNew ?
            <p className="productEntryItemCell" style={{ width: "75%" }}><b>Nouveau groupe</b></p> :
            <p className="productEntryItemCell" style={{ width: "75%" }}><b>{`${groupData.type}, ${groupData.number}`}</b>{` / ${groupData.name}`}</p>
          }
          <p className="productEntryItemCell" style={{ margin: "auto 16px auto auto" }}>{`${groupData.ids.length} thèmes`}</p>
        </div>
        <div className="orderEntryItemExpanded" ref={itemExpandedRef} style={{ display: "none" }}>
          <GroupActions groupData={groupData} groupsData={groupsData} setGroupsData={setGroupsData} meditationsData={meditationsData} index={index}
          doRefresh={doRefresh} selectedType={selectedType} updateSelectedType={updateSelectedType} setMsgBoxData={setMsgBoxData}
          key={key} doRefreshThis={refreshComponent} doDeleteThis={deleteComponent} />
        </div>
        {msgBoxData!==undefined && <MessageBox msgBoxData={msgBoxData} componentRef={msgBoxAnchorRef} mode="absolute2" />}
      </div>
      <div className="orderEmptyMsgBoxAnchor" ref={msgBoxAnchorRef}></div>
    </>
  );
})

/*==============================================================================
Component GroupEntriesLeftMenu
==============================================================================*/
function GroupEntriesLeftMenu({ currentIndex, setCurrentIndex, ...props }) {
  const onClick = (e, index) => {
    setCurrentIndex(index);
  }
  return (
    <nav className="orderEntriesLeftMenu">
      <ul>
        {["Tous", ...Object.values(c.GROUPS_TYPE_ATTR).map(x => x.category)].map((label, i) => {
          const style = (currentIndex===i ? { fontWeight: 700 } : {});
          return <li key={i} title={label} onClick={(e) => onClick(e, i)} style={style} role="button">
            {label}
          </li>;
        })}
      </ul>
    </nav>
  );
}

/*==============================================================================
Component GroupEntriesRightButton
==============================================================================*/
function GroupEntriesRightButton({ addGroup, ...props }) {
  const onClick = (e) => {
    addGroup();
  }
  return (
    <div className="groupEntriesRightButton" onClick={onClick} role="button">
      + Ajouter un groupe
    </div>
  );
}

/*==============================================================================
Main component
==============================================================================*/
export default withRouter(function AdminGroupsPage({ setMsgBoxData, ...props }) {
  const { setModalProps } = React.useContext(ModalContext);
  const entriesLayoutRef = React.useRef(null);
  /* Hooks */
  const [currentIndex, setCurrentIndex] = React.useState(0);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentIndex]);
  const [meditationsData] = useFetchMeditations(setMsgBoxData);
  const [groupsData, setGroupsData, doRefresh] = useFetchGroups(setMsgBoxData);
  const filteredGroupsData = useFilterGroups(groupsData, currentIndex);
  /* → Open a confirmation modal */
  const doDelete = (groupData) => {
    /* Callback for confirmation modal */
    const nextCallback = (data, props, setMsgBoxData) => {
      setMsgBoxData({ text: "Suppression du groupe", classes: ["colorInfo"], isLoading: true });
      /* → Request to server to delete the product */
      promiseFetch(`${url}/api/groups/delete`, {
        method: 'DELETE',
        headers: { "Content-Type": "application/json; charset=utf-8" },
        body: JSON.stringify({
          type: data.groupData.type,
          number: data.groupData.number
        })
      })
        .then(res => {
          if (res.status===200 && res.data) {
            /* → Update local data */
            data.setGroupsData(groupsData => {
              return groupsData.filter(x => !(x.type===data.groupData.type && x.number===data.groupData.number));
            });
            window.setTimeout(() => {
              setMsgBoxData(null);
              /* → Redirection */
              props.history.goBack();
            }, c.MSG_TIMEOUT_FAST);
          } else {
            setMsgBoxData({ text: "Une erreur inconnue est survenue", classes: ["colorError"] });
          }
        })
    };

    setModalProps({ groupData, setGroupsData, next: nextCallback,
      text: <><b>Voulez-vous vraiment supprimer ce groupe ?</b><br/><br/>
        Les méditations associées aux thèmes des groupes ne seront plus liées et risquent de ne plus être accessibles aux clients.</>
    });
    props.history.push('#deletegroup');
  };

  const addGroup = () => {
    setGroupsData(groupsData => {
      if (!groupsData.find(groupData => groupData.isNew)) {
        groupsData.push({ type: "", name: "", number: "", ids: [], isNew: true });
      }
      return [...groupsData];
    });
    /* Scroll to the added item */
    if (entriesLayoutRef.current && entriesLayoutRef.current.lastChild) {
      const childPos = entriesLayoutRef.current.lastChild.getBoundingClientRect().top;
      window.scrollTo({ top: childPos + window.pageYOffset - window.innerHeight / 2, behavior: 'smooth' });
    }
  };

  return (
    <>
      {filteredGroupsData ?
        <>
          <GroupEntriesLeftMenu currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} />
          <GroupEntriesRightButton addGroup={addGroup} />
          <div className="orderEntriesLayout" ref={entriesLayoutRef}>
            {filteredGroupsData.map((groupData, i) => {
              const key = `${groupData.type}${groupData.number}${groupData.name}`;
              return <GroupEntryItem key={key} groupData={groupData} groupsData={groupsData} index={i}
              setGroupsData={setGroupsData} meditationsData={meditationsData} zIndex={filteredGroupsData.length - i}
              doRefresh={doRefresh} doDelete={doDelete} />;
            })}
          </div>
        </> :
        <LoadingSpinner />
      }
    </>
  );
})
