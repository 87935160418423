import { AuthContext } from '../Auth'
import React from 'react'
import { withRouter } from 'react-router'
import Modal from './Modal.component'
import { useEnterText } from '../hooks/asyncHooks'
import { promiseFetch } from '../helperFunctions'
import c from '../Constants'

const url = process.env.REACT_APP_URL;

/*==============================================================================
Component Login
==============================================================================*/
const Login = withRouter(({ callback, inMaintenance, handleMsgBoxData, ...props }) => {
  /* Auth context */
  const { login } = React.useContext(AuthContext);
  /* Force focus on the input text */
  const emailInputRef = React.useRef(null);
  React.useEffect(() => {
    emailInputRef.current!==null && emailInputRef.current.focus();
  }, [emailInputRef]);

  /* Hooks to handle the signup form */
  const [email, updateEmail] = useEnterText();
  const [password, updatePassword] = useEnterText();
  const handleUpdateEmail = (text) => { handleMsgBoxData(null); updateEmail(text); };
  const handleUpdatePassword = (text) => { handleMsgBoxData(null); updatePassword(text); };

  /* Validation */
  const handleLoginOnSubmit = (e) => {
    e.preventDefault();
    const emailRegex = /\S+@\S+\.\S+/;
    if (emailRegex.test(email) === false) {
      handleMsgBoxData({ text: "Veuillez entrer un email valide", classes: ["colorError"] });
    } else if (password.length===0) {
      handleMsgBoxData({ text: "Veuillez entrer votre mot de passe", classes: ["colorError"] });
    } else {
      /* → First we check if there's a userreset in process */
      promiseFetch(`${url}/api/userresets/exists/${email}`)
        .then(res => {
          if (res.data.valid) {
            /* → If there's one, we log and ask to change the password by showing "passwordResetModal" */
            login({ email, password, inMaintenance })
              .then(res => {
                /* → Then redirect to the passwordResetModal */
                if (res.status===200) {
                  props.history.replace('#passwordreset');
                } else {
                  handleMsgBoxData({ text: "Email ou mot de passe incorrect", classes: ["colorError"] });
                }
              });
          } else {
            /* → If there's none, we log the user up */
            login({ email, password, inMaintenance })
              .then(res => {
                /* → Then redirect to the admin or profile page */
                if (res.status===200 && res.data) {
                  if (res.data.admin) {
                    props.history.replace('/admin');
                  } else {
                    props.history.replace('/profile');
                  }
                } else {
                  handleMsgBoxData({ text: res.data, classes: ["colorError"] });
                }
              });
          }
        })
    }
  };

  return (
    <div className="signInVLayout" style={inMaintenance===true?{width:"100%"}:{}}>
      <div className="signInTitle">Connexion</div>
      <div className="signInSubTitle">{inMaintenance===true?"Accès administrateur":"Vous avez déjà un compte"}</div>
      <form onSubmit={handleLoginOnSubmit}>
        <input className="signInInput" type="text" placeholder="Email" ref={emailInputRef} autoComplete="username"
          value={email} onChange={handleUpdateEmail} />
        <input className="signInInput" type="password" placeholder="Mot de passe" autoComplete="current-password"
          value={password} onChange={handleUpdatePassword} />
        {!inMaintenance ?
          <div className="signInForgottenButton" onClick={callback} role="button">Mot de passe oublié</div> :
          <p/>
        }
        <input className="buttonPrimaryAction" type="submit" value="Se connecter" />
      </form>
    </div>
  );
})

/*==============================================================================
Component Signup
==============================================================================*/
const Signup = withRouter(({ handleMsgBoxData, ...props }) => {
  /* Auth context */
  const { signup, login } = React.useContext(AuthContext);
  /* Hooks to handle the signup form */
  const [email, updateEmail] = useEnterText();
  const [password, updatePassword] = useEnterText();
  const [confirmation, updateConfirmation] = useEnterText();
  const handleUpdateEmail = (text) => { handleMsgBoxData(null); updateEmail(text); };
  const handleUpdatePassword = (text) => { handleMsgBoxData(null); updatePassword(text); };
  const handleUpdateConfirmation = (text) => { handleMsgBoxData(null); updateConfirmation(text); };

  /* Validation */
  const handleSignupOnSubmit = (e) => {
    e.preventDefault();
    const emailRegex = /\S+@\S+\.\S+/;
    if (emailRegex.test(email) === false) {
      handleMsgBoxData({ text: "Veuillez entrer un email valide", classes: ["colorError"] });
    } else if (password.length===0) {
      handleMsgBoxData({ text: "Veuillez entrer un mot de passe", classes: ["colorError"] });
    } else if (password.length < 7) {
      handleMsgBoxData({ text: "Veuillez créer un mot de passe contenant au moins 7 caractères", classes: ["colorError"] });
    } else if (confirmation.length===0) {
      handleMsgBoxData({ text: "Veuillez confirmer ce mot de passe", classes: ["colorError"] });
    } else if (password!==confirmation) {
      handleMsgBoxData({ text: "Les mots de passe sont différents", classes: ["colorError"] });
    } else {
      /* → First we sign the user up */
      signup({ email, password })
        .then(res => {
          if (res.status===200) {
            /* → Then we log the user */
            login({ email, password })
              .then(res => {
                /* → Then redirect to the admin or profile page */
                if (res.status===200 && res.data) {
                  if (res.data.admin) {
                    props.history.replace('/admin');
                  } else {
                    props.history.replace('/profile');
                  }
                } else {
                  handleMsgBoxData({ text: "Email ou mot de passe incorrect", classes: ["colorError"] });
                }
              })
          } else {
            handleMsgBoxData({ text: res.data, classes: ["colorError"] });
          }
        });
    }
  };

  return (
      <div className="signInVLayout">
        <div className="signInTitle">Inscription</div>
        <div className="signInSubTitle">Vous souhaitez créer un compte</div>
        <form onSubmit={handleSignupOnSubmit}>
          <input className="signInInput" type="text" placeholder="Email" autoComplete="username"
            value={email} onChange={handleUpdateEmail} />
          <input className="signInInput" type="password" placeholder="Mot de passe" autoComplete="new-password"
            value={password} onChange={handleUpdatePassword} />
          <input className="signInInput" type="password" placeholder="Confirmation du mot de passe" autoComplete="new-password" style={{marginBottom: "23px"}}
            value={confirmation} onChange={handleUpdateConfirmation} />
          <input className="buttonPrimaryAction" type="submit" value="Créer un compte" />
        </form>
      </div>
  );
})

/*==============================================================================
Main component
==============================================================================*/
export default withRouter(function SignInModal({ callback, inMaintenance, ...props }) {
  /* Message box */
  const [msgBoxData, setMsgBoxData] = React.useState(null);
  /* To know if we're finished checking first if we're auth */
  const [isComponentLoaded, setIsComponentLoaded] = React.useState(false);
  /* When loading the component we redirect to /profile if we're authenticated */
  const { checkStatus } = React.useContext(AuthContext);
  React.useEffect(() => {
    /* → Check the user status */
    checkStatus()
      .then(res => {
        if (res.data.auth) {
          if (res.data.reset) {
            props.history.replace('#passwordreset');
          } else {
            if (res.data.admin) {
              props.history.replace('/admin');
            } else {
              props.history.replace('/profile');
            }
          }
        } else {
          setIsComponentLoaded(true);
        }
      })
  }, []);

  return (
    <>
      {isComponentLoaded &&
        <Modal className={!inMaintenance?"signInBox":"signInBoxMaintenance"} msgBoxData={msgBoxData}>
          <Login callback={callback} inMaintenance={inMaintenance} handleMsgBoxData={setMsgBoxData} />
          {!inMaintenance && <>
            <div className="signInVerticalLine"></div>
            <div className="signInVerticalLineText">Ou</div>
            <Signup handleMsgBoxData={setMsgBoxData} />
          </>}
        </Modal>
      }
    </>
  );
})
