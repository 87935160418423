import React from 'react'
import { withRouter } from 'react-router'
import LoadingSpinner from './LoadingSpinner.component'
import MeditationCard, { MeditationCardFromMeditationData } from './MeditationCard.component'
import { codeToProductId, promiseFetch } from '../helperFunctions'

const url = process.env.REACT_APP_URL;

/*------------------------------------------------------------------------------
Helper getGroupNumberFromCode
------------------------------------------------------------------------------*/
function getGroupNumberFromCode(code) {
  const gnumber = code.slice(0,2);
  const number = parseInt(gnumber);
  return isNaN(number) ? gnumber : number;
}

/*------------------------------------------------------------------------------
Helper createDummyProduct
------------------------------------------------------------------------------*/
function createDummyProduct(newpid) {
  return {
    id: newpid,
    type: "Bracelet",
    name: "/",
    imageMain: "main/Bandeau 22.jpg",
    price: 29,
    isDummy: true
  };
}

/*==============================================================================
Component MeditationCardFromItem
==============================================================================*/
function MeditationCardFromItem({ item, j, handleTracks, onMeditationClicked, selectedTrack, zIndex }) {
  if (item.type==="product") {
    /* Add the pid to the callback flow */
    const doOnMeditationClicked = (key) => onMeditationClicked(key, item.data.id);
    const selectedTrackInCard = (selectedTrack!==null&&selectedTrack[1]===item.data.id) && selectedTrack[0];
    return <MeditationCard code={'#'+item.code} productData={item.data} handleTracks={handleTracks}
      onMeditationClicked={doOnMeditationClicked} selectedTrack={selectedTrackInCard} zIndex={zIndex} key={j} />
  } else if (item.type==="meditation") {
    /* Add the code to the callback flow */
    const doOnMeditationClicked = (key) => onMeditationClicked(key, item.code);
    const selectedTrackInCard = (selectedTrack!==null&&selectedTrack[1]===item.code) && selectedTrack[0];
    return <MeditationCardFromMeditationData code={item.code} meditationData={item.data} handleTracks={handleTracks}
      onMeditationClicked={doOnMeditationClicked} selectedTrack={selectedTrackInCard} zIndex={zIndex} key={j} />
  }
  return null;
}

/*==============================================================================
Main component
==============================================================================*/
export default withRouter(function MeditationCardColumn({ keysEntered, handleTracks, onMeditationClicked, selectedTrack, ...props }) {
  /* Get the product infos from the db */
  const [data, setData] = React.useState(null)
  
  React.useEffect(() => {
    if (keysEntered && keysEntered.data) {
      const data = new Array(keysEntered.data.length).fill(null);
      const codes = keysEntered.data;
      const pids = codes.map(code => codeToProductId(`#${code}`));
      if (pids.length>0) {
        /* → Fetch the products */
        promiseFetch(`${url}/api/products/getWithPids/${encodeURIComponent(pids)}`)
          .then(res => {
            if (res.status===200) {
              let filteredCodes = codes;
              /* → Update the local data */
              if (res.data) {
                res.data.forEach(product => {
                  const idx = pids.findIndex(pid => pid===product.id);
                  data[idx] = { type: "product", data: product, code: codes[idx] };
                });
                /* → Only fetch the meditations from the remaining codes that were not products id */
                filteredCodes = codes.filter((_,i) => !res.data.find(x => x.id===pids[i]));
              }
              if (filteredCodes.length>0) {
                /* → Fetch the meditations */
                promiseFetch(`${url}/api/meditations/withKeys/${encodeURIComponent(filteredCodes)}`)
                  .then(res => {
                    if (res.status===200) {
                      /* → Update the local data */
                      if (res.data) {
                        res.data.forEach(meditation => {
                          const idx = codes.findIndex(code => code===meditation.generatedKey);
                          data[idx] = { type: "meditation", data: meditation, code: codes[idx] };
                        });
                      }
                      /* → We handle the older codes as needed, as they're not attached to a product anymore */
                      const filteredCodesRemaining = codes.filter((code,i) => {
                        return data[i]===null && !isNaN(getGroupNumberFromCode(code));
                      });
                      if (filteredCodesRemaining.length>0) {
                        /* → We create a dummy product for each remaining code */
                        filteredCodesRemaining.forEach(code => {
                          const newpid = codeToProductId(`#${code}`);
                          const dummyProduct = createDummyProduct(newpid);
                          const idx = pids.findIndex(pid => pid===newpid);
                          data[idx] = { type: "product", data: dummyProduct, code: code };
                        })
                      }
                      /* → Update the data to use for creating the meditation cards */
                      setData(data.filter(x => x!==null));
                    }
                  })
              } else {
                /* → Update the data to use for creating the meditation cards */
                setData(data.filter(x => x!==null));
              }
            }
          })
      } else {
        setData([]);
      }
    }
  }, [keysEntered]);

  return (
    <>
      {data!==null ?
        <div className="meditationsColumn">
          {data.map((item,j) => <MeditationCardFromItem item={item} j={j} handleTracks={handleTracks}
            onMeditationClicked={onMeditationClicked} selectedTrack={selectedTrack} zIndex={data.length-j} key={j} />)}
        </div> :
        <LoadingSpinner className="meditationsColumnSpinner" />
      }
    </>
  );
})
