import React from 'react'
import { withRouter } from 'react-router' 
import Modal from './Modal.component'
import { useEnterText } from '../hooks/asyncHooks'
import { promiseFetch } from '../helperFunctions'
import c from '../Constants'

const url = process.env.REACT_APP_URL;

/*==============================================================================
Main component
==============================================================================*/
export default withRouter(function ForgottenPasswordModal({ ...props }) {
  /* Message box data */
  const [msgBoxData, setMsgBoxData] = React.useState(null);
  /*Force focus on the input text*/
  const emailInputRef = React.useRef(null);
  React.useEffect(() => {
    emailInputRef.current!==null && emailInputRef.current.focus();
  }, [emailInputRef]);

  /* Hooks to handle the form */
  const [email, updateEmail] = useEnterText();
  const handleUpdateEmail = (text) => { setMsgBoxData(null); updateEmail(text); };

  const onSubmitClicked = (e) => {
    e.preventDefault();
    /* → We verify that the form values are correct */
    const emailRegex = /\S+@\S+\.\S+/;
    if (emailRegex.test(email)===false) {
      setMsgBoxData({ text: "Veuillez entrer un email valide", classes: ["colorError"] });
    } else {
      /* → We send a request to back that will send a mail with sendinblue */
      promiseFetch(`${url}/api/users/resetpwd`, {
        method: 'POST',
        headers: { "Content-Type": "application/json; charset=utf-8" },
        body: JSON.stringify({ email: email })
      })
        .then(res => {
          if (res.status===200) {
            setMsgBoxData({ text: "Un email de réinitialisation a été envoyé", classes: ["colorSuccess"], isLoading: true });
            window.setTimeout(() => {
              props.history.push('/#signin');
            }, c.MSG_TIMEOUT_FAST);
          } else if (res.status===299) {
            setMsgBoxData({ text: "Un email de réinitialisation a déjà été envoyé", classes: ["colorInfo"], isLoading: true });
            window.setTimeout(() => {
              props.history.push('/#signin');
            }, c.MSG_TIMEOUT_FAST);
          } else {
            setMsgBoxData({ text: "Une erreur est survenue", classes: ["colorError"] });
          }
        })
    }
  }

  return (
    <Modal className="forgottenPasswordBox" msgBoxData={msgBoxData}>
      <h1 className="modalTitle">Mot de passe oublié</h1>
      <div className="addCodeDescription">Veuillez entrer l’email associé à votre compte, nous allons procéder à l’envoi d’un mot de passe temporaire et de la marche à suivre.</div>
      <form onSubmit={onSubmitClicked}>
        <input className="forgottenPasswordInput" type="text" placeholder="Email" ref={emailInputRef} autoComplete="username"
          value={email} onChange={handleUpdateEmail}></input>
        <input className="buttonPrimaryAction" type="submit" value="Demande de réinitialisation"/>
      </form>
    </Modal>
  );
})
