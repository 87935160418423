import { AuthContext } from '../Auth'
import React from 'react'
import { withRouter } from 'react-router'
import { getPublicImageSrc } from '../helperFunctions'
import Modal from './Modal.component'

export default withRouter(function SizeGuideModal({ ...props }) {
  const { isDarkTheme, checkStatus } = React.useContext(AuthContext);
  /* Force checkStatus to update the value of isDarkTheme  */
  React.useEffect(() => {
    checkStatus();
  }, []);

  /* Handle product deletion logic */
  const onConfirmAction = (e) => {
    e.preventDefault();
    props.history.goBack();
  };

  return (
    <Modal className="sizeGuideModalBox">
      <div className="sizeGuideText">
        <h1>Guide des tailles</h1>
        {isDarkTheme ?
          <img className="sizeGuideSvg" src={getPublicImageSrc("about/Guide tailles TFonce.png")} alt="Guide des tailles" /> :
          <img className="sizeGuideSvg" src={getPublicImageSrc("about/Guide tailles TClair.png")} alt="Guide des tailles" />
        }
      </div>
      <div className="orderActionsLayout" style={{ width: "auto", marginBottom: "15px", justifyContent: "center" }}>
        <div className="orderActionButton buttonConfirm" onClick={onConfirmAction} role="button" style={{ margin: "0 5px" }}>Ok</div>
      </div>
    </Modal>
  );
})
