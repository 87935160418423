import React from 'react'
import MessageBox from './MessageBox.component'

/*==============================================================================
Main component
==============================================================================*/
export default function Modal({ msgBoxData, backgroundClassName="backgroundBlur", ...props }) {
  /* Use a ref to get the position of the component under which to slide */
  const modalRef = React.useRef(null);

  return (
    <div className={backgroundClassName}>
      <div className={`modalWindow ${props.className}`} ref={modalRef} >
        {props.children}
      </div>
      {msgBoxData!==undefined && <MessageBox msgBoxData={msgBoxData} componentRef={modalRef} />}
    </div>
  );
}
