import { AuthContext } from '../Auth'
import React from 'react'
import { withRouter } from 'react-router'
import Modal from './Modal.component'
import { useEnterText } from '../hooks/asyncHooks'
import { promiseFetch } from '../helperFunctions'
import c from '../Constants'

const url = process.env.REACT_APP_URL;

/*==============================================================================
Helper function emailModificationLogic
==============================================================================*/
function emailModificationLogic(email, currentEmail, setMsgBoxData, clearFields) {
  /* Handle function to change email */
  const onSubmitModifyEmail = (e) => {
    e.preventDefault();
    /* → We verify that the form values are correct */
    const emailRegex = /\S+@\S+\.\S+/;
    if (emailRegex.test(email) === false) {
      setMsgBoxData({ text: "Veuillez entrer un email valide", classes: ["colorError"] });
    } else if (email !== currentEmail) {
      /* → We change the email */
      promiseFetch(`${url}/api/users/changeEmail`, {
        method: 'POST',
        headers: { "Content-Type": "application/json; charset=utf-8" },
        body: JSON.stringify({ email: email })
      })
        .then(res => {
          if (res.status===299) {
            setMsgBoxData({ text: "Cet email est associé à un autre compte", classes: ["colorError"] });
          } else {
            /* → We display a validation text and clear fields after 1.5sec */
            setMsgBoxData({ text: "Email correctement modifié", classes: ["colorSuccess"] });
            window.setTimeout(() => {
              clearFields();
            }, c.MSG_TIMEOUT_FAST);
          }
        })
    } else {
      setMsgBoxData({ text: "Veuillez choisir un email différent", classes: ["colorError"] });
    }
  };
  return onSubmitModifyEmail;
}

/*==============================================================================
Helper function passwordModificationLogic
==============================================================================*/
function passwordModificationLogic(currentPassword, newPassword, confirmation, setMsgBoxData, clearFields) {
  /* Handle function to change password */
  const onSubmitModifyPassword = (e) => {
    e.preventDefault();
    /* → We verify that the form values are correct */
    if (currentPassword.length===0) {
      setMsgBoxData({ text: "Veuillez entrer votre mot de passe actuel", classes: ["colorError"] });
    } else if (newPassword.length===0) {
      setMsgBoxData({ text: "Veuillez entrer votre nouveau mot de passe", classes: ["colorError"] });
    } else if (newPassword.length < 7) {
      setMsgBoxData({ text: "Le mot de passe doit contenir au moins 7 caractères", classes: ["colorError"] });
    } else if (confirmation.length===0) {
      setMsgBoxData({ text: "Veuillez confirmer le nouveau mot de passe", classes: ["colorError"] });
    } else if (newPassword!==confirmation) {
      setMsgBoxData({ text: "Le mot de passe de confirmation est différent", classes: ["colorError"] });
    } else if (currentPassword===newPassword) {
      setMsgBoxData({ text: "Le nouveau mot de passe est identique à l'actuel", classes: ["colorError"] });
    } else {
      /* → We verify the current password */
      promiseFetch(`${url}/api/users/verifypwd`, {
        method: 'POST',
        headers: { "Content-Type": "application/json; charset=utf-8" },
        body: JSON.stringify({ password: currentPassword })
      })
        .then(res => {
          if (res.status===200) {
            /* → We change the password */
            promiseFetch(`${url}/api/users/changepwd`, {
              method: 'POST',
              headers: { "Content-Type": "application/json; charset=utf-8" },
              body: JSON.stringify({ password: newPassword })
            })
              .then(res => {
                if (res.status===200) {
                  /* → We display a validation text and clear fields after 1.5sec */
                  setMsgBoxData({ text: "Mot de passe modifié", classes: ["colorSuccess"] });
                  window.setTimeout(() => {
                    clearFields();
                  }, c.MSG_TIMEOUT_FAST);
                } else {
                  setMsgBoxData({ text: "Impossible de changer le mot de passe", classes: ["colorError"] });
                }
              })
          } else if (res.status===403) {
            setMsgBoxData({ text: "Le mot de passe actuel est incorrect", classes: ["colorError"] });
          }
        })
    }
  };
  return onSubmitModifyPassword;
}

/*==============================================================================
Component DeleteAccountConfirmationModal
==============================================================================*/
const DeleteAccountConfirmationModal = withRouter(({ ...props }) => {
  /* Auth */
  const { deleteAccount } = React.useContext(AuthContext);
  /* Message box data */
  const [msgBoxData, setMsgBoxData] = React.useState(null);
  /* Hooks to handle fields */
  const [password, updatePassword] = useEnterText();
  const handleUpdatePassword = (text) => { setMsgBoxData(null); updatePassword(text); };

  /* Handle account deletion logic */
  const onSubmitDeleteProfile = (e) => {
    e.preventDefault();
    /* → We verify that the form values are correct */
    if (password.length===0) {
      setMsgBoxData({ text: "Veuillez entrer votre mot de passe", classes: ["colorError"] });
    } else {
      /* → We verify the current password */
      promiseFetch(`${url}/api/users/verifypwd`, {
        method: 'POST',
        headers: { "Content-Type": "application/json; charset=utf-8" },
        body: JSON.stringify({ password: password })
      })
        .then(res => {
          if (res.status===200) {
            /* → We show account deletion confirmation message for 1.5seconds */
            setMsgBoxData({ text: "Votre compte a été supprimé", classes: ["colorSuccess"], isLoading: true });
            window.setTimeout(() => {
              /* → We delete the account */
              deleteAccount()
                .then(res => {
                  if (res.status===200) {
                    props.history.replace('/');
                  } else {
                    setMsgBoxData({ text: "Impossible de supprimer le compte", classes: ["colorError"] });
                  }
                })
            }, c.MSG_TIMEOUT_FAST);
          } else if (res.status===403) {
            setMsgBoxData({ text: "Le mot de passe est incorrect", classes: ["colorError"] });
          }
        })
    }
  };
  /* Force focus on the input text */
  const passwordInputRef = React.useRef(null);
  React.useEffect(() => {
    passwordInputRef.current!==null && passwordInputRef.current.focus();
  }, [passwordInputRef]);


  return ( //TODO: mettre dans le gestionnaire de modales
    <Modal className="modifyAccountAccountDeletionBox" msgBoxData={msgBoxData}>
      <p className="modifyAccountNotice" style={{ marginTop: "16px", fontSize: "12px" }}>
        Veuillez entrer votre mot de passe pour confirmer la suppression du compte.
      </p>
      <form onSubmit={onSubmitDeleteProfile}>
        <input className="modifyAccountInput" type="password" placeholder="Mot de passe" autoComplete="current-password"
          value={password} onChange={handleUpdatePassword} ref={passwordInputRef} />
        <input className="buttonPrimaryAction colorError" type="submit" value="Supprimer le compte" />
      </form>
    </Modal>
  );
});

/*==============================================================================
Main component
==============================================================================*/
export default withRouter(function ModifyAccountModal({ ...props }) {
  /* Message box data */
  const [msgBoxData, setMsgBoxData] = React.useState(null);
  /* Hooks to handle the form */
  const [email, updateEmail] = useEnterText();
  const [currentPassword, updateCurrentPassword] = useEnterText();
  const [newPassword, updateNewPassword] = useEnterText();
  const [confirmation, updateConfirmation] = useEnterText();
  const handleUpdateEmail = (text) => { setMsgBoxData(null); updateEmail(text); };
  const handleUpdateCurrentPassword = (text) => { setMsgBoxData(null); updateCurrentPassword(text); };
  const handleUpdateNewPassword = (text) => { setMsgBoxData(null); updateNewPassword(text); };
  const handleUpdateConfirmation = (text) => { setMsgBoxData(null); updateConfirmation(text); };

  /* Force focus on the input text */
  const emailInputRef = React.useRef(null);

  /* Get the user email to display as placeholder value */
  promiseFetch(`${url}/api/users/getEmail`)
    .then(res => {
      if (res.data!==null) {
        if (emailInputRef.current!==null) {
          emailInputRef.current.placeholder = res.data;
        }
      }
    })

  /* Handle email modification logic */
  const clearEmailFields = () => handleUpdateEmail("");
  const onSubmitModifyEmail = emailModificationLogic(email, emailInputRef.current && emailInputRef.current.placeholder, setMsgBoxData, clearEmailFields);

  /* Handle password modification logic */
  const clearPasswordFields = () => {
    setMsgBoxData(null);
    updateCurrentPassword("");
    updateNewPassword("");
    updateConfirmation("");
  };
  const onSubmitModifyPassword = passwordModificationLogic(currentPassword, newPassword, confirmation, setMsgBoxData, clearPasswordFields);

  /* Handle account deletion logic */
  const [deleteAccountConfirmationModal, setDeleteAccountConfirmationModal] = React.useState(null);
  const onSubmitDeleteProfile = (e) => {
    e.preventDefault();
    setDeleteAccountConfirmationModal(<DeleteAccountConfirmationModal />)
  };

  return (
    <>
      <Modal className="modifyAccountBox" msgBoxData={msgBoxData}>
        <h1 className="modalTitle">Modification du compte</h1>
        <h2 className="modifyAccountTitleSub">Email</h2>
        <form onSubmit={onSubmitModifyEmail}>
          <input className="modifyAccountInput" type="text" placeholder="Nouvel email" ref={emailInputRef} autoComplete="username"
            value={email} onChange={handleUpdateEmail} />
          <input className="buttonPrimaryAction" type="submit" value="Changer l'email" />
        </form>
        <hr></hr>
        <h2 className="modifyAccountTitleSub">Mot de passe</h2>
        <form onSubmit={onSubmitModifyPassword}>
          <input className="modifyAccountInput" type="password" placeholder="Mot de passe actuel" ref={null} autoComplete="current-password"
            value={currentPassword} onChange={handleUpdateCurrentPassword} />
          <input className="modifyAccountInput" type="password" placeholder="Nouveau mot de passe" ref={null} autoComplete="new-password"
            value={newPassword} onChange={handleUpdateNewPassword} />
          <input className="modifyAccountInput" type="password" placeholder="Confirmation" ref={null} autoComplete="new-password"
            value={confirmation} onChange={handleUpdateConfirmation} />
          <input className="buttonPrimaryAction" type="submit" value="Changer le mot de passe" />
        </form>
        <hr></hr>
        <h2 className="modifyAccountTitleSub modifyAccountCenter">Attention</h2>
        <p className="modifyAccountNotice">
          La suppression du compte est irréversible et entraînera la perte de toutes vos données.
          Pour continuer d'accéder aux méditations il est nécessaire de noter les codes des bracelets, de recréer un compte et de les ajouter à nouveau manuellement.
        </p>
        <div className="buttonPrimaryAction colorBorderError" onClick={onSubmitDeleteProfile} role="button">Supprimer le compte</div>
      </Modal>
      {deleteAccountConfirmationModal}
    </>
  );
});
