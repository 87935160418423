import { ModalContext } from '../Modal'
import React from 'react'
import { withRouter } from 'react-router-dom'
import MessageBox from '../components/MessageBox.component'
import { OrderSubItem } from '../components/OrdersModal.component'
import { promiseFetch, floatToPrice, getMoneticoDate } from '../helperFunctions'
import { useEnterText } from '../hooks/asyncHooks'
import LoadingSpinner from '../components/LoadingSpinner.component'
import c from '../Constants'

/* Constants */
const url = process.env.REACT_APP_URL;

/*------------------------------------------------------------------------------
Hook useFilterOrders
------------------------------------------------------------------------------*/
function useFilterOrders(ordersData, currentIndex) {
  const [filteredOrdersData, setFilteredOrdersData] = React.useState(null);
  React.useEffect(() => {
    if (ordersData) {
      /* "Tous" selected */
      if (currentIndex===0) {
        setFilteredOrdersData(ordersData);
      } else {
        const filterStatus = Object.keys(c.ORDER_STATUS_ATTR)[currentIndex-1];
        setFilteredOrdersData(ordersData.filter(order => order.status===filterStatus));
      }
    }
  }, [ordersData, currentIndex]);
  return filteredOrdersData;
}

/*------------------------------------------------------------------------------
Hook useFetchOrders
------------------------------------------------------------------------------*/
function useFetchOrders(setMsgBoxData) {
  const [orders, setOrders] = React.useState(null);
  const [shouldRefresh, setShouldRefresh] = React.useState(true);
  React.useEffect(() => {
    if (shouldRefresh) {
      setShouldRefresh(false);
      promiseFetch(`${url}/api/orders`)
        .then(res => {
          if (res.status===200 && res.data) {
            setOrders(res.data);
          } else {
            setMsgBoxData({ text: "Impossible de récupérer les commandes", classes: ["colorError"] });
          }
        })
    }
  }, [shouldRefresh]);
  return [orders, setShouldRefresh];
}

/*==============================================================================
Component StatusIndicator
==============================================================================*/
function StatusIndicator({ status, isCritical, isExpired }) {
  const style = {
    color: c.ORDER_STATUS_ATTR[status].color,
    border: `1px solid ${c.ORDER_STATUS_ATTR[status].color}`
  };
  const criticalStyle = {
    color: "#fff",
    border: `1px solid #fff`
  };

  return (
    <div className="orderEntryItemCellStatus" style={isCritical && !isExpired ? criticalStyle : style}>
      {c.ORDER_STATUS_ATTR[status].tag}
    </div>
  );
}

/*------------------------------------------------------------------------------
Helper
------------------------------------------------------------------------------*/
function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

/*------------------------------------------------------------------------------
Helper
------------------------------------------------------------------------------*/
function getRemainingTime(dateFrom) {
  const remaining = (addDays(dateFrom, c.DAYS_BEFORE_CANCELLING) - Date.now()) / 86400000;
  const remainingDays = Math.floor(remaining);
  const remainingHours = Math.floor((remaining - remainingDays) * 24);
  const text = `${remainingDays===0 ? "" : `${remainingDays} jours `}${remainingHours}h`;
  return [text, remainingDays<=c.REMAINING_DAYS_CRITICAL, remaining<=0.0];
}

/*------------------------------------------------------------------------------
Helper
------------------------------------------------------------------------------*/
function getItemsAmount(items) {
  let itemsAmount = 0;
  items.forEach(item => {
    const extrasLinkedLen = (item.extrasLinked ? item.extrasLinked.filter(x => x).length : 0);
    const extrasSelectedLen = (item.extrasSelected ? item.extrasSelected.filter(x => x).length : 0);
    itemsAmount += (extrasLinkedLen + extrasSelectedLen + 1) * item.quantity;
  })
  return itemsAmount;
}

/*==============================================================================
Component OrdersComponents
==============================================================================*/
function OrdersComponents({ orderData }) {
  return (
    <div className="orderPageSummary" style={{ paddingTop: "20px", width: "580px" }}>
      {orderData.items.map((item, j) => {
        return <OrderSubItem key={j} orderItem={item} />;
      })}
      <p className="productPackageSummaryTotal" style={{ margin: "0px 0px 10px" }}>
        Livraison standard
        <span className="productPackageSummaryTotalAmount">{orderData.deliveryFee===0 ? 'Offerte' : '+'+floatToPrice(orderData.deliveryFee)}</span>
      </p>
      <p className="productPackageSummaryTotal productPackageSummaryTotalAlt">
        Total
        <span className="productPackageSummaryTotalAmount">{floatToPrice(orderData.total)}</span>
      </p>
    </div>
  );
}

/*==============================================================================
Component OrderRecipient
==============================================================================*/
function OrderRecipient({ orderData }) {
  return (
    <div className="orderEntryItemExpandedRecipient">
      <div className="recipientLineLayout">
        <h3 className="recipientName">{orderData.recipient.firstname} {orderData.recipient.lastname}</h3>
        <a className="recipientLink" href={`mailto:${orderData.recipient.email}`}>{orderData.recipient.email}</a>
      </div>
      <p className="recipientLineLayout">
        <span>{orderData.recipient.address}</span>
        <a className="recipientLink" href={`https://www.monetico-services.com/fr/client/Paiement/Paiement_PaiementsJour.aspx?tpe_id=2960901`} rel="noreferrer" target="_blank">
          Vers tableau de bord Monetico
        </a>
      </p>
      <p className="recipientLineLayout">
        <span>{orderData.recipient.postcode}, {orderData.recipient.city}</span>
        {orderData.trackingNumber==="none" ?
          <span>/</span> :
          <span>{orderData.trackingNumber}</span>
        }
      </p>
    </div>
  );
}

/*==============================================================================
Component OrderActions
==============================================================================*/
const OrderActions = withRouter(({ orderData, doRefresh, isExpired, setMsgBoxData, ...props }) => {
  const { setModalProps } = React.useContext(ModalContext);
  /* Hooks */
  const [trackingNumber, updateTrackingNumber] = useEnterText();
  const handleUpdateTrackingNumber = (text) => { setMsgBoxData(null); updateTrackingNumber(text); };

  const onClickConfirmOrder = (e) => {
    e.preventDefault();
    if (isExpired) {
      setMsgBoxData({ text: "La commande est expirée, vous ne l'avez pas confirmée dans le délai imparti. Veuillez envoyer un email au client pour l'en informer.", classes: ["colorError"] });
    } else {
      /* Confirms the transaction, changes the status of the command to "En préparation" and send a notifiation email to client */
      /* → Request to server to update the order */
      promiseFetch(`${url}/api/orders/confirmAccepted`, {
        method: 'POST',
        headers: { "Content-Type": "application/json; charset=utf-8" },
        body: JSON.stringify({
          orderId: orderData.id,
          orderDate: getMoneticoDate(orderData.date),
          date: getMoneticoDate(),
          total: `${orderData.total}EUR`,
          email: orderData.recipient.email
        })
      })
        .then(res => {
          if (res.status===200 && res.data) {
            setMsgBoxData({ text: "Paiement validé et commande correctement modifiée", classes: ["colorSuccess"], isLoading: true });
            window.setTimeout(() => {
              setMsgBoxData(null);
              doRefresh(true);
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }, c.MSG_TIMEOUT_FAST);
          } else if (res.status===299) {
            setMsgBoxData({ text: res.data, classes: ["colorError"] });
          } else {
            setMsgBoxData({ text: "Une erreur inconnue est survenue", classes: ["colorError"] });
          }
        })
    }
  };

  const onClickCancelOrder = (e) => {
    e.preventDefault();
    /* Open the cancel order modal to validate with refusal message */
    setModalProps({ orderData: orderData, doRefresh: doRefresh });
    props.history.push('#cancelorder');
  };

  const onClickExpiredProceed = (e) => {
    e.preventDefault();
    /* The callback for confirmation modal */
    const nextCallback = (data, props, setMsgBoxData) => {
      /* → We change the status of the order in the DB */
      promiseFetch(`${url}/api/orders/setOrderStatus`, {
        method: 'POST',
        headers: { "Content-Type": "application/json; charset=utf-8" },
        body: JSON.stringify({
          orderId: orderData.id,
          status: "Expiré",
        })
      })
        .then(res => {
          if (res.status===200 && res.data) {
            setMsgBoxData({ text: "Commande correctement modifiée", classes: ["colorSuccess"], isLoading: true });
            window.setTimeout(() => {
              setMsgBoxData(null);
              doRefresh(true);
              props.history.goBack();
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }, c.MSG_TIMEOUT_FAST);
          } else {
            setMsgBoxData({ text: "Impossible de modifier la commande", classes: ["colorError"] });
          }
        })
    };
    /*  */
    setModalProps({ next: nextCallback, buttonCancelText: "Non", buttonConfirmText: "Oui",
      text: <>
          <b>Avez vous envoyé un email au client pour lui informer de l'annulation de sa commande ?</b><br/><br/>
          En confirmant, cette commande sera classée mais vous pourrez toujours avoir accès à ses informations dans l'onglet "Expirés" de la page admin des commandes.
        </>
      });
    props.history.push('#expiredproceed');
  };

  const onClickSendConfirmationOrder = (e) => {
    /* Changes the status of the command to "Envoyé", set the trackingNumber and send a notifiation email to client */
    e.preventDefault();
    if (trackingNumber.length < 11 || trackingNumber.length > 15) {
      setMsgBoxData({ text: "Le numéro de suivi doit faire entre 11 et 15 caractères", classes: ["colorInfo"] });
    } else {
      /* → Request to server to update the order */
      promiseFetch(`${url}/api/orders/confirmSent`, {
        method: 'POST',
        headers: { "Content-Type": "application/json; charset=utf-8" },
        body: JSON.stringify({
          orderId: orderData.id,
          recipient: orderData.recipient,
          trackingNumber: trackingNumber
        })
      })
        .then(res => {
          if (res.status===200 && res.data) {
            setMsgBoxData({ text: "Commande correctement modifiée", classes: ["colorSuccess"], isLoading: true });
            window.setTimeout(() => {
              setMsgBoxData(null);
              doRefresh(true);
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }, c.MSG_TIMEOUT_FAST);
          } else {
            setMsgBoxData({ text: "Impossible de modifier la commande", classes: ["colorError"] });
          }
        })
    }
  };

  return (
    <>
      {isExpired ?
        <>
          <p className="orderActionsNotice">
            La commande est expirée, vous ne l'avez pas confirmée dans le délai imparti. Veuillez envoyer un email au client pour l'en informer puis veuillez cliquer sur le bouton "Procéder" pour classer cette commande.
          </p>
          <div className="orderActionsLayout">
            <div className="orderActionButton buttonConfirm" onClick={onClickExpiredProceed} role="button" style={{ margin: "0 5px" }}>Procéder</div>
          </div>
        </> :
        <>
          {orderData.status==="En attente" &&
            <div className="orderActionsLayout">
              <div className="orderActionButton buttonReject" onClick={onClickCancelOrder} role="button" style={{ margin: "0 5px" }}>Refuser</div>
              <div className="orderActionButton buttonConfirm" onClick={onClickConfirmOrder} role="button" style={{ margin: "0 5px" }}>Valider la commande</div>
            </div>
          }
          {orderData.status==="En préparation" &&
            <form className="orderActionsLayout" onSubmit={onClickSendConfirmationOrder}>
              <label className="orderActionLabel" htmlFor={`trackingNumber-${orderData.id}`}>Numéro de suivi</label>
              <input className="orderActionTextField" type="text" placeholder="Ex : 6Q01929938641"
                value={trackingNumber} onChange={handleUpdateTrackingNumber} id={`trackingNumber-${orderData.id}`} />
              <input className="orderActionButton buttonConfirm" type="submit" value="Confirmer l'envoi de la commande" style={{ margin: "0 5px 0 10px" }} />
            </form>
          }
        </>
      }
    </>
  );
})

/*==============================================================================
Component OrderEntryItem
==============================================================================*/
function OrderEntryItem({ orderData, zIndex, doRefresh, ...props }) {
  /* Hooks */
  const [msgBoxData, setMsgBoxData] = React.useState(null);
  const msgBoxAnchorRef = React.useRef(null);
  /* Computed variables */
  const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
  const itemsAmount = getItemsAmount(orderData.items);
  const orderingDate = new Date(orderData.date);
  const [remainingTime, isTimeCritical, isTimeExpired] = getRemainingTime(orderingDate);
  // const isInProcess = (orderData.status==="En attente" || orderData.status==="En préparation");
  const isInProcess = (orderData.status==="En attente");
  const isCritical = (isInProcess && isTimeCritical);
  const isExpired = (isInProcess && isTimeExpired);
  const criticalStyle = {
    color: "#fff",
    background: "#E22036"
  };

  /* Resets msgBoxData at unmount */
  React.useEffect(() => {
    return () => { setMsgBoxData(null); };
  }, []);

  const itemExpandedRef = React.useRef(null);
  React.useEffect(() => {
    if (orderData && itemExpandedRef.current) {
      itemExpandedRef.current.style.display = "none";
    }
  }, [orderData, itemExpandedRef]);
  /* onClick callback function */
  const onClick = (e) => {
    if (itemExpandedRef.current) {
      itemExpandedRef.current.style.display = (itemExpandedRef.current.style.display==="none" ? "flex" : "none");
      setMsgBoxData(null);
    }
  };

  return (
    <div className="orderEntryItemBox" style={{ zIndex: zIndex }}>
      <div className="orderEntryItemHeader" style={isCritical && !isExpired ? criticalStyle : {}} onClick={onClick} role="button">
        <p className="orderEntryItemCell" style={{ width: "100px" }}><b>{orderData.id}</b></p>
        <p className="orderEntryItemCell" style={{ width: "100px" }}><b>{floatToPrice(orderData.total)}</b></p>
        <p className="orderEntryItemCell" style={{ width: "100px" }}>{`${itemsAmount} ${(itemsAmount===1?'produit':'produits')}`}</p>
        <p className="orderEntryItemCell" style={{ width: "160px" }}>{(isExpired ? "Expirée" : (isInProcess && `${remainingTime} restants`))}</p>
        <p className="orderEntryItemCell" style={{ width: "160px" }}>{orderingDate.toLocaleDateString('fr-FR', dateOptions)}</p>
        <div className="orderEntryItemCell"><StatusIndicator status={orderData.status} isCritical={isCritical} isExpired={isExpired}/></div>
      </div>
      <div className="orderEntryItemExpanded" ref={itemExpandedRef} style={{ display: "none" }}>
        <OrdersComponents orderData={orderData} />
        <OrderRecipient orderData={orderData} />
        <OrderActions orderData={orderData} doRefresh={doRefresh} isExpired={isExpired} setMsgBoxData={setMsgBoxData} />
      </div>
      <div className="orderEmptyMsgBoxAnchor" ref={msgBoxAnchorRef}></div>
      {msgBoxData!==undefined && <MessageBox msgBoxData={msgBoxData} componentRef={msgBoxAnchorRef} mode="absolute2" />}
    </div>
  );
}

/*==============================================================================
Component OrderEntriesLeftMenu
==============================================================================*/
function OrderEntriesLeftMenu({ currentIndex, setCurrentIndex, ...props }) {
  /* Hooks */
  const onClick = (e, index) => {
    setCurrentIndex(index);
  }

  return (
    <nav className="orderEntriesLeftMenu">
      <ul>
        {["Tous", ...Object.values(c.ORDER_STATUS_ATTR).map(x => x.category)].map((label, i) => {
          const style = (currentIndex===i ? { fontWeight: 700 } : {});
          return <li key={i} title={label} onClick={(e) => onClick(e, i)} style={style} role="button">
            {label}
          </li>;
        })}
      </ul>
    </nav>
  );
}

/*==============================================================================
Main component
==============================================================================*/
export default function AdminOrdersPage({ setMsgBoxData, ...props }) {
  /* Hooks */
  const [currentIndex, setCurrentIndex] = React.useState(0);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentIndex]);
  const [ordersData, doRefresh] = useFetchOrders(setMsgBoxData);
  const filteredOrdersData = useFilterOrders(ordersData, currentIndex);

  return (
    <>
      {filteredOrdersData ?
        <>
          <OrderEntriesLeftMenu currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} />
          <div className="orderEntriesLayout">
            {filteredOrdersData.map((orderData, i) => {
              return <OrderEntryItem key={i} orderData={orderData} zIndex={filteredOrdersData.length - i} doRefresh={doRefresh} />;
            })}
          </div>
        </> :
        <LoadingSpinner />
      }
    </>
  );
}