import { AuthContext } from '../Auth'
import React from 'react'
import { AdminHeader } from '../components/Header.component'
import MessageBox from '../components/MessageBox.component'
import AdminOrdersPage from '../components/AdminOrders.component'
import AdminProductsPage from '../components/AdminProducts.component'
import AdminMeditationsPage from '../components/AdminMeditations.component'
import AdminGroupsPage from '../components/AdminGroups.component'

import "./AdminPage.css"

const HASH_COMPONENTS = {
  '#orders': AdminOrdersPage,
  '#meditations': AdminMeditationsPage,
  '#groups': AdminGroupsPage,
  // '#packages': AdminPackagesPage,
  '#products': AdminProductsPage,
};

/* Helper functions */
/* Build the components of the given object with the params */
function buildComponentsFromObject(components, params) {
  return Object.fromEntries(Object.entries(components).map(x => [x[0], React.createElement(x[1], params)]));
}

/*------------------------------------------------------------------------------
Hook usePagesPosition
------------------------------------------------------------------------------*/
function usePagesPosition(props, setCurrentPage, setMsgBoxData) {
  /* Using AuthContext */
  const { logout } = React.useContext(AuthContext);
  const [pagesComponents] = React.useState(buildComponentsFromObject(HASH_COMPONENTS, { setMsgBoxData }));
  /* Callback */
  const callbackFunction = (idx, hashLocation) => {
    setHeaderSelected(idx);
    setCurrentPage(pagesComponents[hashLocation]);
    props.history.push(hashLocation);
  };
  /* Hooks */
  const [headerSelected, setHeaderSelected] = React.useState(0);
  const headerButtons = [
    { name: "Commandes", func: (idx) => callbackFunction(idx, '#orders') },
    { name: "Méditations", func: (idx) => callbackFunction(idx, '#meditations') },
    { name: "Groupes", func: (idx) => callbackFunction(idx, '#groups') },
    // { name: "Formules", func: (idx) => callbackFunction(idx, '#packages') },
    { name: "Produits", func: (idx) => callbackFunction(idx, '#products') },
    { name: "Se déconnecter", func: logout }
  ];
  /* Restore the correct position */
  React.useEffect(() => {
    if (props.history.location.hash && props.history.location.hash.length > 0) {
      const res = pagesComponents[props.history.location.hash];
      if (res!==undefined) {
        setCurrentPage(res);
        setHeaderSelected(Object.keys(pagesComponents).indexOf(props.history.location.hash));
      }
    } else if (props.history.location.hash==="") {
      props.history.replace('#orders');
    }
  }, [props.history.location.hash]);
  return [headerSelected, headerButtons];
}

/*==============================================================================
Main component
==============================================================================*/
export default function AdminPage({ ...props }) {
  /* Message Box data */
  const [msgBoxData, setMsgBoxData] = React.useState(null);
  const msgBoxAnchorRef = React.useRef(null);
  const [currentPage, setCurrentPage] = React.useState(null);
  const [headerSelected, headerButtons] = usePagesPosition(props, setCurrentPage, setMsgBoxData);

  return (
    <>
      <AdminHeader buttons={headerButtons} currentSelected={headerSelected} />
      {currentPage}
      <div className="orderEmptyMsgBoxAnchor" ref={msgBoxAnchorRef}></div>
      {msgBoxData!==undefined && <MessageBox msgBoxData={msgBoxData} componentRef={msgBoxAnchorRef} />}
    </>
  );
}
