import React from 'react'
import { withRouter } from 'react-router'

const url = process.env.REACT_APP_URL;

/*==============================================================================
Main component
==============================================================================*/
export default withRouter(function Footer({ className, ...props }) {
  /* Callbacks de requête de téléchargement des fichier */
  const handleDownloadManual = () => window.open(`${url}/download/manual`);
  const handleDownloadCatalog = () => window.open(`${url}/download/catalog`);
  /* Callbacks de mailto */
  const handleMailToContact = () => window.open(`mailto:synesthesia.fr@gmail.com`);
  /* Callback pour l'affichage des documents légaux */
  const onClickLegalNotice = (e) => {
    e.preventDefault();
    props.history.push('#legalnotice');
  };
  const onClickCgv = (e) => {
    e.preventDefault();
    props.history.push('#cgv');
  };

  return (
    <>
      <div className={className}>
        <div className="footerCategory">
          <div className="footerCategoryTitle">Contact</div>
          <div className="footerCategoryLink" onClick={handleMailToContact} role="button">synesthesia.fr@gmail.com</div>
        </div>
        <hr/>
        <div className="footerCategory">
          <div className="footerCategoryTitle">Informations légales</div>
          <div className="footerCategoryLink" onClick={onClickLegalNotice} role="button">Mentions légales</div>
          <div className="footerCategoryLink" onClick={onClickCgv} role="button">CGV</div>
        </div>
        <hr/>
        <div className="footerCategory">
          <div className="footerCategoryTitle">Téléchargements</div>
          <div className="footerCategoryLink" onClick={handleDownloadManual} role="button">Manuel d'utilisation</div>
          <div className="footerCategoryLink" onClick={handleDownloadCatalog} role="button">Catalogue</div>
        </div>
      </div>
    </>
  );
})

/*==============================================================================
Component FooterProfile
==============================================================================*/
export function FooterProfile({ className, ...props }) {
  /* Callbacks de requête de téléchargement des fichier */
  const handleDownloadManual = () => window.open(`${url}/download/manual`);
  const handleDownloadCatalog = () => window.open(`${url}/download/catalog`);

  return (
    <>
      <div className={className} style={{ padding: "10px 15px 15px" }}>
        <div className="footerCategory" style={{ alignItems: "center" }}>
          <div className="footerCategoryTitle">Téléchargements</div>
          <div className="footerCategoryLink" style={{ padding: "2px 0" }} onClick={handleDownloadManual} role="button">Manuel d'utilisation</div>
          <div className="footerCategoryLink" style={{ padding: "2px 0" }} onClick={handleDownloadCatalog} role="button">Catalogue</div>
        </div>
      </div>
    </>
  );
}
