import React from 'react'

import '../resources/loadingSpinner.css'

export default function LoadingSpinner({ className }) {
  return (
    <div className={className}>
      <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    </div>
  );
}

export function LoadingSpinnerSmall({ className }) {
  return (
    <div className={className}>
      <div className="lds-dual-ring"></div>
    </div>
  );
}