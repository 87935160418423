import React from 'react'
import { withRouter } from 'react-router'
import Modal from './Modal.component'
import { promiseFetch, getMoneticoDate } from '../helperFunctions'
import { useEnterText } from '../hooks/asyncHooks'
import c from '../Constants'

const url = process.env.REACT_APP_URL;

export default withRouter(function CancelOrderModal({ data, ...props }) {
  /* Message box data */
  const [msgBoxData, setMsgBoxData] = React.useState(null);
  /* Hooks to handle fields */
  const [text, updateText] = useEnterText();
  const handleUpdateText = (text) => { setMsgBoxData(null); updateText(text); };

  /* Handle account deletion logic */
  const onConfirmCancelOrder = (e) => {
    e.preventDefault();
    /* → Request to server to update the order */
    promiseFetch(`${url}/api/orders/confirmCancelled`, {
      method: 'POST',
      headers: { "Content-Type": "application/json; charset=utf-8" },
      body: JSON.stringify({
        orderId: data.orderData.id,
        orderDate: getMoneticoDate(data.orderData.date),
        date: getMoneticoDate(),
        total: `${data.orderData.total}EUR`,
        email: data.orderData.recipient.email,
        msg: text
      })
    })
      .then(res => {
        if (res.status===200 && res.data) {
          setMsgBoxData({ text: "Paiement annulé et commande correctement modifiée", classes: ["colorSuccess"], isLoading: true });
          window.setTimeout(() => {
            setMsgBoxData(null);
            data.doRefresh(true);
            /* → Redirection to /admin */
            props.history.replace(props.history.location.pathname);
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }, c.MSG_TIMEOUT_FAST);
        } else if (res.status===299) {
          setMsgBoxData({ text: res.data, classes: ["colorError"] });
        } else {
          setMsgBoxData({ text: "Une erreur inconnue est survenue", classes: ["colorError"] });
        }
      })
  };
  const onCancelAction = (e) => {
    e.preventDefault();
    /* We terminate the modal, redirection to /admin */
    props.history.replace(props.history.location.pathname);
  }
  /* Force focus on the input text */
  const textInputRef = React.useRef(null);
  React.useEffect(() => {
    textInputRef.current!==null && textInputRef.current.focus();
  }, [textInputRef]);

  return (
    <Modal className="cancelOrderModalBox" msgBoxData={msgBoxData}>
      <p className="cancelOrderNotice">
        Veuillez renseigner la raison de l'annulation de la commande.<br/>
        Ce message apparaîtra dans l'email automatique de notification envoyé au client.
      </p>
      <textarea className="cancelOrderTextArea" type="textarea" placeholder=""
        value={text} onChange={handleUpdateText} ref={textInputRef} />
      <div className="orderActionsLayout" style={{ width: "auto", marginBottom: "15px" }}>
        <div className="orderActionButton buttonConfirm" onClick={onCancelAction} role="button">Annuler</div>
        <div className="orderActionButton buttonReject" onClick={onConfirmCancelOrder} role="button" style={{ margin: "0 0 0 10px" }}>Confirmer</div>
      </div>
    </Modal>
  );
})
