import { ModalContext } from '../Modal'
import React from 'react'
import { withRouter } from 'react-router-dom'
import Switch from "react-switch";
import MessageBox from '../components/MessageBox.component'
import { promiseFetch, floatToPrice, getPublicImageSrc } from '../helperFunctions'
import { useEnterText } from '../hooks/asyncHooks'
import LoadingSpinner from '../components/LoadingSpinner.component'
import { isChrome } from 'react-device-detect'
import c from '../Constants'

/* Constants */
const url = process.env.REACT_APP_URL;

/*------------------------------------------------------------------------------
Hook useFilterProducts
------------------------------------------------------------------------------*/
function useFilterProducts(productsData, currentIndex) {
  const [filteredProductsData, setFilteredProductsData] = React.useState(null);
  React.useEffect(() => {
    if (productsData) {
      /* "Tous" selected */
      if (currentIndex===0) {
        setFilteredProductsData(productsData);
      } else if (currentIndex===Object.keys(c.PRODUCT_TYPE_ATTR).length+1) {
        setFilteredProductsData(productsData.filter(product => product.disabled));
      } else {
        const filterType = Object.keys(c.PRODUCT_TYPE_ATTR)[currentIndex-1];
        setFilteredProductsData(productsData.filter(product => product.type===filterType||product.id===c.EMPTY_ID));
      }
    }
  }, [productsData, currentIndex]);
  return filteredProductsData;
}

/*------------------------------------------------------------------------------
Hook useFetchProducts
------------------------------------------------------------------------------*/
function useFetchProducts(setMsgBoxData) {
  const [products, setProducts] = React.useState(null);
  const [shouldRefresh, setShouldRefresh] = React.useState(true);
  React.useEffect(() => {
    if (shouldRefresh) {
      setShouldRefresh(false);
      promiseFetch(`${url}/api/products`)
        .then(res => {
          if (res.status===200 && res.data) {
            /* Sort by id */
            res.data.sort((first, second) => (''+first.id).localeCompare(second.id))
            /* Sort by category */
            res.data.sort((first, second) => c.PRODUCT_TYPE_ATTR[first.type].sortOrder-c.PRODUCT_TYPE_ATTR[second.type].sortOrder);
            setProducts(products => {
              /* On check si on avait un nouveau produit, on le garde si c'est le cas */
              const emptyProduct = (products ? products.find(x => x.id==='') : null);
              return (emptyProduct ? res.data.concat(emptyProduct) : res.data);
            });
          } else {
            setMsgBoxData({ text: "Impossible de récupérer les produits", classes: ["colorError"] });
          }
        });
    }
  }, [shouldRefresh]);
  return [products, setProducts, setShouldRefresh];
}

/*------------------------------------------------------------------------------
Hook useFetchGroups
------------------------------------------------------------------------------*/
function useFetchGroups(setMsgBoxData) {
  const [groups, setGroups] = React.useState(null);
  const [shouldRefresh, setShouldRefresh] = React.useState(true);
  React.useEffect(() => {
    if (shouldRefresh) {
      setShouldRefresh(false);
      promiseFetch(`${url}/api/groups`)
        .then(res => {
          if (res.status===200 && res.data) {
            setGroups(res.data);
          } else {
            setMsgBoxData({ text: "Impossible de récupérer les groupes", classes: ["colorError"] });
          }
        });
    }
  }, [shouldRefresh]);
  return [groups, setShouldRefresh];
}

/*------------------------------------------------------------------------------
Hook useFetchMeditations
------------------------------------------------------------------------------*/
function useFetchMeditations(setMsgBoxData) {
  const [meditations, setMeditations] = React.useState(null);
  const [shouldRefresh, setShouldRefresh] = React.useState(true);
  React.useEffect(() => {
    if (shouldRefresh) {
      setShouldRefresh(false);
      promiseFetch(`${url}/api/meditations`)
        .then(res => {
          if (res.status===200 && res.data) {
            setMeditations(res.data);
          } else {
            setMsgBoxData({ text: "Impossible de récupérer les méditations", classes: ["colorError"] });
          }
        })
    }
  }, [shouldRefresh]);
  return [meditations, setShouldRefresh];
}

/*------------------------------------------------------------------------------
Hook useHandleSelectableQuantity
------------------------------------------------------------------------------*/
function useHandleSelectableValue(value, updateSelected) {
  const [selectedValue, setSelectedValue] = React.useState(value);
  const handleSelectedValue = (e) => {
    setSelectedValue(e.target.value);
    updateSelected && updateSelected(e.target.value);
  }
  React.useLayoutEffect(() => {
    if (value!==null) {
      setSelectedValue(value);
    }
  }, [value]);
  return [selectedValue, handleSelectedValue];
}

/*------------------------------------------------------------------------------
Hook useGetFilteredGroups
------------------------------------------------------------------------------*/
function useGetFilteredGroups(groupsData, productType) {
  const [filteredGroups, setFilteredGroups] = React.useState(null);
  React.useEffect(() => {
    if (groupsData && productType) {
      setFilteredGroups(groupsData.filter(x => x.type===productType));
    }
  }, [groupsData, productType]);
  return filteredGroups;
}

/*------------------------------------------------------------------------------
Hook useGetSelectableGroups
------------------------------------------------------------------------------*/
function useGetSelectableGroups(groupsData) {
  const [selectableGroups, setSelectableGroups] = React.useState(null);
  React.useEffect(() => {
    if (groupsData) {
      let groups = groupsData;
      groups = groups.sort((a, b) => parseInt(a.number)-parseInt(b.number)).map(x => `${x.number} - ${x.name}`);
      setSelectableGroups([""].concat(groups));
    }
  }, [groupsData]);
  return selectableGroups;
}

/*------------------------------------------------------------------------------
Hook useGetProductGroups
------------------------------------------------------------------------------*/
function useGetProductGroups(productData, productType, groupsData) {
  /* Hooks */
  const [selectedGroups, setSelectedGroups] = React.useState({});
  const filteredGroups = useGetFilteredGroups(groupsData, productType);
  const selectableGroups = useGetSelectableGroups(filteredGroups);
  React.useEffect(() => {
    if (filteredGroups) {
      /* Getting the group number for the product */
      const arr = productData.id.split('-');
      let productGroup = (arr.length > 1 ? arr[0].slice(1) : "");
      /* Building the initial groups */
      let groups = {};
      filteredGroups.forEach(group => {
        /* If we're changing the type we want a blank slate */
        if (productType!==productData.type) {
          groups = { [c.EMPTY_KEY]: "" };
        /* Otherwise default init */
        } else if (group.ids.indexOf(productGroup)!==-1 || (Number.isNaN(parseInt(productGroup)) && group.number===productGroup)) {
          groups = {...groups, [group.number]: `${group.number} - ${group.name}`};
        }
      });
      setSelectedGroups(groups);
    }
  }, [filteredGroups]);
  /* Callbacks */
  const onAddGroup = (e) => {
    e && e.preventDefault();
    setSelectedGroups(selectedGroups => ({...selectedGroups, [c.EMPTY_KEY]: ""}));
  };

  const onRemoveGroup = (e, key) => {
    e && e.preventDefault();
    setSelectedGroups(selectedGroups => {
      const res = { ...selectedGroups }; // Clone the object so React knows there's been a change.
      delete res[key];
      if (Object.values(res).length===0) {
        res[c.EMPTY_KEY] = "";
      }
      return res;
    });
  };

  const onUpdateGroup = (key, value) => {
    setSelectedGroups(selectedGroups => {
      let newkey = (value!=="" ? value.split(' - ')[0] : c.EMPTY_KEY);
      if (key!==newkey) {
        const res = { ...selectedGroups, [newkey]: value };
        delete res[key];
        return res;
      }
      return selectedGroups;
    });
  };

  return { selectedGroups, selectableGroups, onAddGroup, onRemoveGroup, onUpdateGroup };
}

/*------------------------------------------------------------------------------
Hook useGetProductNumbers
------------------------------------------------------------------------------*/
function useGetProductNumbers(productData, num=10) {
  const [selectedNumber, setSelectedNumber] = React.useState("");
  const selectableNumbers = Array.from(Array(num).keys());
  selectableNumbers[0] = '';
  React.useEffect(() => {
    if (productData) {
      setSelectedNumber(parseInt(productData.id.split('-')[1]));
    }
  }, [productData]);
  /* Callback */
  const updateSelectedNumber = (value) => {
    setSelectedNumber(value);
  };
  return [selectedNumber, selectableNumbers, updateSelectedNumber];
}

/*==============================================================================
Component SelectionBox
==============================================================================*/
function SelectionBox({ name, value, choices, updateSelected, showNumbers=false, width=undefined }) {
  const [selectedValue, handleSelectedValue] = useHandleSelectableValue(value, updateSelected);
  return (
    <div className={!isChrome ? "selectionBox" : "selectionBoxChrome"} style={width ? { width: `${width}px`, margin: "0 4px 0 0" } : {}}>
      <p>{name}</p>
      <select name={name} onChange={handleSelectedValue} value={selectedValue}>
        {choices.map((x, i) => {
          const disp = (showNumbers ? `${i+1} - ${x}` : x);
          return <option value={disp} key={i} style={i===0?{ display: "none" }:{}}>{disp}</option>;
        })}
      </select>
    </div>
  );
}

/*==============================================================================
Component SelectionBoxList
==============================================================================*/
function SelectionBoxList({ name, values, choices, onAddItem, onRemoveItem, onUpdateItem, showNumbers=false }) {
  return (
    <>
      {Object.entries(values).map((value, idx) => {
        const key = value[0];
        /* Only display the choices that are not yet selected */
        let newchoices = choices.filter(choice => !Object.values(values).includes(choice)).concat(value[1]);
        newchoices=newchoices.sort((a, b) => (''+a).localeCompare(b));

        return <div style={{ display: "flex", flexDirection: "row" }} key={idx}>
          <SelectionBox name={idx===0?name:""} value={value[1]} choices={newchoices}
            updateSelected={(value) => onUpdateItem(key, value)} showNumbers={showNumbers} />
          {onRemoveItem && <div className="selectionBoxButton" onClick={(e) => onRemoveItem(e, key)} role="button">Retirer</div>}
        </div>
      })}
      {onAddItem && 
        <div className={!isChrome ? "selectionBox" : "selectionBoxChrome"} style={{ margin: '-5px 0 5px' }}>
          <p></p>
          <div className="selectionBoxButton" onClick={onAddItem} style={{ margin: "0" }} role="button">+ Ajouter</div>
        </div>
      }
    </>
  );
}

/*==============================================================================
Component PriceInput
==============================================================================*/
function PriceInput({ name, value, updateValue, style={}, symbol=undefined }) {
  return (
    <div className="adminProductPriceInputLayout" style={style}>
      <p>{name}</p>
      <input className="adminProductPriceInput" type="text" placeholder="29.00"
        value={value} onChange={updateValue} />
      {symbol && <p>{symbol}</p>}
    </div>
  );
}

/*==============================================================================
Component TextInput
==============================================================================*/
function TextInput({ name, value, updateValue, dkey=null, style={} }) {
  /* Submit callback */
  const updateInputValue = (e) => {
    e.preventDefault();
    if (dkey!==null) {
      updateValue(dkey, e.target.value);
    } else {
      updateValue(e.target.value);
    }
  };
  return (
    <div className="adminProductTextInputLayout" style={style}>
      <p>{name}</p>
      <input className="adminProductTextInput" type="text" value={value} onChange={updateInputValue} />
    </div>
  );
}

/*------------------------------------------------------------------------------
Hook useGetDescription
------------------------------------------------------------------------------*/
function useGetDescription(productDescription) {
  const [description, setDescription] = React.useState({});
  const [hasLoaded, setHasLoaded] = React.useState(false);
  const updateDescription = (key, value) => {
    setDescription(description => ({ ...description, [key]: value }));
  };
  React.useEffect(() => {
    if (productDescription!==null && productDescription!==undefined) {
      const res = {};
      productDescription.split('\n').filter(x => x).forEach(x => {
        const arr = x.split(' : ');
        if (arr.length > 1) {
          res[arr[0]] = arr[1];
        } else {
          res[c.DESCRIPTION_KEYS[0].desc] = x;
        }
      });
      setDescription(res);
      setHasLoaded(true);
    }
  }, [productDescription]);
  return [description, updateDescription, hasLoaded];
}

/*------------------------------------------------------------------------------
Hook useGetExtrasDescription
------------------------------------------------------------------------------*/
function useGetExtrasDescription(productDescription) {
  const [description, setDescription] = React.useState({});
  const [hasLoaded, setHasLoaded] = React.useState(false);
  const updateDescription = (key, value) => {
    setDescription(description => {
      description[key] = value;
      return [...description];
    });
  };
  React.useEffect(() => {
    if (productDescription!==null && productDescription!==undefined) {
      const res = productDescription.split('\n\n');
      setDescription(res);
      setHasLoaded(true);
    }
  }, [productDescription]);
  return [description, updateDescription, hasLoaded];
}

/*------------------------------------------------------------------------------
Hook useGetThemes
------------------------------------------------------------------------------*/
function useGetThemes(meditationsData, productData, selectedType) {
  const [selectedTheme, setSelectedTheme] = React.useState(productData.name);
  const [selectableThemes, setSelectableThemes] = React.useState(null);
  React.useEffect(() => {
    if (meditationsData && productData) {
      let meditations = meditationsData.filter(x => x.type===selectedType).sort((a, b) => parseInt(a.number)-parseInt(b.number))
      meditations = meditations.map(x => x.name);
      setSelectableThemes(meditations);
    }
  }, [meditationsData, productData, selectedType]);
  React.useEffect(() => {
    if (productData.name) {
      setSelectedTheme(productData.name);
    }
  }, [productData.name]);
  return [selectedTheme, setSelectedTheme, selectableThemes];
}

/*------------------------------------------------------------------------------
Hook useGetLinkedPids
------------------------------------------------------------------------------*/
function useGetLinkedPids(productsIds, productLinkedPids) {
  const [linkedPids, setLinkedPids] = React.useState(productLinkedPids);
  const [selectableLinkedPids, setSelectableLinkedPids] = React.useState(productsIds);
  React.useEffect(() => {
    if (productsIds) {
      setSelectableLinkedPids(productsIds);
    }
  }, [productsIds]);
  React.useEffect(() => {
    if (productLinkedPids) {
      if (productLinkedPids.length > 0) {
        const selected = productLinkedPids.map(linkedpid => productsIds.find(pid => pid.slice(0,6)===linkedpid));
        setLinkedPids(selected);
      } else {
        setLinkedPids({ [c.EMPTY_KEY]: "" });
      }
    }
  }, [productLinkedPids]);

  /* Callbacks */
  const onAddLinkedPid = (e) => {
    e && e.preventDefault();
    setLinkedPids(linkedPids => ({...linkedPids, [c.EMPTY_KEY]: ""}));
  };

  const onRemoveLinkedPid = (e, key) => {
    e && e.preventDefault();
    setLinkedPids(linkedPids => {
      const res = { ...linkedPids }; // Clone the object so React knows there's been a change.
      delete res[key];
      if (Object.values(res).length===0) {
        res[c.EMPTY_KEY] = "";
      }
      return res;
    });
  };

  const onUpdateLinkedPid = (key, value) => {
    setLinkedPids(linkedPids => {
      let newkey = (value!=="" ? value : c.EMPTY_KEY);
      if (key!==newkey) {
        const res = { ...linkedPids, [newkey]: value };
        delete res[key];
        return res;
      }
      return linkedPids;
    });
  };
  return [linkedPids, selectableLinkedPids, onAddLinkedPid, onRemoveLinkedPid, onUpdateLinkedPid];
}

/*------------------------------------------------------------------------------
Hook useGetProductIdBracelet
------------------------------------------------------------------------------*/
function useGetProductIdBracelet(productDataId, meditationsData, selectedTheme, selectedNumber) {
  const [productId, setProductId] = React.useState(productDataId);
  React.useEffect(() => {
    if (selectedTheme && meditationsData) {
      /* formatting the selectednumber */
      const snumber = (selectedNumber ? (selectedNumber<10? '0':'') + selectedNumber : c.EMPTY_KEY);
      /* formatting the number of the group of the meditation */
      let gnumber = c.EMPTY_KEY;
      const meditation = meditationsData.find(x => x.name===selectedTheme);
      if (meditation) {
        gnumber = (meditation.number<10? '0':'') + String(meditation.number);
      }
      setProductId(`#${gnumber}-${snumber}`);
    }
  }, [selectedTheme, selectedNumber, meditationsData]);
  return [productId, setProductId];
}

/*------------------------------------------------------------------------------
Hook useGetProductIdQuantique
------------------------------------------------------------------------------*/
function useGetProductIdQuantique(productDataId, meditationsData, selectedTheme, selectedGroups) {
  const [productId, setProductId] = React.useState(productDataId);
  React.useEffect(() => {
    if (selectedGroups && meditationsData) {
      /* formatting the number of the group of the meditation */
      let number = c.EMPTY_KEY;
      const meditation = meditationsData.find(x => x.name===selectedTheme);
      if (meditation) {
        /* HARDCODED */
        const num = Object.keys(selectedGroups)[0]==='VQ' ? (meditation.number>12?meditation.number-12:meditation.number) : meditation.number;
        number = (num<10? '0':'') + String(num);
      }
      setProductId(`#${Object.keys(selectedGroups)[0]}-${number}`);
    }
  }, [selectedTheme, selectedGroups, meditationsData]);
  return [productId, setProductId];
}

/*------------------------------------------------------------------------------
Hook useGetProductIdQuantique
------------------------------------------------------------------------------*/
function useGetProductIdExtras(productDataId, productDataType, selectedNumber) {
  const [productId, setProductId] = React.useState(productDataId);
  React.useEffect(() => {
    if (selectedNumber && productDataType) {
      let number = (selectedNumber<10? '0':'') + String(selectedNumber);
      setProductId(`#${c.PRODUCT_TYPE_ATTR[productDataType].abbrv}-${number}`);
    }
  }, [selectedNumber, productDataType]);
  return [productId, setProductId];
}

/*------------------------------------------------------------------------------
Hook useGetSelectedType
------------------------------------------------------------------------------*/
function useGetSelectedType(productType) {
  const [selectedType, setSelectedType] = React.useState(productType);
  React.useEffect(() => {
    if (productType) {
      setSelectedType(productType);
    }
  }, [productType]);
  const updateSelectedType = (value) => {
    setSelectedType(value);
  };
  return [selectedType, updateSelectedType];
}

/*------------------------------------------------------------------------------
Hook useGetImagesSrc
------------------------------------------------------------------------------*/
function useGetImagesSrc(productData) {
  /* ImageButton */
  const [selectedImageIdx, setSelectedImageIdx] = React.useState(0);
  const [imagesSrc, setImagesSrc] = React.useState({
    [c.IMAGES_SUBDIRS[0]]: productData.imageMain, [c.IMAGES_SUBDIRS[1]]: productData.imageSecond
  });
  React.useEffect(() => {
    setImagesSrc(imagesSrc => {
      return {
        ...imagesSrc,
        [c.IMAGES_SUBDIRS[0]]: getPublicImageSrc(productData.imageMain),
        [c.IMAGES_SUBDIRS[1]]: getPublicImageSrc(productData.imageSecond)
      };
    });
  }, [productData.imageMain, productData.imageSecond]);
  return {imagesSrc, setImagesSrc, selectedImageIdx, setSelectedImageIdx};
}

/*==============================================================================
Component ProductActionsBracelet
==============================================================================*/
function ImageSelectionButtons({ currentIndex, setCurrentIndex, ...props }) {
  const BUTTONS = 2;
  const itArray = new Array(BUTTONS).fill(0);
  const onClick = (i) => setCurrentIndex(i);
  return (
    <>
      <div className="addImageButtons">
        {itArray.map((_,i) => {
          return <div className={`addImageButton${i===currentIndex ? " addImageButtonSelected" : ""}`} key={i}
            onClick={() => onClick(i)} role="button">{i+1}{i<BUTTONS-1 && <hr/>}</div>;
        })}
      </div>
    </>
  );
}

/*------------------------------------------------------------------------------
Helper getImageFileRatio
------------------------------------------------------------------------------*/
async function getImageFileRatio(imageFile) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    /* Read the contents of Image File */
    reader.readAsDataURL(imageFile);
    reader.onload = function (e) {
      let image = new Image();
      image.onload = function () {
        resolve(`${this.width/this.height}`);
      };
      image.onerror = function () {
        reject(null);
      };
      image.src = e.target.result;
    };
  });
}

/*==============================================================================
Component ProductImageActions
==============================================================================*/
function ProductImageActions({ productData, setUploadCallback, setHasImages, setMsgBoxData, isDisabled, onlyMain=false, ...props }) {
  const { imagesSrc, setImagesSrc, selectedImageIdx, setSelectedImageIdx } = useGetImagesSrc(productData);
  /* Handling the upload of the images */
  const [images, setImages] = React.useState({});
  const addImage = (e, index) => {
    setImages(images => {
      return {...images, [c.IMAGES_SUBDIRS[index]]: {
        picturePreview: URL.createObjectURL(e.target.files[0]),
        pictureAsFile: e.target.files[0]
      }};
    });
    setImagesSrc(imagesSrc => {
      return {
        ...imagesSrc,
        [c.IMAGES_SUBDIRS[selectedImageIdx]]: URL.createObjectURL(e.target.files[0])
      };
    });
  };
  /* Update hasImages when we set the imagesSrc */
  React.useEffect(() => {
    setHasImages(hasImages => {
      const key = [c.IMAGES_SUBDIRS[selectedImageIdx]];
      return {...hasImages, [key]: (imagesSrc[key]?true:false)};
    });
  }, [imagesSrc]);
  /* The callback to upload images to server */
  const uploadImages = async () => {
    /* If we have no images to upload */
    if (images && Object.values(images).length===0) {
      return new Promise((resolve) => resolve([]));
    }
    /* → Construct the data to send */
    const formData = new FormData();
    Object.entries(images).forEach(entry => {
      formData.append(entry[0], entry[1].pictureAsFile, entry[1].pictureAsFile.name);
    });
    setMsgBoxData({ text: `Upload des images ${Object.entries(images).map(entry => entry[1].pictureAsFile.name).join(', ')}`, classes: ["colorInfo"], isLoading: true });
    /* → Send the data */
    return new Promise((resolve, reject) => {
      promiseFetch(`${url}/api/products/uploadImages`, {
        method: "post",
        body: formData,
      })
        .then(res => {
          if (res.status===200 && res.data) {
            window.setTimeout(() => {
              setMsgBoxData(null);
              /* → If the image is the main image, we get the ratio */
              if ("main" in images) {
                getImageFileRatio(images["main"].pictureAsFile)
                  .then(ratio => resolve([res.data, ratio]));
              } else {
                resolve([res.data, null]);
              }
            }, c.MSG_TIMEOUT_FAST);
          } else {
            setMsgBoxData({ text: res.data, classes: ["colorError"] });
            reject([res.data, null]);
          }
        })
    });
  };
  /* Each time the images change we update the callback */
  React.useEffect(() => {
    if (images) {
      setUploadCallback(() => uploadImages);
    }
  }, [images]);

  return (
    <div className="productFileInput">
      <label htmlFor={`fileInput${productData.id}`}>
        {/* https://bitsofco.de/styling-broken-images/ */}
        <img className="adminEntryImage" alt="" style={{
            backgroundColor: (isDisabled ? "var(--color-text-white)" : "var(--color-background)"),
            cursor: "pointer",
            border: (imagesSrc[c.IMAGES_SUBDIRS[selectedImageIdx]]===null ? "1px dashed var(--color-text)" : "none"),
            height: (imagesSrc[c.IMAGES_SUBDIRS[selectedImageIdx]]===null ? "135px" : "auto")
          }}
          src={imagesSrc[c.IMAGES_SUBDIRS[selectedImageIdx]]} />
      </label>
      {!onlyMain && <ImageSelectionButtons currentIndex={selectedImageIdx} setCurrentIndex={setSelectedImageIdx} />}
      <input type="file" name="image" id={`fileInput${productData.id}`} accept="image/*" onChange={(e) => addImage(e, selectedImageIdx)} />
    </div>
  );
}

/*------------------------------------------------------------------------------
Helper productModifyRequest
------------------------------------------------------------------------------*/
function productModifyRequest(data, setProductsData, setMsgBoxData) {
  /* → Request to modify product */
  promiseFetch(`${url}/api/products/modify`, {
    method: 'POST',
    headers: { "Content-Type": "application/json; charset=utf-8" },
    body: JSON.stringify(data)
  })
    .then(res => {
      if (res.status===200 && res.data) {
        window.setTimeout(() => {
          /* → Update the local data */
          setProductsData(productsData => {
            const index = productsData.findIndex(x => x.id===data.currentId);
            if (index!==-1) {
              productsData[index] = res.data;
              return [...productsData];
            }
            return productsData;
          });
          setMsgBoxData(null);
        }, c.MSG_TIMEOUT_FAST);
      } else {
        setMsgBoxData({ text: "Une erreur inconnue est survenue", classes: ["colorError"] });
      }
    });
}

/*------------------------------------------------------------------------------
Helper convertPathsToProductSrc
------------------------------------------------------------------------------*/
function convertPathsToProductSrc(paths) {
  return (paths ? Object.fromEntries(paths.map(x => {
    return [c.IMAGES_KEYCONV[x.split('/')[0]], x];
  })) : {});
}

/*==============================================================================
Component ActionsButtons
==============================================================================*/
function ActionsButtons({ doDeleteThis, doRefreshThis, onSave }) {
  return (
    <div className="adminProductButtonsLayout">
      <div className="orderActionButton buttonReject" style={{ margin: "0 auto 0 0" }} onClick={doDeleteThis} role="button">Supprimer</div>
      <div className="orderActionButton" onClick={doRefreshThis} role="button">Annuler</div>
      <div className="orderActionButton buttonConfirm" onClick={onSave} role="button">Sauvegarder les changements</div>
    </div>
  );
}

/*==============================================================================
Component ProductActionsBracelet
==============================================================================*/
const ProductActionsBracelet = withRouter(({ productData, productsIds, meditationsData, productsData, setProductsData, isDisabled, doRefresh, doRefreshThis, doDeleteThis, selectedType, updateSelectedType, setMsgBoxData, ...props }) => {
  /* Hooks */
  const [selectedTheme, setSelectedTheme, selectableThemes] = useGetThemes(meditationsData, productData, selectedType);
  const [selectedNumber, selectableNumbers, updateSelectedNumber] = useGetProductNumbers(productData);
  const [price, setPrice] = useEnterText(parseFloat(productData.price).toFixed(2));
  const [description, updateDescription, hasLoaded] = useGetDescription(productData.description);
  const [productId] = useGetProductIdBracelet(productData.id, meditationsData, selectedTheme, selectedNumber);
  const [subtitle, setSubtitle] = React.useState(productData.subtitle);
  const [linkedPids, selectableLinkedPids, onAddLinkedPid, onRemoveLinkedPid, onUpdateLinkedPid] = useGetLinkedPids(productsIds, productData.linkedPids);

  const [uploadCallback, setUploadCallback] = React.useState(null);
  const [hasImages, setHasImages] = React.useState({});

  /* Callback pour faire la sauvegarde du produit */
  const onSave = () => {
    const fprice = price.replace(',','.');
    if (productId!==productData.id && productsData.find(x => x.id===productId)) {
      setMsgBoxData({ text: `Le numéro ${selectedNumber} est déjà utilisé pour le thème "${selectedTheme}" par un autre produit, veuillez changer le thème ou le numéro`, classes: ["colorError"] });
    } else if (isNaN(parseFloat(fprice)) || fprice.match(/\d+[.]{0,1}\d{0,2}/)[0]!==fprice) {
      setMsgBoxData({ text: `Le prix est mal formaté, il ne doit contenir que des chiffres, une virgule, et 2 décimales`, classes: ["colorError"] });
    } else if (parseFloat(fprice)<=0) {
      setMsgBoxData({ text: `Le prix doit être supérieur à 0€`, classes: ["colorError"] });
    } else if (selectedType===undefined || selectedType===null || selectedType==="") {
      setMsgBoxData({ text: `Veuillez sélectionner un type`, classes: ["colorError"] });
    } else if (!selectableThemes.find(x => x===selectedTheme) || productId.slice(1,3)===c.EMPTY_KEY) {
      setMsgBoxData({ text: `Veuillez sélectionner un thème`, classes: ["colorError"] });
    } else if (!selectableNumbers.find(x => parseInt(x)===parseInt(selectedNumber))) {
      setMsgBoxData({ text: `Veuillez sélectionner un numéro`, classes: ["colorError"] });
    } else if (!hasImages[c.IMAGES_SUBDIRS[0]]) {
      setMsgBoxData({ text: `Veuillez ajouter l'image principale du produit`, classes: ["colorError"] });
    } else {
      setMsgBoxData({ text: "Sauvegarde du produit", classes: ["colorInfo"], isLoading: true });
      /* Delete the empty linkedPid */
      const newLinkedPid = {...linkedPids};
      delete newLinkedPid[c.EMPTY_KEY];
      /* → Upload the images if any */
      if (uploadCallback) {
        uploadCallback()
          .then(([newPaths, ratio]) => {
            newPaths = convertPathsToProductSrc(newPaths);
            /* → Request to modify the product */
            productModifyRequest({
                type: selectedType,
                name: selectedTheme,
                id: productId,
                currentId: productData.id,
                price: fprice,
                imageMain: newPaths['imageMain'],
                imageSecond: newPaths['imageSecond'],
                ...(ratio!==null?{imageRatio: ratio}:{}),
                description: Object.entries(description).map(x => x[0]==='Intention'?x[1]+'\n':x[0]+' : '+x[1]).join('\n'),
                subtitle: subtitle,
                linkedPids: Object.values(newLinkedPid).map(x => x.split(' - ')[0]),
                onlyLinked: false
              },
              setProductsData,
              setMsgBoxData);
          });
      }
    }
  };

  return (
    <div className="orderPageSummary" style={{ width: "650px", padding: "15px", margin: "0 auto 20px", background: "inherit" }}>
      <div className="ordersEntry">
        <ProductImageActions productData={productData} setUploadCallback={setUploadCallback} setHasImages={setHasImages}
          isDisabled={isDisabled} setMsgBoxData={setMsgBoxData}/>
        <div className="adminProductActionsLayout">
          <SelectionBox name="Type" value={selectedType} choices={[""].concat(Object.keys(c.PRODUCT_TYPE_ATTR))} updateSelected={updateSelectedType} />
          {selectableThemes &&
            <SelectionBox name="Thème" value={selectedTheme} choices={[""].concat(selectableThemes)} updateSelected={setSelectedTheme} />
          }
          <div className="adminProductLineLayout">
            <SelectionBox name="Numéro" value={selectedNumber} choices={selectableNumbers} updateSelected={updateSelectedNumber} width={120}/>
            <div className="adminProductIdDisplay">{productId}</div>
            <PriceInput name="Prix" value={price} updateValue={setPrice} symbol="€" style={{ margin: "0px 0px auto auto" }} />
          </div>
          {selectableLinkedPids &&
            <SelectionBoxList name="Inclus" values={linkedPids} choices={selectableLinkedPids} onAddItem={onAddLinkedPid}
              onRemoveItem={onRemoveLinkedPid} onUpdateItem={onUpdateLinkedPid} />
          }
        </div>
      </div>
      {description && hasLoaded &&
        <div className="adminProductBottomLayout">
          <TextInput name="Titre" value={subtitle} updateValue={setSubtitle} />
          {c.DESCRIPTION_KEYS.map((item, i) => {
            return <TextInput key={i} name={item.tag} value={description[item.desc]} dkey={item.desc} updateValue={updateDescription} />
          })}
          <ActionsButtons doDeleteThis={doDeleteThis} doRefreshThis={doRefreshThis} onSave={onSave} />
        </div>
      }
    </div>
  );
});

/*==============================================================================
Component ProductActionsQuantique
==============================================================================*/
const ProductActionsQuantique = withRouter(({ productData, productsIds, groupsData, meditationsData, productsData, setProductsData, isDisabled, doRefresh, doRefreshThis, doDeleteThis, selectedType, updateSelectedType, setMsgBoxData, ...props }) => {
  /* Hooks */
  const { selectedGroups, selectableGroups, onUpdateGroup } = useGetProductGroups(productData, selectedType, groupsData);
  const [selectedTheme, setSelectedTheme, selectableThemes] = useGetThemes(meditationsData, productData, selectedType);
  const [price, setPrice] = useEnterText(parseFloat(productData.price).toFixed(2));
  const [description, updateDescription, hasLoaded] = useGetDescription(productData.description);
  const [productId] = useGetProductIdQuantique(productData.id, meditationsData, selectedTheme, selectedGroups);
  const [subtitle, setSubtitle] = React.useState(productData.subtitle);
  const [linkedPids, selectableLinkedPids, onAddLinkedPid, onRemoveLinkedPid, onUpdateLinkedPid] = useGetLinkedPids(productsIds, productData.linkedPids);
  const [uploadCallback, setUploadCallback] = React.useState(null);
  const [hasImages, setHasImages] = React.useState({});

  /* Callback pour faire la sauvegarde du produit */
  const onSave = () => {
    const fprice = price.replace(',','.');
    if (Object.values(selectableGroups).length<1 || !selectableGroups.find(x => x===Object.values(selectedGroups)[0])) {
      setMsgBoxData({ text: `Veuillez sélectionner un groupe`, classes: ["colorError"] });
    } else if (productId!==productData.id && productsData.find(x => x.id===productId)) {
      setMsgBoxData({ text: `Le thème "${selectedTheme}" est déjà utilisé pour le groupe "${Object.values(selectedGroups)[0]}" par un autre produit, veuillez changer le thème ou le groupe`, classes: ["colorError"] });
    } else if (isNaN(parseFloat(fprice)) || fprice.match(/\d+[.]{0,1}\d{0,2}/)[0]!==fprice) {
      setMsgBoxData({ text: `Le prix est mal formaté, il ne doit contenir que des chiffres, une virgule, et 2 décimales`, classes: ["colorError"] });
    } else if (parseFloat(fprice)<=0) {
      setMsgBoxData({ text: `Le prix doit être supérieur à 0€`, classes: ["colorError"] });
    } else if (selectedType===undefined || selectedType===null || selectedType==="") {
      setMsgBoxData({ text: `Veuillez sélectionner un type`, classes: ["colorError"] });
    } else if (!selectableThemes.find(x => x===selectedTheme) || productId.slice(1,3)===c.EMPTY_KEY) {
      setMsgBoxData({ text: `Veuillez sélectionner un thème`, classes: ["colorError"] });
    } else if (!hasImages[c.IMAGES_SUBDIRS[0]]) {
      setMsgBoxData({ text: `Veuillez ajouter l'image principale du produit`, classes: ["colorError"] });
    } else {
      setMsgBoxData({ text: "Sauvegarde du produit", classes: ["colorInfo"], isLoading: true });
      /* Delete the empty linkedPid */
      const newLinkedPid = {...linkedPids};
      delete newLinkedPid[c.EMPTY_KEY];
      /* → Upload the images if any */
      if (uploadCallback) {
        uploadCallback()
          .then(newPaths => {
            newPaths = convertPathsToProductSrc(newPaths);
            /* → Request to modify the product */
            productModifyRequest({
                type: selectedType,
                name: selectedTheme,
                id: productId,
                currentId: productData.id,
                price: fprice,
                imageMain: newPaths['imageMain'],
                imageSecond: newPaths['imageSecond'],
                description: Object.entries(description).map(x => x[0]==='Intention'?x[1]+'\n':x[0]+' : '+x[1]).join('\n'),
                subtitle: subtitle,
                linkedPids: Object.values(newLinkedPid).map(x => x.split(' - ')[0]),
                onlyLinked: false
              },
              setProductsData,
              setMsgBoxData);
          });
      }
    }
  };

  return (
    <div className="orderPageSummary" style={{ width: "650px", padding: "15px", margin: "0 auto 20px", background: "inherit" }}>
      <div className="ordersEntry">
        <ProductImageActions productData={productData} setUploadCallback={setUploadCallback} setHasImages={setHasImages}
          isDisabled={isDisabled} setMsgBoxData={setMsgBoxData}/>
        <div className="adminProductActionsLayout">
          <SelectionBox name="Type" value={selectedType} choices={[""].concat(Object.keys(c.PRODUCT_TYPE_ATTR))} updateSelected={updateSelectedType} />
          {selectableGroups &&
            <SelectionBoxList name="Groupe" values={selectedGroups} choices={selectableGroups} onAddItem={null}
              onRemoveItem={null} onUpdateItem={onUpdateGroup} />
          }
          {selectableThemes &&
            <SelectionBox name="Thème" value={selectedTheme} choices={[""].concat(selectableThemes)} updateSelected={setSelectedTheme} />
          }
          <div className="adminProductLineLayout">
            {selectableGroups &&
              <div className="adminProductIdDisplay" style={{ margin: "0 0 0 61px" }}>{productId}</div>
            }
            <PriceInput name="Prix" value={price} updateValue={setPrice} symbol="€" style={{ margin: "0px 0px auto auto" }} />
          </div>
          {selectableLinkedPids &&
            <SelectionBoxList name="Inclus" values={linkedPids} choices={selectableLinkedPids} onAddItem={onAddLinkedPid}
              onRemoveItem={onRemoveLinkedPid} onUpdateItem={onUpdateLinkedPid} />
          }
        </div>
      </div>
      {description && hasLoaded &&
        <div className="adminProductBottomLayout">
          <TextInput name="Titre" value={subtitle} updateValue={setSubtitle} />
          {c.DESCRIPTION_KEYS.map((item, i) => {
            if (item.tag!=="Recommandations HE")
              return <TextInput key={i} name={item.tag} value={description[item.desc]} dkey={item.desc} updateValue={updateDescription} />
            return null;
          })}
          <ActionsButtons doDeleteThis={doDeleteThis} doRefreshThis={doRefreshThis} onSave={onSave} />
        </div>
      }
    </div>
  );
});

/*------------------------------------------------------------------------------
Hook useGetOnlyLinked
------------------------------------------------------------------------------*/
function useGetOnlyLinked(productData, props) {
  const { setModalProps } = React.useContext(ModalContext);
  const [onlyLinked, setOnlyLinked] = React.useState(productData.onlyLinked);
  const handleOnlyLinked = (e) => {
    /* The callback for confirmation modal */
    const nextCallback = (data, props, setMsgBoxData) => {
      setOnlyLinked(data.checked);
      setMsgBoxData(null);
      props.history.goBack();
    };
    /*  */
    setModalProps({ productData: productData, checked: e.target.checked, setOnlyLinked: setOnlyLinked, next: nextCallback,
      text: !e.target.checked ?
        <>
          <b>Voulez-vous vraiment remettre ce produit disponible à la vente ?</b><br/><br/>
          Ce produit sera à nouveau visible dans le magasin en plus de ses associations aux produits.
        </> :
        <>
          <b>Voulez-vous vraiment retirer ce produit de la vente tout en gardant ses associations ?</b><br/><br/>
          Ce produit ne sera plus visible dans le magasin mais restera associé aux produits avec lesquels il l'est déjà.
        </>
      });
    props.history.push('#onlylinkconfirmation');    
  };
  React.useEffect(() => {
    if (productData.onlyLinked!==null && productData.onlyLinked!==undefined) {
      setOnlyLinked(productData.onlyLinked);
    }
  }, [productData.onlyLinked]);
  return [onlyLinked, handleOnlyLinked];
}

/*==============================================================================
Component ProductActionsExtras
==============================================================================*/
const ProductActionsExtras = withRouter(({ productData, groupsData, meditationsData, productsData, setProductsData, isDisabled, doRefresh, doRefreshThis, doDeleteThis, selectedType, updateSelectedType, setMsgBoxData, ...props }) => {
  /* Hooks */
  const [selectedNumber, selectableNumbers, updateSelectedNumber] = useGetProductNumbers(productData, 100);
  const [price, setPrice] = useEnterText(parseFloat(productData.price).toFixed(2));
  const [description, updateDescription, hasLoaded] = useGetExtrasDescription(productData.description);
  const [productId] = useGetProductIdExtras(productData.id, selectedType, selectedNumber);
  const [subtitle, setSubtitle] = React.useState(productData.subtitle);
  const [name, setName] = React.useState(productData.name);
  const [uploadCallback, setUploadCallback] = React.useState(null);
  const [hasImages, setHasImages] = React.useState(null);
  const [onlyLinked, handleOnlyLinked] = useGetOnlyLinked(productData, props);

  /* Callback pour faire la sauvegarde du produit */
  const onSave = () => {
    const fprice = price.replace(',','.');
    if (productId!==productData.id && productsData.find(x => x.id===productId)) {
      setMsgBoxData({ text: `Le numéro ${selectedNumber} est déjà utilisé pour le type "${selectedType}" par un autre produit, veuillez changer le numéro ou le type`, classes: ["colorError"] });
    } else if (isNaN(parseFloat(fprice)) || fprice.match(/\d+[.]{0,1}\d{0,2}/)[0]!==fprice) {
      setMsgBoxData({ text: `Le prix est mal formaté, il ne doit contenir que des chiffres, une virgule, et 2 décimales`, classes: ["colorError"] });
    } else if (parseFloat(fprice)<=0) {
      setMsgBoxData({ text: `Le prix doit être supérieur à 0€`, classes: ["colorError"] });
    } else if (selectedType===undefined || selectedType===null || selectedType==="") {
      setMsgBoxData({ text: `Veuillez sélectionner un type`, classes: ["colorError"] });
    } else if (!selectableNumbers.find(x => parseInt(x)===parseInt(selectedNumber))) {
      setMsgBoxData({ text: `Veuillez sélectionner un numéro`, classes: ["colorError"] });
    } else if (name==="") {
      setMsgBoxData({ text: `Veuillez renseigner un nom pour le produit`, classes: ["colorError"] });
    } else if (!hasImages[c.IMAGES_SUBDIRS[0]]) {
      setMsgBoxData({ text: `Veuillez ajouter l'image principale du produit`, classes: ["colorError"] });
    } else {
      setMsgBoxData({ text: "Sauvegarde du produit", classes: ["colorInfo"], isLoading: true });
      /* → Upload the images if any */
      if (uploadCallback) {
        uploadCallback()
          .then(newPaths => {
            newPaths = convertPathsToProductSrc(newPaths);
            /* → Request to modify the product */
            productModifyRequest({
                type: selectedType,
                name: name,
                id: productId,
                currentId: productData.id,
                price: fprice,
                imageMain: newPaths['imageMain'],
                description: description.join('\n\n'),
                subtitle: subtitle,
                linkedPids: [],
                onlyLinked: onlyLinked
              },
              setProductsData,
              setMsgBoxData);
          });
      }
    }
  };

  return (
    <div className="orderPageSummary" style={{ width: "650px", padding: "15px", margin: "0 auto 20px", background: "inherit" }}>
      <div className="ordersEntry">
        <ProductImageActions productData={productData} setUploadCallback={setUploadCallback} setHasImages={setHasImages}
          isDisabled={isDisabled} setMsgBoxData={setMsgBoxData} onlyMain={true} />
        <div className="adminProductActionsLayout">
          <SelectionBox name="Type" value={selectedType} choices={[""].concat(Object.keys(c.PRODUCT_TYPE_ATTR))} updateSelected={updateSelectedType} />
          <div className="adminProductLineLayout">
            <SelectionBox name="Numéro" value={selectedNumber} choices={selectableNumbers} updateSelected={updateSelectedNumber} width={120}/>
            <div className="adminProductIdDisplay">{productId}</div>
            <PriceInput name="Prix" value={price} updateValue={setPrice} symbol="€" style={{ margin: "0px 0px auto auto" }} />
          </div>
          {description && hasLoaded &&
            <>
              <TextInput name="Nom" value={name} updateValue={setName} />
              <TextInput name="Titre" value={subtitle} updateValue={setSubtitle} />
              <TextInput name="Description" value={description[0]} dkey={0} updateValue={updateDescription} />
              <TextInput name="Composition" value={description[1]} dkey={1} updateValue={updateDescription} />
              <TextInput name="Lien" value={description[2]} dkey={2} updateValue={updateDescription} />
            </>
          }
          <label className="adminProductOnlyLinked">
            <span>Disponible uniquement par association aux produits</span>
            <input name="onlyLinked" type="checkbox" onChange={handleOnlyLinked} checked={onlyLinked} />
          </label>
        </div>
      </div>
      <ActionsButtons doDeleteThis={doDeleteThis} doRefreshThis={doRefreshThis} onSave={onSave} />
    </div>
  );
});

/*------------------------------------------------------------------------------
Helpers
------------------------------------------------------------------------------*/
function getNumberInGroup(productData) {
  if (productData.type==="Bracelet") {
    const arr = productData.id.split('-');
    if (arr.length===2 && !isNaN(parseInt(arr[1]))) {
      return `, ${parseInt(arr[1])}`;
    }
  }
  return "";
}

/*------------------------------------------------------------------------------
Hook useDeactivationSwitch
------------------------------------------------------------------------------*/
function useDeactivationSwitch(productData, doRefresh, props) {
  const { setModalProps } = React.useContext(ModalContext);
  /* Activate/Deactivate switch */
  const [checked, setChecked] = React.useState(!productData.disabled);
  const handleSetChecked = (checked) => {
    setChecked(checked);
    doRefresh(true);
  };

  /* The callback for confirmation modal */
  const nextCallback = (data, props, setMsgBoxData) => {
    const resolve = (data, props, setMsgBoxData) => {
      /* → Update local data */
      data.setChecked(data.checked);
      window.setTimeout(() => {
        setMsgBoxData(null);
        /* → Redirection */
        props.history.goBack();
      }, c.MSG_TIMEOUT_FAST);
    };
    setMsgBoxData({ text: `${data.checked?"Activation":"Désactivation"} du produit`, classes: ["colorInfo"], isLoading: true });
    if (data.productData.id===c.EMPTY_ID) {
      resolve(data, props, setMsgBoxData);
    } else {
      /* → Request to server to change the activation the product */
      promiseFetch(`${url}/api/products/changeActivationWithPid/${encodeURIComponent(data.productData.id)}`, {
        method: 'POST',
        headers: { "Content-Type": "application/json; charset=utf-8" },
        body: JSON.stringify({ disabled: !data.checked })
      })
        .then(res => {
          if (res.status===200 && res.data) {
            resolve(data, props, setMsgBoxData);
          } else {
            setMsgBoxData({ text: "Une erreur inconnue est survenue", classes: ["colorError"] });
          }
        })
    }
  };
  const handleChange = (checked) => {
    setModalProps({ productData: productData, checked: checked, setChecked: handleSetChecked, next: nextCallback,
      text: !checked ?
        <>
          <b>Voulez-vous vraiment retirer ce produit de la vente ?</b><br/><br/>
          Ce produit ne sera plus disponible à la vente jusqu'à ce que vous le réactiviez.<br/>
          Les clients ayant ce produit dans leur panier pourront encore le commander (soyez vigilants à la validation des prochaines commandes).
        </> :
        <>
          <b>Voulez-vous vraiment remettre ce produit en vente ?</b><br/><br/>
          Ce produit sera de nouveau disponible à la vente.
        </>
      });
    props.history.push('#productactivation');
  };
  return [checked, handleChange];
}

/*==============================================================================
Component ProductEntryItem
==============================================================================*/
const ProductEntryItem = withRouter(({ productData, productsIds, groupsData, meditationsData, productsData, setProductsData, zIndex, doRefresh, doDelete, ...props }) => {
  /* Hooks */
  const [msgBoxData, setMsgBoxData] = React.useState(null);
  const msgBoxAnchorRef = React.useRef(null);

  /* Resets msgBoxData at unmount */
  React.useEffect(() => {
    return () => { setMsgBoxData(null); };
  }, []);

  const [isExpanded, setIsExpanded] = React.useState(false);
  React.useEffect(() => {
    if (productData) {
      setIsExpanded(false);
    }
  }, [productData]);
  /* onClick callback function */
  const onClick = (e) => {
    e.preventDefault();
    setIsExpanded(isExpanded => !isExpanded);
    setMsgBoxData(null);
  };

  /* Handle the product deactivation switch logic */
  const [checked, handleChecked] = useDeactivationSwitch(productData, doRefresh, props);
  /* Used to switch components */
  const [selectedType, updateSelectedType] = useGetSelectedType(productData.type);
  /* Used to refresh the component when we cancel */
  const [key, setKey] = React.useState(0);
  const refreshComponent = () => {
    setKey(key => key + 1);
    updateSelectedType(productData.type);
  };
  /* Delete component callback */
  const deleteComponent = () => {
    doDelete(productData);
  };
  const ACTIONS_COMPONENTS = {
    "Bracelet": ProductActionsBracelet,
    "Quantique": ProductActionsQuantique,
    "Huile essentielle": ProductActionsExtras,
    "Composé floral": ProductActionsExtras
  };

  return (
    <div className="orderEntryItemBox" style={!checked ? { zIndex: zIndex, background: 'var(--color-text-white)' } : { zIndex: zIndex }}>
      <div className="productEntryOuterHeader">
        <div className="orderEntryItemHeader" onClickCapture={onClick} style={{ color: (!checked?'var(--color-text-light)':'var(--color-text)') }}role="button">
          <p className="productEntryItemCell" style={{ fontWeight:"300", color: "var(--color-text-light)" }}>{
            productData.id!==c.EMPTY_ID ? productData.id : ""
          }</p>
          <p className="productEntryItemCell" style={{ width: "50%" }}>{
            productData.id!==c.EMPTY_ID ? <><b>{productData.type}</b>{` / ${productData.name}${getNumberInGroup(productData)}`}</> :
            <b>Nouveau produit</b>
          }</p>
          <p className="productEntryItemCell" style={{ margin: "auto 72px auto auto" }}><b>{floatToPrice(productData.price)}</b></p>
        </div>
        <label className="switchLabel">
          <Switch onChange={handleChecked} checked={checked} height={16} width={32} checkedIcon={false} uncheckedIcon={false}
            onColor='#16a765' offColor='#C1C1C1'/>
        </label>
      </div>
      {isExpanded &&
        <div className="orderEntryItemExpanded" style={{ display: 'flex' }}>
          {React.createElement(ACTIONS_COMPONENTS[selectedType], {
            productData, productsIds, groupsData, meditationsData, productsData, setProductsData, doRefresh,
            selectedType, updateSelectedType, setMsgBoxData, key, isDisabled: !checked,
            doRefreshThis: refreshComponent, doDeleteThis: deleteComponent,
          })}
        </div>
      }
      <div className="orderEmptyMsgBoxAnchor" ref={msgBoxAnchorRef}></div>
      {msgBoxData!==undefined && <MessageBox msgBoxData={msgBoxData} componentRef={msgBoxAnchorRef} mode="absolute2" />}
    </div>
  );
})

/*==============================================================================
Component ProductEntriesLeftMenu
==============================================================================*/
function ProductEntriesLeftMenu({ currentIndex, setCurrentIndex, ...props }) {
  const onClick = (e, index) => {
    setCurrentIndex(index);
  }
  return (
    <nav className="orderEntriesLeftMenu">
      <ul>
        {["Tous", ...Object.values(c.PRODUCT_TYPE_ATTR).map(x => x.category), "Désactivés"].map((label, i) => {
          const style = (currentIndex===i ? { fontWeight: 700 } : {});
          return <li key={i} title={label} onClick={(e) => onClick(e, i)} style={style} role="button">
            {label}
          </li>;
        })}
      </ul>
    </nav>
  );
}

/*==============================================================================
Component ProductEntriesRightButton
==============================================================================*/
function ProductEntriesRightButton({ addProduct, ...props }) {
  const onClick = (e) => {
    addProduct();
  }
  return (
    <div className="groupEntriesRightButton" onClick={onClick} role="button">
      + Ajouter un produit
    </div>
  );
}

/*==============================================================================
Main component
==============================================================================*/
export default withRouter(function AdminProductsPage({ setMsgBoxData, ...props }) {
  const { setModalProps } = React.useContext(ModalContext);
  const entriesLayoutRef = React.useRef(null);
  /* Hooks */
  const [currentIndex, setCurrentIndex] = React.useState(0);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentIndex]);
  const [groupsData] = useFetchGroups(setMsgBoxData);
  const [meditationsData] = useFetchMeditations(setMsgBoxData);
  const [productsData, setProductsData, doRefresh] = useFetchProducts(setMsgBoxData);
  const filteredProductsData = useFilterProducts(productsData, currentIndex);

  /* Build the linked extras pids to select */
  const [productsIds, setProductsIds] = React.useState(null);
  React.useEffect(() => {
    if (productsData) {
      setMsgBoxData(null);
      const extrasTypes = Object.entries(c.PRODUCT_TYPE_ATTR).filter(x => x[1].isExtra).map(x => x[0]);
      setProductsIds(productsData.filter(x => extrasTypes.includes(x.type)).map(x => `${x.id} - ${x.name}${x.disabled?' (désactivé)':''}`));
    }
  }, [productsData]);
  /* → Open a confirmation modal */
  const doDelete = (productData) => {
    /* Callback from confirmation modal */
    const nextCallback = (data, props, setMsgBoxData) => {
      const resolve = (data, props, setMsgBoxData) => {
        /* → Update local data */
        data.setProductsData(productsData => {
          return productsData.filter(x => x.id!==data.productData.id);
        });
        window.setTimeout(() => {
          setMsgBoxData(null);
          /* → Redirection */
          props.history.goBack();
        }, c.MSG_TIMEOUT_FAST);
      };
      setMsgBoxData({ text: "Suppression du produit", classes: ["colorInfo"], isLoading: true });
      if (data.productData.id===c.EMPTY_ID) {
        resolve(data, props, setMsgBoxData);
      } else {
        /* → Request to server to delete the product */
        promiseFetch(`${url}/api/products/deleteWithPid/${encodeURIComponent(data.productData.id)}`, {method: 'DELETE'})
          .then(res => {
            if (res.status===200 && res.data) {
              resolve(data, props, setMsgBoxData);
            } else {
              setMsgBoxData({ text: "Une erreur inconnue est survenue", classes: ["colorError"] });
            }
          })
      }
    };
    /* Send data and trigger confirmation modal */
    setModalProps({ productData, setProductsData, next: nextCallback,
      text: <><b>Voulez-vous vraiment supprimer ce produit ?</b><br/><br/>
        Le produit ne sera plus disponible à la vente et il n'existera plus de référence du produit dans la base de donnée.</>
    });
    props.history.push('#deleteproduct');
  };
  /* Create a new product */
  const addProduct = () => {
    setProductsData(productsData => {
      if (!productsData.find(x => x.id===c.EMPTY_ID)) {
        productsData.push({
          type: "Bracelet",
          name: "",
          description: "",
          id: c.EMPTY_ID,
          imageMain: "",
          imageSecond: "",
          linkedPids: [],
          price: 29,
          disabled: false,
        });
      }
      return [...productsData];
    });
    /* Scroll to the added item */
    if (entriesLayoutRef.current && entriesLayoutRef.current.lastChild) {
      const childPos = entriesLayoutRef.current.lastChild.getBoundingClientRect().top;
      window.scrollTo({ top: childPos + window.pageYOffset - window.innerHeight / 2, behavior: 'smooth' });
    }
  };

  return (
    <>
      {filteredProductsData ?
        <>
          <ProductEntriesLeftMenu currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} />
          <ProductEntriesRightButton addProduct={addProduct} />
          <div className="orderEntriesLayout" ref={entriesLayoutRef}>
            {filteredProductsData.map((productData, i) => {
              return <ProductEntryItem key={productData.id} productData={productData} productsIds={productsIds} groupsData={groupsData}
                meditationsData={meditationsData} productsData={productsData} setProductsData={setProductsData} zIndex={filteredProductsData.length - i}
                doRefresh={doRefresh} doDelete={doDelete} />;
            })}
          </div>
        </> :
        <LoadingSpinner />
      }
    </>
  );
})
