import React from 'react'
import { withRouter } from 'react-router'
import Modal from './Modal.component'
import LoadingSpinner from './LoadingSpinner.component'
import { floatToPrice, extrasStringToHtmlText, getPublicImageSrc } from '../helperFunctions'
import { convertSearchParamsToObject, convertObjectToSearchParams } from '../helperFunctions'
import c from '../Constants'

/*==============================================================================
Component ListEntry
==============================================================================*/
function ListEntry({ extraData, zindex, isIncluded, currentSelected, setCurrentSelected, setMsgBoxData }) {
  /* Button selection logic */
  const handleSelection = () => {
    setCurrentSelected(zindex!==currentSelected ? zindex : -1);
    setMsgBoxData(null);
  }
  const [buttonState, setButtonState] = React.useState({ className: "buttonRejectAction", text: "Sélectionner" });
  React.useEffect(() => {
    if (currentSelected===zindex) {
      setButtonState({ className: "buttonConfirmAction", text: "Retirer" });
    } else {
      setButtonState({ className: "buttonRejectAction", text: "Sélectionner" });
    }
  }, [currentSelected]);

  return (
    <div className="modalListEntry" style={{ zIndex: zindex, background: (currentSelected===zindex ? "var(--color-text-white)" : "var(--color-background)") }}>
      <img className="extraDetailsImage" src={getPublicImageSrc(extraData.imageMain)} alt={`${extraData.type} du thème ${extraData.name}`}/>
      <div className="extraDetailsRightLayout">
        <h3 className="productNameExtras">{extraData.name}
          <span className="productId">{extraData.id}</span>
        </h3>
        {extraData.subtitle && <h4 className="productSubTitleExtras">{extraData.subtitle}</h4>}
        <p className="productPrice" style={isIncluded ? { color: "#999", fontWeight: "300" } : {}}>
          {isIncluded ? "Prix inclu" : floatToPrice(extraData.price)}
        </p>
        <p className="extraDetailsDescription">{extrasStringToHtmlText(extraData.description, c.SELECTABLE_EXTRAS[extraData.type].linkText)}</p>
        <div className={buttonState.className} style={{ margin: "18px 0 0" }} onClick={handleSelection} role="button">
          {buttonState.text}
        </div>
      </div>
    </div>
  );
}

/*==============================================================================
Main component
==============================================================================*/
export default withRouter(function ProductExtrasSelectionModal({ data, ...props }) {
  /* Message box data */
  const [msgBoxData, setMsgBoxData] = React.useState(null);
  /* Hooks */
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [nvalues, setNvalues] = React.useState(0);
  const [currentSelected, setCurrentSelected] = React.useState(-1);

  /* Callbacks */
  const onCancelClicked = () => {
    props.history.goBack();
  }
  const onConfirmClicked = () => {
    const selectedPid = data.extras.map(x => x.id)[nvalues-currentSelected];
    if (selectedPid!==undefined) {
      /* Add a param in the search params or modifies it if it already exists */
      const params = convertSearchParamsToObject(props.location.search);
      /* Logic for list of selection in params.selected */
      if (params.selected===undefined || params.selected==="")
        params["selected"] = ",";
      let selected = params["selected"].split(',');
      selected[data.index] = selectedPid.slice(1);
      params["selected"] = selected.join(',');
      const searchParams = convertObjectToSearchParams(params);

      /* goBack with updated searchParams, assuming a superModal url is a location appended with a - to previous hash location */
      if (props.location.hash.indexOf('-')!==-1) {
        const lastPosition = props.location.hash.split('-').slice(0, -1).join('-');
        props.history.push(searchParams+lastPosition);
      }
    } else {
      setMsgBoxData({ text: "Veuillez sélectionner un produit puis valider ou cliquez sur annuler", classes: ["colorInfo"] });
    }
  }

  React.useEffect(() => {
    /* If we don't have anything to show we goBack */
    if (data.extras===undefined || data.extras.length===0) {
      props.history.goBack();
    } else {
      setNvalues(data.extras.length);
      setIsLoaded(true);
    }
  }, []);

  return (<>
    {isLoaded ?
      <Modal className="extrasBox" backgroundClassName="backgroundDark" msgBoxData={msgBoxData}>
        <div className="modalListHeader" style={{ zIndex: nvalues+1 }}>
          <h1 className="modalTitle">{data.type}</h1>
        </div>
        <div className="extraListContent">
          {data.type==="Huile essentielle" &&
            <p className="huileEssentielleNotice">
              Nous avons choisi le laboratoire français Herbes et Traditions pour ses engagements éco-responsable, humaine, qualité des plantes, de chaque produit et qualité de production ;<br/>
              Chaque Huile essentielle sont 100% pures et naturelles.<br/><br/>
              Bouchon sécurité enfant ;<br/>
              Les Huiles essentielles peuvent dangereuse pour les enfants et les femmes enceintes.<br/>
              Ne pas ingérer, jamais sur les muqueuses, jamais dans l’œil.<br/>
              Ne pas mettre sur la peau, toutes les huiles essentielles photo-sensibilisantes, tous les citrus (ne pas s’exposer au soleil)<br/>
              Elles peuvent être dermo-caustique (irriter la peau, tous les épices...)<br/>
              Elles peuvent être hépato-toxique (intoxiquer le foie : thym, épices...)<br/>
              Toutes les huiles essentielles sont à utiliser avec précaution et pour celles conseillées sur notre site ;<br/>
              À utiliser avec beaucoup de précaution : Bergamote - Marjolaine - Menthe poivrée<br/><br/>
              Mettre une petite goutte de l’huile essentielle choisie, sur la Pierre de lave de votre bracelet puis se laver les mains (voir le <a href="https://www.synesthesia.fr/download/manual">mode d’emploi</a>)
            </p>
          }
          {data.extras.map((extraData, i) => {
            return <ListEntry extraData={extraData} isIncluded={data.isIncluded} zindex={nvalues-i} key={i}
              currentSelected={currentSelected} setCurrentSelected={setCurrentSelected} setMsgBoxData={setMsgBoxData} />
          })}
        </div>
        <div className="modalListFooter" style={{ zIndex: nvalues+1 }}>
          <div className={"buttonRejectAction"} onClick={onCancelClicked} role="button">Annuler</div>
          <div className={"buttonConfirmAction"} onClick={onConfirmClicked} role="button">Valider</div>
        </div>
      </Modal> :
      <LoadingSpinner className="productDetailsSpinner"/>
    }
  </>);
})
