import React from 'react'

/* Note:
  On ajoute credentials: 'include' dans les options des requêtes pour passer
  les credentials quand on fait des requêtes CORS depuis https://www.synesthesia.fr
  sinon req.isAuthenticated() échoue car l'api réside au domaine https://synesthesia.fr
 */
export function useFetch(url, init = {}) {
  const [state, setState] = React.useState({
    data: null,
    completed: false
  });
  React.useEffect(function () {
    const fetchData = async () => {
      const response = await fetch(url, { credentials: 'include', ...init });
      if (response.ok) {
        const responseData = await response.json();
        setState(state => ({ data: responseData, completed: true }));
      } else {
        setState(state => ({ ...state, completed: true }));
      }
    };
    fetchData();
  }, []); // eslint-disable-line
  return [state.data, state.completed];
}

// return fetch(baseurl + url, { credentials: 'include', ...init })
// .then(async res => {
//   const status = res.status;
//   const err = res.ok ? "" : (await res.text().catch(() => ""));
//   const data = res.ok ? await res.json().catch(() => null) : null;

//   return { status, data: (data === null ? undefined : data), err: (err === "" ? undefined : err) };
// });

export function useFetchOnSubmit(url, init = {}) {
  const [state, setState] = React.useState({
    data: null,
    status: 0,
    completed: false
  });
  const handleSubmit = e => {
    fetch(url, { credentials: 'include', ...init })
      .then(res => {
        return Promise.all([res.json(), res.status]);
      })
      .then(([json, status]) => {
        setState({ data: json, status: status, completed: true });
      })
      .catch(e => {
        setState(state => ({ ...state, completed: true }));
      })
  }
  return [state, handleSubmit];
}

export function useFetchOnSubmitWithSet(url, init = {}) {
  const [state, setState] = React.useState({
    data: null,
    status: 0,
    completed: false
  });
  const handleSubmit = e => {
    fetch(url, { credentials: 'include', ...init })
      .then(res => {
        return Promise.all([res.json(), res.status]);
      })
      .then(([json, status]) => {
        setState({ data: json, status: status, completed: true });
      })
      .catch(e => {
        setState(state => ({ ...state, completed: true }));
      })
  }
  return [state, setState, handleSubmit];
}

export function useEnterText(initialValue = "", maxLength = 0) {
  const [value, setValue] = React.useState(initialValue);
  const handleChange = (e) => {
    if (typeof e === 'string') {
      if (maxLength === 0 || e.length <= maxLength) {
        setValue(e);
      }
    } else {
      e.preventDefault()
      if (maxLength === 0 || e.target.value.length <= maxLength) {
        setValue(e.target.value);
      }
    }
  };
  return [value, handleChange];
}

export function useEnterCode(maxLength = 0) {
  const [value, setValue] = React.useState("")
  const handleChange = (value) => {
    if (maxLength === 0 || value.length <= maxLength) {
      setValue(value.toUpperCase())
    }
  }
  return [value, handleChange]
}

/*
Garde une référence sur la valeur précédente d'un objet
*/
export function usePrevious(value) {
  const ref = React.useRef();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

/*
Hook pour avoir la taille de la fenêtre
*/
const getWindowDimensions = () => {
  const { innerWidth, innerHeight } = window;
  return { width: innerWidth, height: innerHeight };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());

  React.useLayoutEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [])
  return windowDimensions;
}

/*
Hook pour précharger une image (utile quand on veut afficher un component d'un coup
et pas avoir des sous-component qui se chargent petit à petit)
*/
export function useImagePreloader(src) {
  const [imageReady, setImageReady] = React.useState(false);
  React.useEffect(() => {
    if (src===null) {
      setImageReady(true);
    } else {
      const img = new Image();
      img.onload = () => {
        setImageReady(true);
      }
      img.src = src;
    }
  }, []);
  return [imageReady, src];
}
