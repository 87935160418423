import React from 'react'
import { isMobile } from 'react-device-detect'
import Modal from './Modal.component'
import LoadingSpinner from './LoadingSpinner.component'
import SummaryTable, { SummaryTablePrices } from './SummaryTable.component'
import { floatToPrice, formatDate, getPublicImageSrc, promiseFetch, getLowFromPriceRange } from '../helperFunctions'
import { useImagePreloader } from '../hooks/asyncHooks'

const url = process.env.REACT_APP_URL;

/*------------------------------------------------------------------------------
Hook usePackagePriceLow
------------------------------------------------------------------------------*/
function usePackagePriceLow(priceRange) {
  const [packagePriceLow, setPackagePriceLow] = React.useState(getLowFromPriceRange(priceRange));
  React.useEffect(() => {
    setPackagePriceLow(getLowFromPriceRange(priceRange));
  }, [priceRange]);
  return packagePriceLow;
}

/*==============================================================================
Component OrderSubItem
==============================================================================*/
export function OrderSubItem({ orderItem }) {
  const packagePriceLow = usePackagePriceLow(orderItem.package.priceRange);
  const [isImageReady, imageSrc] = useImagePreloader(getPublicImageSrc(orderItem.product.imageMain));

  return (
    <>
      {isImageReady && <>
        <div className="ordersEntry">
          <img className="cartEntryImage" src={imageSrc}
            alt={`${orderItem.product.type} du thème ${orderItem.product.name}`} />
          <div className="cartEntryInfoLayout">
            <h2 className="cartEntryName">{orderItem.package.name}
              <span className="cartEntryPrice">{floatToPrice(packagePriceLow)}</span>
            </h2>
            <SummaryTable productData={orderItem.product} packageData={orderItem.package} size={orderItem.size?`Taille ${orderItem.size}`:null}
              extrasSelected={orderItem.extrasSelected} extrasLinked={orderItem.extrasLinked} />
            <SummaryTablePrices orderItem={orderItem} />
          </div>
        </div>
        <hr/>
      </>}
    </>
  );
}

/*==============================================================================
Component StatusIndicator
==============================================================================*/
function StatusIndicator({ status, trackOrderCallback }) {
  const statusColor = {
    "En attente": "#4C66CA",
    "En préparation": "#4C66CA",
    "Envoyé": "#16A765",
    "Annulé": "#F51D35",
    "Expiré": "#ADAABF",
  };
  /* Callback */
  const handleOnClick = () => {
    if (status==="Envoyé") {
      trackOrderCallback();
    }
  }
  return (
    <div className="ordersEntryStatusIndicator" style={{ backgroundColor: statusColor[status] }} onClick={handleOnClick} role="button">
      {status}
    </div>
  );
}

/*==============================================================================
Component OrdersComponents
==============================================================================*/
function OrdersComponents({ ordersContent, trackOrderCallback }) {
  return (
    <>
      {ordersContent.map((order, i) => {
          return (
            <div className="modalListEntry" key={i} style={{ zIndex: ordersContent.length-i, flexDirection: "column" }}>
              <div className="ordersEntryHeaderLayout">
                <h2 className="ordersEntryOrderId">
                  {isMobile ? 'N°' : 'Commande N°'}
                  <span className="ordersEntryId">{order.id}</span>
                </h2>
                <p className="ordersEntryDate">{formatDate(order.date)}</p>
                <StatusIndicator status={order.status} trackOrderCallback={() => trackOrderCallback(i)} />
              </div>
              <hr/>
              {order.items.map((item, j) => {
                return <OrderSubItem key={j} orderItem={item} />;
              })}
              <p className="productPackageSummaryTotal" style={{ margin: "0px 0px 10px" }}>
                Livraison standard
                <span className="productPackageSummaryTotalAmount">{order.deliveryFee===0 ? 'Offerte' : '+'+floatToPrice(order.deliveryFee)}</span>
              </p>
              <p className="productPackageSummaryTotal productPackageSummaryTotalAlt">
                Total
                <span className="productPackageSummaryTotalAmount">{floatToPrice(order.total)}</span>
              </p>
            </div>
          );
        })
      }
    </>
  );
}

/*------------------------------------------------------------------------------
Hook useFetchOrders
------------------------------------------------------------------------------*/
function useFetchOrders(setMsgBoxData) {
  const [ordersContent, setOrdersContent] = React.useState(null);
  React.useEffect(() => {
    promiseFetch(`${url}/api/users/getOrders`)
      .then(res => {
        if (res.status===200) {
          setOrdersContent(res.data);
        } else {
          setMsgBoxData({ text: "Impossible de charger les commandes", classes: ["colorError"] });
        }
      })
  }, []);
  return ordersContent;
}

/*==============================================================================
Main component
==============================================================================*/
export default function OrdersModal({ ...props }) {
  /* Hooks */
  const [msgBoxData, setMsgBoxData] = React.useState(null);
  const ordersContent = useFetchOrders(setMsgBoxData);

  /* Callbacks */
  const trackOrderCallback = (i) => {
    console.log("Accessing laposte API to track order");
  };

  return (
    <Modal className="cartBox" msgBoxData={msgBoxData}>
      {ordersContent ?
        <>
          <div className="modalListHeader" style={{ zIndex: ordersContent.length+1 }}>
            <h1 className="modalTitle">Commandes</h1>
          </div>
          {ordersContent.length===0 ?
            <div className="ordersEmptyDescription"><b>Aucune commande en cours.</b><p>Si vous avez effectué un achat, veuillez vérifier que vous ayez reçu un email de confirmation de commande (cela peut prendre quelques minutes) puis veuillez rafraîchir la page.</p></div> :
            <>
              <div className="extraListContent">
                <OrdersComponents ordersContent={ordersContent} trackOrderCallback={trackOrderCallback} />
              </div>
              {!isMobile &&
                <div className="ordersFooter">
                  <div className="ordersFooterDescription">
                    Une fois votre commande traitée elle passe du status "En attente" à "En préparation".
                    Le status "Envoyé" signifie qu'elle a été remise à notre partenaire de livraison et qu'elle est actuellement en chemin.
                    En cliquant sur ce bouton vous pourrez suivre son avancée.
                  </div>
                </div>
              }
            </>
          }
        </> :
        <LoadingSpinner />
      }
      <div className="ordersContactMailTitle">Service client
        <div className="ordersContactMail">synesthesia.fr@gmail.com</div>
      </div>
    </Modal>
  );
}