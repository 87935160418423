import React from 'react'
import { promiseFetch } from './helperFunctions'
import c from './Constants'

const url = process.env.REACT_APP_URL;

/*==============================================================================
Auth requests
==============================================================================*/
async function checkIsAuthenticated() {
  return promiseFetch(`${url}/api/users/isauth`);
}

async function checkIsAdmin() {
  return promiseFetch(`${url}/api/users/isadmin`);
}

async function getStatus() {
  return promiseFetch(`${url}/api/users/status`);
}

async function authLogin(credentials) {
  return promiseFetch(`${url}/api/users/login`, {
    method: 'POST',
    credentials: 'include',
    headers: { "Content-Type": "application/json; charset=utf-8" },
    body: JSON.stringify({ email: credentials.email, password: credentials.password, inMaintenance: credentials.inMaintenance })
  });
}

async function authLogout() {
  return promiseFetch(`${url}/api/users/logout`, { method: 'POST' });
}

async function authSignup(credentials) {
  return promiseFetch(`${url}/api/users/signup`, {
    method: 'POST',
    credentials: 'include',
    headers: { "Content-Type": "application/json; charset=utf-8" },
    body: JSON.stringify({ email: credentials.email, password: credentials.password })
  });
}

async function authDeleteAccount() {
  return promiseFetch(`${url}/api/users/delete`, {
    method: 'DELETE',
  });
}

/*------------------------------------------------------------------------------
Helper
------------------------------------------------------------------------------*/
function updateTheme(isDarkTheme) {
  /* → Update the css variables to change the theme */
  const root = document.documentElement
  Object.entries(isDarkTheme ? c.THEME_LIGHT : c.THEME_DARK).forEach(entry => {
    const [key, value] = entry;
    root.style.setProperty(key, value);
  });
}

/*==============================================================================
Context
==============================================================================*/
export const AuthContext = React.createContext({});

/*==============================================================================
Component Auth
==============================================================================*/
export default function Auth({ children }) {
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [isReset, setIsReset] = React.useState(false);
  const [isDarkTheme, setIsDarkTheme] = React.useState(true);
  const [isInMaintenance, setIsInMaintenance] = React.useState(false);
  const [isShopInMaintenance, setIsShopInMaintenance] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    checkStatus();
  }, []);

  const checkAuth = async () => {
    return checkIsAuthenticated()
      .then(res => {
        setIsAuthenticated(res.data===true);
        setIsLoading(false);
        return res;
      });
  }

  const checkAdmin = async () => {
    return checkIsAdmin()
      .then(res => {
        setIsAdmin(res.data===true);
        setIsLoading(false);
        return res;
      });
  }

  const checkStatus = async () => {
    return getStatus()
      .then(res => {
        if (res.data) {
          setIsAuthenticated(res.data.auth);
          setIsAdmin(res.data.admin);
          setIsReset(res.data.reset);
          setIsDarkTheme(res.data.darkTheme);
          updateTheme(res.data.darkTheme);
          setIsInMaintenance(res.data.inMaintenance);
          setIsShopInMaintenance(res.data.shopInMaintenance);
        }
        setIsLoading(false);
        return res;
      })
  }

  const login = async (credentials) => {
    return authLogin(credentials)
      .then(res => {
        setIsAuthenticated(res.status===200);
        setIsAdmin(res.data.admin===true);
        return res;
      });
  };

  const logout = async () => {
    return authLogout()
      .then(res => {
        setIsAuthenticated(false);
        setIsAdmin(false);
        return res;
      });
  };

  const signup = async (credentials) => {
    return authSignup(credentials)
      .then(res => {
        setIsAuthenticated(true);
        setIsAdmin(res.data.admin===true);
        return res;
      });
  };

  const deleteAccount = async () => {
    return authDeleteAccount()
      .then(res => {
        setIsAuthenticated(false);
        setIsAdmin(false);
        return res;
      });
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, isAdmin, isReset, isDarkTheme, isInMaintenance, isShopInMaintenance,
      isLoading, checkAuth, checkAdmin, checkStatus, login, logout, signup, deleteAccount }}>
      {children}
    </AuthContext.Provider>
  );
}
