import React from 'react'
import { useFetch } from './asyncHooks'

const url = process.env.REACT_APP_URL;

export function useUpdateCartAmount() {
  /* The cart amount to display */
  const [cartAmount, setCartAmount] = React.useState(0);
  const handleCartAmount = (cartContent) => {
    let amount = 0;
    if (cartContent!==null) {
      cartContent.forEach(item => amount += item.quantity);
      setCartAmount(amount);
    } else {
      setCartAmount(0);
    }
  }
  /* When reloading the page we want to get the number of items in cart from db */
  const [cartAmountFromDb] = useFetch(`${url}/api/products/getCartTotalQuantity`);
  React.useEffect(() => {
    if (cartAmountFromDb!==null) {
      setCartAmount(cartAmountFromDb);
    }
  }, [cartAmountFromDb]);

  return [cartAmount, handleCartAmount];
}
