import React from 'react'
import { withRouter } from 'react-router'
import Modal from './Modal.component'

export default withRouter(function LegalNoticeModal({ ...props }) {
  /* Handle product deletion logic */
  const onConfirmAction = (e) => {
    e.preventDefault();
    props.history.goBack();
  };

  return (
    <Modal className="legalDocumentModalBox">
      <div className="legalDocumentText">
        <h1>Mentions légales</h1>
        <h4>En vigueur au 26/04/2021<br/></h4>
        <span>
          Conformément aux dispositions des Articles 6-III et 19 de la Loi n°2004-575 du 21 juin 2004 pour la
          Confiance dans l’économie numérique, dite L.C.E.N., il est porté à la connaissance des Utilisateurs du
          site https://www.synesthesia.fr les présentes mentions légales.<br/>
          La connexion et la navigation sur le site https://www.synesthesia.fr par l’Utilisateur implique acceptation
          intégrale et sans réserve des présentes mentions légales.<br/>
          Ces dernières sont accessibles sur le site à la rubrique « Mentions légales ».
        </span>
        <h2>ARTICLE 1 : L’éditeur</h2>
        <span>
          L’édition et la direction de la publication du site https://www.synesthesia.fr est assurée par ESPACE MARIELLE GIRAULT société au capital de 2000,00 euros, inscrite au R.C.S. de RCS Lyon sous le numéro 521 545 798, dont le siège social est situé au 2 rue de thou, 69001 Lyon, France, représenté(e) par Girault Marielle dument habilité(e), (Ci-après désigné l’« Exploitant »).<br/><br/>
          Le numéro individuel TVA de l’Exploitant est : FR52521545798.<br/><br/>
          L’Exploitant peut être joint au numéro de téléphone suivant 0667075110 et à l’adresse mail suivante synesthesia.fr@gmail.com
        </span>
        <h2>ARTICLE 2 : L’hébergeur</h2>
        <span>
          L'hébergeur du site https://www.synesthesia.fr est la Société OVH, dont le siège social est situé au 2
          rue Kellermann, 59100 Roubaix, France, avec le numéro de téléphone : 0899701761.
        </span>
        <h2>ARTICLE 3 : Accès au site</h2>
        <span>
          Le site est accessible par tout endroit, 7j/7, 24h/24 sauf cas de force majeure, interruption
          programmée ou non et pouvant découlant d’une nécessité de maintenance.<br/>
          En cas de modification, interruption ou suspension des services le site https://www.synesthesia.fr ne
          saurait être tenu responsable.
        </span>
        <h2>ARTICLE 4 : Collecte des données</h2>
        <span>
          Le site est exempté de déclaration à la Commission Nationale Informatique et Libertés (CNIL) dans la
          mesure où il ne collecte aucune donnée concernant les utilisateurs.
        </span>
        <h2>ARTICLE 5 : Cookies</h2>
        <span>
          Des cookies de sessions peuvent s'installer automatiquement sur le logiciel de navigation de l'Utilisateur
          sans obtenir son autorisation explicite en accord avec les lois en vigueur.<br/>
          En naviguant sur le site, il les accepte.<br/>
          Aucun cookie de tracage ou de collecte d'informations de l'Utilisateur n'est utilisé.<br/>
          Un cookie est un élément qui ne permet pas d’identifier l’Utilisateur mais sert à enregistrer des
          informations relatives à la navigation de celui-ci sur le site Internet. L’Utilisateur pourra désactiver ce
          cookie par l’intermédiaire des paramètres figurant au sein de son logiciel de navigation.
        </span>
        <h2>ARTICLE 6 : Propriété intellectuelle</h2>
        <span>
          Toute utilisation, reproduction, diffusion, commercialisation, modification de toute ou partie du site
          https://www.synesthesia.fr, sans autorisation de l’Editeur est prohibée et pourra entraînée des actions
          et poursuites judiciaires telles que notamment prévues par le Code de la propriété intellectuelle et le
          Code civil.<br/><br/><br/>
        </span>
        <span>
          Rédigé sur http://legalplace.fr<br/><br/>
        </span>
      </div>
      <div className="orderActionsLayout" style={{ width: "auto", marginBottom: "15px", justifyContent: "flex-start" }}>
        <div className="orderActionButton buttonConfirm" onClick={onConfirmAction} role="button" style={{ margin: "0 5px" }}>Ok</div>
      </div>
    </Modal>
  );
})
