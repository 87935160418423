import { AuthContext } from '../Auth'
import { withRouter } from 'react-router'
import React from 'react'
import { ReactComponent as BurgerMenu } from "../resources/icons/burger-menu.svg"
import { ReactComponent as BurgerMenuOpen } from "../resources/icons/burger-menu-open.svg"
import { isChrome } from 'react-device-detect'
import Switch from 'react-switch'
import { useWindowDimensions } from '../hooks/asyncHooks'
import c from '../Constants'
import { promiseFetch } from '../helperFunctions'
import { ReactComponent as InformationIcon } from "../resources/icons/information.svg"

const url = process.env.REACT_APP_URL;

/*------------------------------------------------------------------------------
Hook to change the theme
------------------------------------------------------------------------------*/
function useChangeTheme() {
  const { isDarkTheme, isLoading } = React.useContext(AuthContext);
  const [checked, setChecked] = React.useState(false);
  const handleChecked = (checked) => {
    /* → Set the switch */
    setChecked(checked);
    /* → Save the setting to the session */
    promiseFetch(`${url}/api/users/setDarkTheme/${checked}`, { method: 'POST' })
      .then(res => {
        /* → Update the css variables to change the theme */
        const root = document.documentElement
        Object.entries(checked ? c.THEME_LIGHT : c.THEME_DARK).forEach(entry => {
          const [key, value] = entry;
          root.style.setProperty(key, value);
        });
      })
  };
  /* Set the switch at when status is loaded */
  React.useLayoutEffect(() => {
    if (isLoading===false) {
      setChecked(isDarkTheme);
    }
  }, [isLoading, isDarkTheme]);
  return [checked, handleChecked];
}

/*==============================================================================
Main component
==============================================================================*/
export default withRouter(function Header({ buttons, handleSetFirstCategorySelected, ...props }) {
  const { width } = useWindowDimensions();
  /* Activate/Deactivate switch */
  const [checked, handleChecked] = useChangeTheme();

  const [burgerMenuOpened, setBurgerMenuOpened] = React.useState(false);
  const onBurgerMenuClick = (e) => {
    setBurgerMenuOpened(opened => !opened);
  }

  const onTitleClick = () => {
    props.history.push("/");
  };

  return (
    <div className="header">
      <div className="headerTitle" onClick={onTitleClick}>Synesthésia</div>
      {width>c.MOBILE_BREAK_WIDTH ?
        <ul className="headerList">
          <li className="headerListItem">
            <label className="switchLabelTheme">
              <Switch onChange={handleChecked} checked={checked} height={16} width={32} checkedIcon={false} uncheckedIcon={false}
                onColor='#DDD' offColor='#151515'/>
            </label>
          </li>
          {buttons.map((button, idx) => {
            const amount = (button.amount!==undefined&&button.amount>0?`${button.amount}`:'');
            return <li key={idx} className="headerListItem">
              <div className="headerListButton" onClick={button.func} role="button">
                {button.name}
                {amount!=='' &&
                  <div style={isChrome?{}:{ height: "16px", padding: '1px 0.1em 0 0' }}>{amount}</div>
                }
              </div>
            </li>
          })}
        </ul> :
        <>
          <InformationIcon className="headerInformationButton" onClick={handleSetFirstCategorySelected} role="button"/>
          <BurgerMenu className="headerBurgerMenu" onClick={onBurgerMenuClick} role="button"
            style={burgerMenuOpened ? { display: "none" } : {}} />
          <BurgerMenuOpen className="headerBurgerMenu" onClick={onBurgerMenuClick} role="button"
            style={!burgerMenuOpened ? { display: "none" } : {}} />
          {burgerMenuOpened &&
            <div className="headerBurgerMenuLayout">
              <ul className="headerList">
                <li className="headerListItem" style={{ marginRight: "0"}}>
                  <label className="switchLabelTheme">
                    <Switch onChange={handleChecked} checked={checked} height={16} width={32} checkedIcon={false} uncheckedIcon={false}
                      onColor='#DDD' offColor='#151515'/>
                  </label>
                </li>
                {buttons.map((button, idx) => {
                  const amount = (button.amount!==undefined&&button.amount>0?`${button.amount}`:'');
                  return <li key={idx} className="headerListItem">
                    <div className="headerListButton" onClick={button.func} role="button">
                      {button.name}
                      {amount!=='' &&
                        <div style={isChrome?{ letterSpacing: "-0.1em", padding: "2px 0.1em 0 0", height: "13px" }:{ height: "14px", padding: '1px 0.1em 0 0' }}>{amount}</div>
                      }
                    </div>
                  </li>
                })}
              </ul>
            </div>
          }
        </>
      }
    </div>
  );
})

export const ProfileHeader = withRouter(({ buttons, children, opened=false, style={}, ...props }) => {
  const { width } = useWindowDimensions();

  const [burgerMenuOpened, setBurgerMenuOpened] = React.useState(opened);
  const onBurgerMenuClick = (e) => {
    setBurgerMenuOpened(opened => !opened);
  }

  const onTitleClick = () => {
    props.history.push("/");
  };

  return (
    <div className="profileHeader">
      {children}
      <div className="profileHeaderTitle" onClick={onTitleClick}>Synesthésia</div>
      {width>c.MOBILE_BREAK_WIDTH ?
        <ul className="profileHeaderList">
          {buttons.map((button, idx) => {
            if (button.custom!==undefined && button.custom!==null) {
              return <li key={idx}>{button.custom}</li>
            } else {
              const amount = (button.amount!==undefined&&button.amount>0?`${button.amount}`:'');
              return <li key={idx}>
                <div className="profileHeaderListButton" onClick={button.func} role="button">
                  {button.name}
                  {amount!=='' &&
                    <div style={isChrome?{ letterSpacing: "-0.1em", padding: "0 0.1em 0 0" }:{ height: "16px", padding: '1px 0 0 0' }}>{amount}</div>
                  }
                </div>
              </li>
            }
          })}
        </ul> :
        <>
          <BurgerMenu className="profileHeaderBurgerMenu" onClick={onBurgerMenuClick} role="button"
            style={burgerMenuOpened ? { display: "none" } : {}} />
          <BurgerMenuOpen className="profileHeaderBurgerMenu" onClick={onBurgerMenuClick} role="button"
            style={!burgerMenuOpened ? { display: "none" } : {}} />
          {burgerMenuOpened &&
            <div className="profileHeaderBurgerMenuLayout" style={style}>
              <ul className="profileHeaderList">
                {buttons.map((button, idx) => {
                  if (button.custom!==undefined && button.custom!==null) {
                    return <li key={idx} style={{
                      position: "absolute",
                      left: "50%",
                      transform: "translateX(-50%)",
                      top: "2.0em",
                      marginLeft: "0"
                    }}>{button.custom}</li>
                  } else {
                    const amount = (button.amount!==undefined&&button.amount>0?`${button.amount}`:'');
                    return <li key={idx}>
                      <div className="profileHeaderListButton" onClick={button.func} role="button">
                        {button.name}
                        {amount!=='' &&
                          <div style={isChrome?{ letterSpacing: "-0.1em", padding: "1px 0.1em 0 0", height: "14px" }:{ height: "14px", padding: '1px 0.1em 0 0' }}>{amount}</div>
                        }
                      </div>
                    </li>
                  }
                })}
              </ul>
            </div>
          }
        </>
      }
    </div>
  );
})

export const AdminHeader = withRouter(({ buttons, currentSelected, children, ...props }) => {
  /* Activate/Deactivate switch */
  const [checked, handleChecked] = useChangeTheme();

  const onTitleClick = () => {
    props.history.push("/");
  };

  return (
    <div className="profileHeader">
      {children}
      <div className="profileHeaderTitle" onClick={onTitleClick}>Synesthésia</div>
      <ul className="profileHeaderList">
        <li style={{ position: "relative", top: "4px" }}>
          <label className="switchLabelTheme">
            <Switch onChange={handleChecked} checked={checked} height={16} width={32} checkedIcon={false} uncheckedIcon={false}
              onColor='#DDD' offColor='#151515'/>
          </label>
        </li>
        {buttons.map((button, idx) => {
          if (button.custom!==undefined && button.custom!==null) {
            return <li key={idx}>{button.custom}</li>
          } else {
            return <li key={idx}>
              <div className="profileHeaderListButton" onClick={() => button.func(idx)} role="button"
                style={idx===currentSelected ? { fontWeight: "700", borderBottom: "4px solid var(--color-text)" } : {}}>
                {button.name}
              </div>
            </li>
          }
        })}
      </ul>
    </div>
  );
})